import { FC } from "react";
import { CircularProgress, Grid, Typography, makeStyles } from "@xcira/components";
import { Permission } from "graphql/generated";
import {
  useAvStreams,
  useCompanies,
  useFacilities,
  usePortStatus,
  useRequiresPermissions,
  useSelectCurrentFacilityNames,
} from "hooks";
import { CompanyFilter, RequiresPermissions } from "components";
import { selectFacilitiesAuctionHouseFilter, setFacilitiesAuctionHouseFilter } from "slices";
import { FacilitiesTable } from "./FaciliitesTable";
import { NoFacilitiesFound } from "./NoFacilitiesFound";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  noFacilities: {
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    justifyContent: "center",
  },
  noPermission: { padding: theme.spacing(2) },
  filters: {
    marginBottom: theme.spacing(2),
  },
}));

const requiredPermissions = [Permission.CompaniesRead, Permission.FacilitiesRead];

export const Facilities: FC = () => {
  const { classes } = useStyles();

  const [hasPerms] = useRequiresPermissions(requiredPermissions);

  const { isLoading: facilitiesLoading } = useFacilities();
  const { isLoading: companiesLoading } = useCompanies();
  const isLoading = facilitiesLoading || companiesLoading;

  const facilityNames = useSelectCurrentFacilityNames();
  useAvStreams();
  usePortStatus();

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="facilitiesLoader"
        className={classes.circularProgress}
        size={100}
      />
    );
  }

  if (!hasPerms) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.noPermission}
        data-testid="facilitiesNoPermission"
      >
        <Grid item xs>
          <Typography variant="h6">
            Your credentials don&apos;t have permissions to access.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RequiresPermissions permissions={requiredPermissions}>
      <div className={classes.root} data-testid="facilities">
        <Grid container spacing={2} className={classes.filters}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <CompanyFilter
              selectAuctionHouseFilter={selectFacilitiesAuctionHouseFilter}
              setAuctionHouseFilter={setFacilitiesAuctionHouseFilter}
            />
          </Grid>
        </Grid>

        {facilityNames?.length ? (
          <FacilitiesTable />
        ) : (
          <NoFacilitiesFound className={classes.noFacilities} />
        )}
      </div>
    </RequiresPermissions>
  );
};
