import { FC } from "react";
import {
  makeStyles,
  InputLabel,
  ListSubheader,
  Select,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@xcira/components";
import { usePreviewFlavors } from "hooks/usePreviewFlavors";
import { usePreviewFlavorsSelector, useSelectCompanyByAhco, useSelectEventById } from "hooks";

const useStyles = makeStyles()((theme) => ({
  flavorListItem: { cursor: "pointer" },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "95%",
    paddingLeft: theme.spacing(1),
  },
  caption: {
    lineHeight: "inherit",
    textTransform: "capitalize",
  },
}));

interface FlavorSelectProps {
  eventId?: number;
  ahco?: string;
  flavor: string;
  setFlavor: (flavor: string) => void;
  label?: boolean;
  className?: string;
}

const FlavorSelect: FC<FlavorSelectProps> = ({
  eventId,
  flavor,
  setFlavor,
  label = true,
  className,
  ahco,
}) => {
  usePreviewFlavors();

  const event = useSelectEventById(eventId ?? 0);
  const auctionCompany = ahco ?? event?.auctionCompany;
  const flavorGroup = useSelectCompanyByAhco(auctionCompany, (company) => company?.flavorGroup);

  const flavorGroupCollections = flavorGroup?.flavorGroupCollections ?? [];
  const companyFlavors = flavorGroupCollections.map((companyFlavor) => companyFlavor.flavorName);
  const previewFlavors = usePreviewFlavorsSelector((previewFlavors) =>
    previewFlavors.filter((previewFlavor) => !companyFlavors.includes(previewFlavor.flavor))
  );

  const { classes } = useStyles();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setFlavor(event.target.value as string);
  };

  return (
    <>
      {label && (
        <InputLabel htmlFor="flavor-select" data-testid="flavorLabel">
          Flavor
        </InputLabel>
      )}
      <Select
        id="flavor-select"
        value={flavor}
        onChange={handleChange}
        label="Flavor"
        disabled={!flavorGroup}
        data-testid="flavorSelect"
        className={className}
        displayEmpty
      >
        <ListSubheader>Default</ListSubheader>
        <MenuItem value="default" className={classes.flavorListItem}>
          <div className={classes.flexRow}>
            <Typography variant="body2" data-testid="flavorSelectDefault">
              Default Flavor
            </Typography>
          </div>
        </MenuItem>

        <ListSubheader>Company Flavors</ListSubheader>
        {flavorGroupCollections.map((flavorGroup) => (
          <MenuItem
            value={flavorGroup.flavorName}
            className={classes.flavorListItem}
            key={`companyFlavor${flavorGroup.flavorName}`}
            data-testid={`companyFlavor${flavorGroup.flavorName}`}
          >
            <div className={classes.flexRow}>
              <Typography variant="body2">{flavorGroup.flavorName}</Typography>
              <Typography variant="caption" className={classes.caption}>
                {flavorGroup.deviceType}
              </Typography>
            </div>
          </MenuItem>
        ))}

        <ListSubheader>Preview Flavors</ListSubheader>
        {previewFlavors?.map((previewFlavor) => (
          <MenuItem
            value={previewFlavor.flavor}
            className={classes.flavorListItem}
            key={`previewFlavor${previewFlavor.flavor}`}
            data-testid={`previewFlavor${previewFlavor.flavor}`}
          >
            <div className={classes.flexRow}>
              <Typography variant="body2">{previewFlavor.flavor}</Typography>
              {previewFlavor.allowPreview && (
                <Typography variant="caption" className={classes.caption}>
                  Preview
                </Typography>
              )}
            </div>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { FlavorSelect };
