import { FC, memo, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
} from "@xcira/components";
import { AuctionAttendee } from "graphql/generated";
import { AttendeesHeader } from "./AttendeesHeader";
import { AttendeeRow } from "./AttendeeRow";

interface AttendeeTableProps {
  attendees: AuctionAttendee[];
  eventId: number;
  ahco: string;
}

const AttendeeTable: FC<AttendeeTableProps> = memo(({ attendees, eventId, ahco }) => {
  const [flavor, setFlavor] = useState("default");
  const [devOverride, setDevOverride] = useState("");

  return (
    <Paper>
      <TableContainer>
        <Table size="small" data-testid="attendeesTable">
          <AttendeesHeader
            flavor={flavor}
            setFlavor={setFlavor}
            devOverride={devOverride}
            setDevOverride={setDevOverride}
          />
          <TableBody>
            {attendees?.length === 0 && (
              <TableRow data-testid="noAttendeesRow">
                <TableCell>
                  <Typography variant="subtitle2">No attendee(s) found</Typography>
                </TableCell>
              </TableRow>
            )}

            {attendees.map((attendee: AuctionAttendee) => {
              return (
                <AttendeeRow
                  id={attendee.id}
                  clientAttendeeId={attendee.clientAttendeeId}
                  name={attendee.name}
                  type={attendee.type}
                  creditLimit={attendee.creditLimit}
                  warranty={attendee.warranty ?? ""}
                  sellerId={attendee.sellerId}
                  key={attendee.clientAttendeeId}
                  ahco={ahco}
                  eventId={eventId}
                  flavor={flavor}
                  devOverride={devOverride}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export { AttendeeTable };
