import { memo, FC } from "react";
import { Grid, LinearProgress, makeStyles, Paper, Typography } from "@xcira/components";
import { useSelectInventoryProgressCountsByEventId } from "hooks/useInventoryCounts";

const useStyles = makeStyles<void, "toolTip">()((theme, _props, classes) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    overflow: "visible",
    [`&:hover .${classes.toolTip}`]: {
      visibility: "visible",
    },
  },
  toolTip: {
    position: "absolute",
    zIndex: 1,
    visibility: "hidden",
    padding: theme.spacing(2),
    width: "200px",
    top: `calc(100% + ${theme.spacing(1)})`,
    left: "50%",
    marginLeft: "-25%",
  },
  clerkedProgressBar: {
    height: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },
  linearProgress: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  progressLabel: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
}));

interface InventoryProgessCountsProps {
  eventId: number;
  lotsClerked: number;
  showTitle?: boolean;
}

const InventoryProgress: FC<InventoryProgessCountsProps> = memo(({ eventId, lotsClerked }) => {
  const { classes } = useStyles();
  const {
    percentClerked,
    totalLots,
    soldLotCount,
    ifSoldLotCount,
    noSaleLotCount,
    withdrawnLotCount,
  } = useSelectInventoryProgressCountsByEventId(eventId, lotsClerked);

  return (
    <div className={classes.root} data-testid="inventoryProgress">
      <Paper elevation={1} className={classes.toolTip}>
        <Grid container>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">Total Lots: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{totalLots} </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">Sold Lots: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{soldLotCount} </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">If Sold Lots: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{ifSoldLotCount} </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">No Sale Lots: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{noSaleLotCount} </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle2">Withdrawn Lots: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{withdrawnLotCount} </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Typography variant="subtitle2" data-testid="inventoryProgressTotalLots">
        {totalLots}
      </Typography>
      <div className={classes.linearProgress}>
        <LinearProgress
          className={classes.clerkedProgressBar}
          variant="determinate"
          value={percentClerked}
          data-testid="inventoryProgressLinear"
        />
        <Typography
          variant="body2"
          className={classes.progressLabel}
          data-testid="inventoryProgressClerked"
        >
          {percentClerked}% clerked
        </Typography>
      </div>
    </div>
  );
});

export { InventoryProgress };
