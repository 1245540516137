import { FC, useState, MouseEvent } from "react";
import { useAppSelector, useAppDispatch, useUserDetailsSelector } from "hooks";
import { selectIsAuthenticated, logout } from "slices/auth";
import {
  AppBar,
  AppBarProps,
  Typography,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  makeStyles,
  Grid,
} from "@xcira/components";
import { cloudServices } from "services";
import { NavLinks, RequiresPermissions } from "components";
import { Permission } from "graphql/generated";
import { useAppInfo } from "hooks/useAppInfo";
import { useAppInfoSelector } from "hooks/useAppInfo/useAppInfoSelector";

interface NavBarProps extends AppBarProps {}

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  header: { minWidth: 100 },
  username: { textTransform: "uppercase", marginTop: theme.spacing(0.5) },
  logoutBtn: { textDecoration: "underline" },
  divider: {
    height: 21,
    marginLeft: theme.spacing(1),
  },
}));

export const NavBar: FC<NavBarProps> = (props) => {
  useAppInfo();
  const appInfo = useAppInfoSelector((appInfo) => appInfo);
  const { classes } = useStyles();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const username = useUserDetailsSelector((userDetails) => userDetails.username);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(cloudServices.util.resetApiState());
    handleClose();
  };

  return (
    <AppBar {...props}>
      <Typography variant="h6" className={classes.header}>
        OLR Tools
      </Typography>
      <Typography variant="h6" className={classes.header}>
        - {appInfo.envName}
      </Typography>
      {isAuthenticated && (
        <>
          <RequiresPermissions permissions={[Permission.CompaniesRead, Permission.EventsRead]}>
            <NavLinks />
          </RequiresPermissions>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Typography variant="subtitle2" className={classes.username}>
                {username}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="user account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Icon icon="account_circle" button />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </>
      )}
    </AppBar>
  );
};
