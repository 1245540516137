import { FC } from "react";
import { makeStyles, Grid, Typography, Box, Divider } from "@xcira/components";
import { useSelectAvPublisherByPort, useSelectAvStreamByPort, useSelectEventById } from "hooks";
import { DateUtil } from "@xcira/commons";
import { StreamInfo } from "./StreamInfo";
import { StreamManagementControls } from "./StreamManagementControls";
import { getStatus } from "utils/AvStreamUtil";
import { AvConnectionsControl } from "components/AvConnections/AvConnectionsControl";
import { StreamInfoRaw } from "./StreamInfoRaw";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: "relative",
    minHeight: 200,
  },
  box: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 220,
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    height: "100%",
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    marginBottom: theme.spacing(0.5),
  },
  streamInfoText: { wordBreak: "break-word" },
  controls: { marginBottom: theme.spacing(2) },
}));

interface StreamManagementProps {
  eventId: number;
}

const StreamManagement: FC<StreamManagementProps> = ({ eventId }) => {
  const { classes } = useStyles();

  const avPort = useSelectEventById(eventId, (event) => event?.avPort ?? 0);
  const facilityName = useSelectEventById(eventId, (event) => event?.facilityName);
  const avPublisher = useSelectAvPublisherByPort(avPort);
  const avStream = useSelectAvStreamByPort(avPort);

  return (
    <>
      <div className={classes.root} data-testid="streamManagement">
        <StreamManagementControls eventId={eventId} className={classes.controls} />

        <Divider className={classes.divider} />

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} lg={4} data-testid="streamManagementAvConnectionsControl">
            <AvConnectionsControl facilityName={facilityName} avPort={avPort} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4} data-testid="streamManagementInfo">
            <Box className={classes.box}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">AV Port</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementAvPort">
                    {avPort}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">CDN</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementCDN">
                    {avPublisher?.avStream.avConnection.description ??
                      avStream?.avConnection.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">Stream Name</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementName">
                    {avPublisher?.avStream.name ?? avStream?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">Stream Type</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementType">
                    {avPublisher?.avStream.streamType ?? avStream?.streamType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">Stream Status</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementStatus">
                    {getStatus(avPublisher?.status, avPublisher?.statusInfo) ?? "NOT ACTIVE"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" data-testid="streamManagementDescription">
                    {avPublisher?.description ?? avStream?.avEncoderSettings.description}
                  </Typography>
                </Grid>
              </Grid>
              {avPublisher && (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridContainer}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Start Date Time</Typography>
                    </Grid>
                    <Grid item>
                      {avPublisher?.startTime && (
                        <Typography
                          variant="body2"
                          className={classes.streamInfoText}
                          data-testid="streamManagementStartTime"
                        >
                          {DateUtil.format(avPublisher?.startTime, "MM/D/YYYY HH:mm:ss")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridContainer}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Last Heartbeat</Typography>
                    </Grid>
                    <Grid item>
                      {avPublisher?.lastModified && (
                        <Typography
                          variant="body2"
                          className={classes.streamInfoText}
                          data-testid="streamManagementLastModified"
                        >
                          {DateUtil.format(avPublisher?.lastModified, "MM/D/YYYY HH:mm:ss")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridContainer}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Audio Bitrate</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" data-testid="streamManagementAudioBitrate">
                        {avPublisher?.avStream.avEncoderSettings.audioBitrate}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.gridContainer}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">Video FPS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" data-testid="streamManagementVideoFps">
                        {avPublisher?.avStream.avEncoderSettings.videoFps}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={8}>
            {avPublisher?.infoRaw ? (
              <StreamInfoRaw infoRaw={avPublisher.infoRaw} />
            ) : (
              <StreamInfo html={avPublisher?.info} />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export { StreamManagement };
