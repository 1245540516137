import { useMemo } from "react";
import { useAppSelector } from "hooks";
import { selectFilteredFacilities } from "slices";

const useSelectCurrentFacilityNames = () => {
  const facilities = useAppSelector(selectFilteredFacilities);
  return useMemo(() => facilities.map((facility) => facility.facilityName), [facilities]);
};

export { useSelectCurrentFacilityNames };
