import { TableCell, TableHead, TableRow, makeStyles } from "@xcira/components";
import { RequiresPermissions } from "components/RequiresPermissions";
import { Permission } from "graphql/generated";
import { FC } from "react";

const useStyles = makeStyles()((theme) => ({
  ports: {
    paddingLeft: theme.spacing(3),
  },
}));

interface FacilitiesHeaderProps {
  className?: string;
}

export const FacilitiesHeader: FC<FacilitiesHeaderProps> = ({ className }) => {
  const { classes } = useStyles();

  return (
    <TableHead className={className}>
      <TableRow>
        <TableCell>Auction House</TableCell>
        <TableCell>Facility ID</TableCell>
        <TableCell>Facility Name</TableCell>
        <TableCell>Marketplace</TableCell>
        <TableCell>Flavor Group</TableCell>
        <TableCell>Feature Collection</TableCell>
        <TableCell>Event Status</TableCell>
        <RequiresPermissions permissions={Permission.PortsRead}>
          <TableCell className={classes.ports}>Ports</TableCell>
        </RequiresPermissions>
        <RequiresPermissions permissions={Permission.AvStreamsRead}>
          <TableCell align="center">AV</TableCell>
        </RequiresPermissions>
      </TableRow>
    </TableHead>
  );
};
