import { FC } from "react";
import { default as MuiDialog, DialogProps } from "@mui/material/Dialog";
import { default as MuiDialogTitle, DialogTitleProps } from "@mui/material/DialogTitle";
import { default as MuiDialogContent, DialogContentProps } from "@mui/material/DialogContent";
import {
  default as MuiDialogContentText,
  DialogContentTextProps,
} from "@mui/material/DialogContentText";
import { default as MuiDialogActions, DialogActionsProps } from "@mui/material/DialogActions";

export const Dialog: FC<DialogProps> = ({ children, ...props }) => {
  return <MuiDialog {...props}>{children}</MuiDialog>;
};

export const DialogTitle: FC<DialogTitleProps> = ({ children, ...props }) => {
  return <MuiDialogTitle {...props}>{children}</MuiDialogTitle>;
};

export const DialogContent: FC<DialogContentProps> = ({ children, ...props }) => {
  return <MuiDialogContent {...props}>{children}</MuiDialogContent>;
};

export const DialogContentText: FC<DialogContentTextProps> = ({ children, ...props }) => {
  return <MuiDialogContentText {...props}>{children}</MuiDialogContentText>;
};

export const DialogActions: FC<DialogActionsProps> = ({ children, ...props }) => {
  return <MuiDialogActions {...props}>{children}</MuiDialogActions>;
};

export type {
  DialogProps,
  DialogTitleProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogActionsProps,
};
