import { Facility } from "graphql/generated";
import { useFacilitiesSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: Facility) => any;

const defaultSelector = (facility?: Facility) => facility;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? Facility | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectFacilityByName = <T extends SelectFromResult>(
  facilityName: string,
  selector: T = defaultSelector as T
): Result<T> => {
  const facility = useFacilitiesSelector((facilities) =>
    facilities.find((facility) => facility.facilityName === facilityName)
  );
  return useMemo(() => selector(facility), [selector, facility]);
};

export { useSelectFacilityByName };
