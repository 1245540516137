import { FC } from "react";
import { Typography } from "@xcira/components";

interface ApplicationStatusProps {}

const ApplicationStatus: FC<ApplicationStatusProps> = () => {
  return <Typography variant="h5">Application Status Page</Typography>;
};

export { ApplicationStatus };
