import { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@xcira/components";
import { AvConnectionTypeEnum } from "graphql/generated";
import { useAppDispatch, useSelectAvStreamByPort } from "hooks";
import { openDialog } from "slices";

const useStyles = makeStyles()(() => ({
  root: {
    width: "100%",
  },
}));

interface AvConnectionsControlProps {
  facilityName?: string;
  avPort?: number;
}

const AvConnectionsControl: FC<AvConnectionsControlProps> = ({ facilityName, avPort }) => {
  const { classes } = useStyles();
  const avStream = useSelectAvStreamByPort(avPort);
  const dispatch = useAppDispatch();

  const avConnectionPrimary = avStream?.avConnectionPrimary;
  const avConnectionAlternate = avStream?.avConnectionAlternate;
  const avConnectionBackup = avStream?.avConnectionBackup;
  const avConnectionCurrent = avStream?.avConnection;

  const getCurrentCDNType = () => {
    if (avConnectionCurrent?.id === avConnectionPrimary?.id) {
      return AvConnectionTypeEnum.Primary;
    }

    if (avConnectionCurrent?.id === avConnectionBackup?.id) {
      return AvConnectionTypeEnum.Backup;
    }

    if (avConnectionCurrent?.id === avConnectionAlternate?.id) {
      return AvConnectionTypeEnum.Alternate;
    }

    return "";
  };

  const cdn = getCurrentCDNType();

  const handleDialogOpen = (type: AvConnectionTypeEnum, description: string) => {
    dispatch(
      openDialog({
        type: "AvConnection",
        props: {
          facilityName,
          description,
          type,
        },
      })
    );
  };

  return (
    <div className={classes.root}>
      <FormControl
        id={`avConnectionControlForm-${avPort}`}
        data-testid={`avConnectionControlForm-${avPort}`}
        fullWidth
      >
        <InputLabel
          id={`avConnectionControlLabel-${avPort}`}
          data-testid={`avConnectionControlLabel-${avPort}`}
        >
          Change CDN
        </InputLabel>
        <Select
          data-testid={`avConnectionControlSelect-${avPort}`}
          labelId={`avConnectionControlLabel-${avPort}`}
          label="Change CDN"
          value={cdn}
        >
          {avConnectionPrimary && (
            <MenuItem
              value={AvConnectionTypeEnum.Primary}
              onClick={() =>
                handleDialogOpen(AvConnectionTypeEnum.Primary, avConnectionPrimary.description)
              }
            >
              Primary - {avConnectionPrimary.description}
            </MenuItem>
          )}
          {avConnectionAlternate && (
            <MenuItem
              value={AvConnectionTypeEnum.Alternate}
              onClick={() =>
                handleDialogOpen(AvConnectionTypeEnum.Alternate, avConnectionAlternate.description)
              }
            >
              Alternate - {avConnectionAlternate.description}
            </MenuItem>
          )}
          {avConnectionBackup && (
            <MenuItem
              value={AvConnectionTypeEnum.Backup}
              onClick={() =>
                handleDialogOpen(AvConnectionTypeEnum.Backup, avConnectionBackup.description)
              }
            >
              Backup - {avConnectionBackup.description}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export { AvConnectionsControl };
