import { useUpdateEventStatusMutation } from "services";
import { EventStatusActions } from "graphql/generated";
import { useCallback } from "react";

function useUpdateEventStatus(action: EventStatusActions, eventIds: number[]) {
  const [updateEventStatus, result] = useUpdateEventStatusMutation();

  const callback = useCallback(async () => {
    const result = await updateEventStatus({ action, eventIds }).unwrap();

    // TODO: invalidate events tag?

    return result;
  }, [updateEventStatus, action, eventIds]);

  return [callback, result] as const;
}

export { useUpdateEventStatus };
