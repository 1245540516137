import { cloudServicesEndpoints } from "services";
import { GetAuctionEventsQuery } from "graphql/generated";

type AuctionEvents = GetAuctionEventsQuery["auctionEvents"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: AuctionEvents) => any;

const defaultEvents: AuctionEvents = [];

function useEventsSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetAuctionEvents.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.auctionEvents ?? defaultEvents),
  });
}

export { useEventsSelector };
