import { memo, useCallback, FC } from "react";
import { Icon, IconButton, keyframes, makeStyles, Paper } from "@xcira/components";
import { Ports, RequiresPermissions } from "components";
import { Permission, PortActionEnum, PortTypeEnum } from "graphql/generated";
import { useAppDispatch, useRequiresPermissions, useSelectPortByNumber } from "hooks";
import { openDialog, EventPort } from "slices/dialogs";
import { getColor, getConstructedDownPort, isPortsUp, isPortUp } from "utils/PortUtil";

interface PortsControlProps {
  eventId?: number;
  updatePortNumber?: number;
  bidPortNumber?: number;
  masterPortNumber?: number;
}

interface StyleProps {
  masterDown: boolean;
}

const flashing = keyframes(`
  50% {
    opacity: .4
  }
`);

const permissions = [Permission.PortsStop];

const useStyles = makeStyles<StyleProps, "toolTip">()((theme, props, classes) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [`&:hover .${classes.toolTip}`]: {
      visibility: "visible",
    },
  },
  masterPort: {
    ...(props.masterDown && {
      animationName: flashing,
      animationIterationCount: "infinite",
      animationDuration: "1.5s",
    }),
  },
  toolTip: {
    position: "absolute",
    zIndex: 1,
    width: 200,
    visibility: "hidden",
    padding: theme.spacing(1),
    top: `calc(100% + ${theme.spacing(0.5)})`,
    left: "-50%",
    margin: "auto",
  },
  iconButton: {
    padding: theme.spacing(1),
  },
}));

const getIconButtonColor = (ports: EventPort[] = []) => {
  if (isPortsUp(ports)) {
    return "default";
  }
  return "success";
};

const getAction = (ports: EventPort[] = []) => {
  if (isPortsUp(ports)) {
    return PortActionEnum.Stop;
  }
  return PortActionEnum.Start;
};

const PortsControl: FC<PortsControlProps> = memo(
  ({ eventId, updatePortNumber = 0, bidPortNumber = 0, masterPortNumber = 0 }) => {
    const updatePort = useSelectPortByNumber(updatePortNumber);
    const bidPort = useSelectPortByNumber(bidPortNumber);
    const masterPort = useSelectPortByNumber(masterPortNumber);
    const key = `${updatePortNumber}_${bidPortNumber}_${masterPortNumber}_${eventId}`;

    const { classes } = useStyles({ masterDown: !isPortUp(masterPort) });

    const [hasPerms] = useRequiresPermissions(permissions);

    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
      const action: PortActionEnum = getAction([updatePort, bidPort]);
      const ports: EventPort[] = [];

      if (isPortsUp([updatePort, bidPort])) {
        ports.push(updatePort, bidPort);
      } else {
        ports.push(
          ...(!isPortUp(updatePort)
            ? [updatePort ?? getConstructedDownPort(updatePortNumber, PortTypeEnum.Update)]
            : []),
          ...(!isPortUp(bidPort)
            ? [bidPort ?? getConstructedDownPort(bidPortNumber, PortTypeEnum.Bid)]
            : [])
        );
      }

      dispatch(
        openDialog({
          type: "Port",
          props: {
            eventId,
            ports,
            action,
          },
        })
      );
    }, [bidPort, bidPortNumber, dispatch, eventId, updatePort, updatePortNumber]);

    return (
      <div className={classes.root} data-testid={`portsControl${key}`}>
        <Paper elevation={1} className={classes.toolTip}>
          <Ports
            eventId={eventId}
            updatePortNumber={updatePortNumber}
            bidPortNumber={bidPortNumber}
            masterPortNumber={masterPortNumber}
          />
        </Paper>
        <Icon
          icon="brightness_1"
          fontSize="large"
          color={getColor([updatePort, bidPort, masterPort])}
          className={classes.masterPort}
          data-testid={`portsControlIcon${key}`}
        />
        <RequiresPermissions permissions={Permission.PortsUpdate}>
          <IconButton
            size="large"
            color={getIconButtonColor([updatePort, bidPort])}
            onClick={handleClick}
            className={classes.iconButton}
            disabled={!hasPerms}
            data-testid={`portsControlIconButton${key}`}
          >
            {!isPortsUp([updatePort, bidPort]) && (
              <Icon fontSize="large" icon="play_arrow" data-testid={`portsControlStart${key}`} />
            )}

            {isPortsUp([updatePort, bidPort]) && (
              <RequiresPermissions permissions={Permission.PortsStop}>
                <Icon fontSize="large" icon="stop" data-testid={`portsControlStop${key}`} />
              </RequiresPermissions>
            )}
          </IconButton>
        </RequiresPermissions>
      </div>
    );
  }
);

export { PortsControl };
