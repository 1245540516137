import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Severity = "success" | "info" | "warning" | "error";

export interface NotificationState {
  isOpen: boolean;
  duration: number;
  message: string;
  severity: Severity;
  key?: string;
}

const initialState: NotificationState = {
  isOpen: false,
  duration: 4500,
  message: "",
  severity: "info",
  key: undefined,
};

export type Notification = {
  message: string;
  severity: Severity;
  key?: string;
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    notify: (state, action: PayloadAction<Notification>) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.key = action.payload.key;
    },
    dismiss: (state) => {
      state.isOpen = false;
    },
  },
});

export const { notify, dismiss } = notifications.actions;

export const { name: notificationsPath, reducer: notificationsReducer } = notifications;
