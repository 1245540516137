import { useGetLoggedInAttendeeCountsQuery, cloudServicesEndpoints } from "services";
import { GetLoggedInAttendeeCountsQueryVariables } from "graphql/generated";

function useLoggedInAttendeeCounts(
  args?: GetLoggedInAttendeeCountsQueryVariables,
  pollingInterval?: number
) {
  const { isLoading } = cloudServicesEndpoints.GetAuctionEvents.useQueryState(undefined);

  const skip = isLoading;

  return useGetLoggedInAttendeeCountsQuery(args, { pollingInterval, skip });
}

export { useLoggedInAttendeeCounts };
