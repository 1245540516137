import { cloudServicesEndpoints } from "services";
import { GetPreviewFlavorsQuery } from "graphql/generated";

type PreviewFlavors = GetPreviewFlavorsQuery["flavors"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: PreviewFlavors) => any;

const defaultPreviewFlavors: PreviewFlavors = [];

function usePreviewFlavorsSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetPreviewFlavors.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.flavors ?? defaultPreviewFlavors),
  });
}

export { usePreviewFlavorsSelector };
