import { FC, PropsWithChildren } from "react";
import { Grid, Typography, makeStyles } from "@xcira/components";

interface HomeProps {}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  linkButton: {
    backgroundColor: theme.palette.grey[100],
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const Home: FC<PropsWithChildren<HomeProps>> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5">Home Page</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
