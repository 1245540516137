import { FC } from "react";
import { useUserDetails } from "hooks";

const UserProvider: FC = () => {
  useUserDetails();

  return null;
};

export { UserProvider };
