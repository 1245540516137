import { AttendeeTypeEnum } from "graphql/generated";
import { useLoggedInAttendeeCountsSelector } from "./useLoggedInAttendeeCountsSelector";
import { useSelectCurrentEventIds } from "hooks";

const useLoggedInAttendeeCountsByEventId = (
  eventId: number,
  type: AttendeeTypeEnum,
  cacheKey?: number[]
): number => {
  const eventIds = useSelectCurrentEventIds();

  const queryCacheKey = cacheKey ?? eventIds;

  return useLoggedInAttendeeCountsSelector(
    (counts) =>
      counts.find((count) => count.eventId === eventId && count.type === type)?.count ?? 0,
    { eventIds: queryCacheKey }
  );
};

export { useLoggedInAttendeeCountsByEventId };
