import { FC, memo } from "react";
import { Button, Divider, Grid, Paper, Theme, Typography, makeStyles } from "@xcira/components";
import { DateUtil } from "@xcira/commons";
import { useSelectCustomerSalesLinkByFacilityName, useAppDispatch, useCustomerAttend } from "hooks";
import { openDialog } from "slices";
import { AttendeeTypeEnum, EventStatus } from "graphql/generated";

const getColor = (theme: Theme, status: EventStatus) => {
  switch (status) {
    case EventStatus.Active:
      return theme.palette.success.main;
    case EventStatus.Scheduled:
    case EventStatus.Presale:
      return theme.palette.primary.dark;
    case EventStatus.Suspended:
      return theme.palette.warning.main;
    case EventStatus.Closedout:
    case EventStatus.Complete:
    case EventStatus.Reconciled:
      return theme.palette.grey[700];
    default:
      return theme.palette.grey[700];
  }
};

const useStyles = makeStyles<{ status: EventStatus }>()((theme, { status }) => ({
  paper: {
    padding: theme.spacing(2),
  },
  eventStatus: {
    borderRadius: theme.spacing(2),
    backgroundColor: getColor(theme, status),
    color: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(1),
  },
}));

interface CustomerSalesLinkCardProps {
  ahco: string;
  facilityName: string;
}

const CustomerSalesLinkCard: FC<CustomerSalesLinkCardProps> = memo(({ facilityName, ahco }) => {
  const customerSalesLink = useSelectCustomerSalesLinkByFacilityName(ahco, facilityName);
  const { classes } = useStyles({ status: customerSalesLink?.status || EventStatus.NotPresent });
  const eventId = customerSalesLink?.eventId;

  const dispatch = useAppDispatch();

  const viewerAttend = useCustomerAttend(
    ahco,
    customerSalesLink?.eventId ?? 0,
    AttendeeTypeEnum.Viewer
  );

  const handleDialogOpen = (type: AttendeeTypeEnum) => {
    dispatch(
      openDialog({
        type: "CustomerAttend",
        props: {
          facilityName,
          ahco,
          type,
        },
      })
    );
  };

  return (
    <Paper
      elevation={1}
      className={classes.paper}
      data-testid={`customerSalesLinkCard${facilityName}`}
    >
      <Grid className={classes.row} container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="subtitle2"> {customerSalesLink?.laneFullName ?? ""}</Typography>
        </Grid>
        <Grid item>
          {eventId && (
            <Typography variant="subtitle2" className={classes.eventStatus}>
              {customerSalesLink?.status}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Typography className={classes.row} variant="body1">
        {customerSalesLink?.description ?? "No Event Set Up"}
      </Typography>
      <Typography className={classes.row} variant="body2" color="text.secondary">
        {customerSalesLink?.startTime
          ? DateUtil.format(customerSalesLink?.startTime, "MM/D/YYYY HH:mm")
          : ""}{" "}
        -{" "}
        {customerSalesLink?.endTime
          ? DateUtil.format(customerSalesLink?.endTime, "MM/D/YYYY HH:mm")
          : ""}
      </Typography>
      <Divider className={classes.row} />
      <Grid container justifyContent={"space-between"} spacing={1}>
        <Grid item>
          <Button
            variant="text"
            disabled={!eventId}
            onClick={() => handleDialogOpen(AttendeeTypeEnum.Clerk)}
          >
            CLERK
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            disabled={!eventId}
            onClick={() => handleDialogOpen(AttendeeTypeEnum.Techcoord)}
          >
            TECH
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            disabled={!eventId}
            onClick={() => handleDialogOpen(AttendeeTypeEnum.Marquee)}
          >
            MARQUEE
          </Button>
        </Grid>
        <Grid item>
          <Button variant="text" disabled={!eventId} onClick={viewerAttend}>
            VIEWER
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
});

export { CustomerSalesLinkCard };
