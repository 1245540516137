import { AvStreamStatus, Maybe } from "graphql/generated";

export function isPaused(status?: AvStreamStatus, statusInfo?: Maybe<string>): boolean {
  return status === AvStreamStatus.Stopped && statusInfo === "Paused";
}

export function getStatus(
  status?: AvStreamStatus,
  statusInfo?: Maybe<string>
): AvStreamStatus | undefined {
  if (isPaused(status, statusInfo)) {
    return AvStreamStatus.Paused;
  }

  return status;
}
