import { FC } from "react";
import { Grid, Button, Typography } from "@xcira/components";
import {
  useHighestPlayerVersionSelector,
  useSelectAvPublisherByPort,
  useSelectEventById,
} from "hooks";
import { StreamStatusIcon } from "./StreamStatusIcon";
import { StreamStatusUpdateButton } from "./StreamStatusUpdateButton";
import { STREAM_URL } from "../../../constants/constants";

interface StreamManagementControlsProps {
  eventId: number;
  className?: string;
}

const StreamManagementControls: FC<StreamManagementControlsProps> = ({ eventId, className }) => {
  const avPort = useSelectEventById(eventId, (event) => event?.avPort);
  const avPublisher = useSelectAvPublisherByPort(avPort);
  const highestPlayerVersion = useHighestPlayerVersionSelector(
    (item) => item?.highestPlayerVersion
  );

  const playerVersion = highestPlayerVersion ? `&version=${highestPlayerVersion}` : "";

  const handleLaunchPlayer = () => {
    window.open(
      `${STREAM_URL}?stream=${avPublisher?.avStream.name}&host=${avPublisher?.avStream?.avConnection.edgeUrl}${playerVersion}`
    );
  };

  return (
    <Grid
      container
      spacing={2}
      className={className}
      alignItems="center"
      flexWrap="wrap-reverse"
      data-testid={`streamManagementControls${eventId}`}
    >
      <Grid item>
        <StreamStatusIcon avPort={avPort} />
      </Grid>
      {!avPublisher && (
        <Grid item>
          <Typography variant="subtitle2" data-testid="streamManagementControlsNoActive">
            No active stream
          </Typography>
        </Grid>
      )}
      {avPublisher && (
        <>
          <Grid item>
            <StreamStatusUpdateButton eventId={eventId} />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleLaunchPlayer}
              data-testid={`streamManagementControlsLaunchButton${eventId}`}
            >
              Launch Player
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export { StreamManagementControls };
