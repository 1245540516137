import { useGetAvStreamNameByAudioPortQuery } from "graphql/generated";

function useAvStreamNameByAudioPort(audioPort = 0, streamName?: string) {
  const skip = !Boolean(audioPort);
  const name = useGetAvStreamNameByAudioPortQuery({ audioPort }, { skip });

  return streamName ?? name.data?.avStreamNamebyAudioPort;
}

export { useAvStreamNameByAudioPort };
