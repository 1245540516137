import {
  AnyAction,
  createListenerMiddleware,
  isFulfilled,
  isRejectedWithValue,
  PayloadAction,
} from "@reduxjs/toolkit";
import { notify, dismiss } from "@xcira/commons";

type ErrorMessage = {
  error: string;
};

const errorHandler = createListenerMiddleware();

type Arg = {
  endpointName: string;
};

type NotificationsState = {
  notifications: {
    key: string;
  };
};

errorHandler.startListening({
  matcher: isRejectedWithValue,
  effect: (action, listenerApi) => {
    listenerApi.dispatch(
      notify({
        severity: "error",
        message: (action as PayloadAction<ErrorMessage>).payload.error,
        key: (action.meta.arg as Arg).endpointName,
      })
    );
  },
});

errorHandler.startListening({
  matcher: isFulfilled,
  effect: (action: AnyAction, listenerApi) => {
    if (
      (listenerApi.getState() as NotificationsState).notifications.key ===
      action.meta.arg.endpointName
    ) {
      listenerApi.dispatch(dismiss());
    }
  },
});

export { errorHandler };
