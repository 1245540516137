import { Icon, IconButton, makeStyles, TableCell, TableHead, TableRow } from "@xcira/components";
import { RequiresPermissions } from "components/RequiresPermissions";
import { AttendeeTypeEnum, Permission } from "graphql/generated";
import { useAppDispatch, useLoggedInAttendeeCountsTotal } from "hooks";
import { FC } from "react";
import { openDialog } from "slices";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  ports: {
    paddingLeft: theme.spacing(3),
  },
  flexRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  attendeesTotal: {
    textAlign: "center",
  },
}));

interface EventsHeaderProps {
  className?: string;
}

export const EventsHeader: FC<EventsHeaderProps> = ({ className }) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const clerkTotal = useLoggedInAttendeeCountsTotal(AttendeeTypeEnum.Clerk);
  const bidderTotal = useLoggedInAttendeeCountsTotal(AttendeeTypeEnum.Bidder);
  const viewerTotal = useLoggedInAttendeeCountsTotal(AttendeeTypeEnum.Viewer);

  return (
    <TableHead className={className}>
      <TableRow>
        <TableCell>
          <IconButton
            onClick={() => dispatch(openDialog({ type: "MultiAttend", props: {} }))}
            data-testid={`eventMultiLaunchButton`}
          >
            <Icon icon="launch" color="primary" />
          </IconButton>
        </TableCell>
        <TableCell>Auction House</TableCell>
        <TableCell>Facility Name</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Date Time</TableCell>
        <TableCell>Status</TableCell>
        <TableCell align="center">Inventory</TableCell>
        <TableCell>
          <div className={classes.flexRow}>
            <Icon icon="gavel" title="Clerk" />
          </div>
          <div className={classes.attendeesTotal}>{clerkTotal}</div>
        </TableCell>
        <TableCell>
          <div className={classes.flexRow}>
            <Icon icon="visibility" title="Viewers" />
          </div>
          <div className={classes.attendeesTotal}>{viewerTotal}</div>
        </TableCell>
        <TableCell>
          <div className={classes.flexRow}>
            <Icon icon="person" title="Bidders" />
          </div>
          <div className={classes.attendeesTotal}>{bidderTotal}</div>
        </TableCell>
        <RequiresPermissions permissions={Permission.PortsRead}>
          <TableCell className={classes.ports}>Ports</TableCell>
        </RequiresPermissions>
        <RequiresPermissions permissions={Permission.AvStreamsRead}>
          <TableCell align="center">AV</TableCell>
        </RequiresPermissions>
      </TableRow>
    </TableHead>
  );
};
