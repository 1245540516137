import { forwardRef } from "react";
import { default as MuiIconButton, IconButtonProps } from "@mui/material/IconButton";

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiIconButton {...props} ref={ref}>
        {children}
      </MuiIconButton>
    );
  }
);

export type { IconButtonProps };
