import { FC, memo } from "react";
import { makeStyles, Badge, BadgeProps, Icon, IconName } from "@xcira/components";

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IconWithBadgeProps {
  content: number | string | undefined;
  title: string;
  icon: IconName;
  color: BadgeProps["color"];
}

const IconWithBadge: FC<IconWithBadgeProps> = memo(({ content, title, icon, color }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root} title={title}>
      <Badge badgeContent={content} color={color} showZero data-testid="badge">
        <Icon icon={icon} data-testid="icon" />
      </Badge>
    </div>
  );
});

export { IconWithBadge };
