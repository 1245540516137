import { FC } from "react";
import { Grid, Typography, makeStyles } from "@xcira/components";
import { CumulativeStatsFilters } from "./CumulativeStatsFilters";
import { CumulativeStatsTable } from "./CumulativeStatsTable";
import { useRequiresPermissions } from "hooks";
import { Permission } from "graphql/generated";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(4),
  },

  noPermission: { padding: theme.spacing(2) },
}));

const requiredPermissions = [Permission.CumulativeStatsRead];

export const CumulativeStats: FC = () => {
  const { classes } = useStyles();
  const [hasPerms] = useRequiresPermissions(requiredPermissions);

  if (!hasPerms) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.noPermission}
        data-testid="eventsNoPermission"
      >
        <Grid item xs>
          <Typography variant="h6">
            Your credentials don&apos;t have permissions to access this.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Stats Console
      </Typography>
      <CumulativeStatsFilters />
      <CumulativeStatsTable />
    </div>
  );
};
