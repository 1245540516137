import { cloudServicesEndpoints } from "services";
import { GetCumulativeStatsQuery } from "../../graphql/generated";
import { useAppSelector } from "hooks";
import { selectAhco, selectEndDate, selectLimit, selectStartDate } from "slices";

type CumulativeStats = GetCumulativeStatsQuery["cumulativeStats"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: CumulativeStats) => any;

const defaultCumulativeStats: CumulativeStats = {
  cumulativeStats: [],
  totals: {
    totalRunningTime: 0,
    totalLoginTime: 0,
    totalItems: 0,
    totalLotsClerked: 0,
    totalSold: 0,
    totalSoldOlr: 0,
    totalSoldOlrBid: 0,
    totalSoldRunnerup: 0,
    totalAcceptedBidsOlr: 0,
    totalAttendeesOlr: 0,
    totalAttendeesOlrWithoutBid: 0,
    totalAttendeesOlrBid: 0,
    totalAttendeesOlrWon: 0,
    totalSales: 0,
    totalSalesOlr: 0,
    totalAveragePerItem: 0,
    totalAveragePerItemOlr: 0,
    totalPercentageOlrRunnerup: 0,
    totalPercentageOlrBid: 0,
    totalPercentageOlrSold: 0,
    totalPercentageSalesOlr: 0,
  },
};

function useCumulativeStatsSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const limit = useAppSelector(selectLimit);
  const ahco = useAppSelector(selectAhco);

  return cloudServicesEndpoints.GetCumulativeStats.useQueryState(
    { startDate, endDate, limit, ahco },
    {
      selectFromResult: (result) =>
        selector(result.data?.cumulativeStats ?? defaultCumulativeStats),
    }
  );
}

export { useCumulativeStatsSelector };
