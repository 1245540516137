import { FC, memo } from "react";
import {
  Checkbox,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@xcira/components";
import { DateUtil } from "@xcira/commons";
import { AuctionEvent, UpdateEventStatusResponse } from "graphql/generated";

const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    height: "63vh",
    minHeight: 300,
  },
  head: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  checkboxCell: { position: "relative" },
  errorIcon: { position: "absolute", left: 0 },
  checkbox: { marginLeft: theme.spacing(1) },
  facility: { width: 220 },
  clientEventId: { maxWidth: 200, overflow: "hidden", textOverflow: "ellipsis" },
  startTime: { minWidth: 180 },
}));

interface EventsTableProps {
  events: AuctionEvent[] | null;
  eventIds: Set<number>;
  failedUpdateResponses: UpdateEventStatusResponse[];
  status: string;
  handleRowSelectChange: (eventId: number) => void;
}

const EventsTable: FC<EventsTableProps> = memo(
  ({ events, eventIds, status, failedUpdateResponses, handleRowSelectChange }) => {
    const { classes } = useStyles();

    if (!events) {
      return (
        <Typography variant="subtitle1" color="action">
          No {status} events
        </Typography>
      );
    }

    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>Lane</TableCell>
              <TableCell>Sale</TableCell>
              <TableCell>Start Date Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => {
              const failedUpdateResponse = failedUpdateResponses.find(
                (response) => response.id === event.id
              );

              return (
                <TableRow
                  key={event.id}
                  data-testid={`eventRow${event.id}`}
                  selected={eventIds.has(event.id)}
                >
                  <TableCell padding="checkbox" className={classes.checkboxCell}>
                    {failedUpdateResponse && (
                      <Icon
                        icon="error"
                        fontSize="small"
                        color="error"
                        className={classes.errorIcon}
                        title={failedUpdateResponse.message ?? ""}
                      />
                    )}
                    <Checkbox
                      value={event.id}
                      checked={eventIds.has(event.id)}
                      onChange={() => handleRowSelectChange(event.id)}
                      className={classes.checkbox}
                      data-testid={`eventCellCheckbox${event.id}`}
                    />
                  </TableCell>
                  <TableCell title={event.facilityName} className={classes.facility}>
                    {event.facilityName}
                  </TableCell>
                  <TableCell title={event.clientEventId} className={classes.clientEventId}>
                    {event.clientEventId}
                  </TableCell>
                  <TableCell className={classes.startTime}>
                    {event.startTime ? DateUtil.format(event.startTime, "MM/D/YYYY HH:mm:ss") : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export { EventsTable };
