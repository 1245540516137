import { FC } from "react";
import { Grid, Icon, Typography } from "@xcira/components";

export const NoMatch: FC = () => {
  return (
    <Grid container justifyContent="center" alignContent="center" spacing={2}>
      <Grid item>
        <Icon color="primary" icon="engineering" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography color="primary" variant="h4">
          Under Construction
        </Typography>
      </Grid>
    </Grid>
  );
};
