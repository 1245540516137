import { useAppSelector } from "hooks";
import { FC } from "react";
import {
  AttendDialog,
  AvConnectionConfirmationDialog,
  AvStreamDialog,
  CustomerAttendDialog,
  EventStatusDialog,
  MultiAttendDialog,
  PortsConfirmationDialog,
  SetGainDialog,
} from "components";

const ToolsDialog: FC<object> = () => {
  const state = useAppSelector((state) => state.dialogs);

  switch (state.type) {
    case "Attend":
      return <AttendDialog isOpen={state.isOpen} {...state.props} />;
    case "MultiAttend":
      return <MultiAttendDialog isOpen={state.isOpen} {...state.props} />;
    case "Av":
      return <AvStreamDialog isOpen={state.isOpen} {...state.props} />;
    case "Port":
      return <PortsConfirmationDialog isOpen={state.isOpen} {...state.props} />;
    case "SetGain":
      return <SetGainDialog isOpen={state.isOpen} {...state.props} />;
    case "EventStatus":
      return <EventStatusDialog isOpen={state.isOpen} {...state.props} />;
    case "AvConnection":
      return <AvConnectionConfirmationDialog isOpen={state.isOpen} {...state.props} />;
    case "CustomerAttend":
      return <CustomerAttendDialog isOpen={state.isOpen} {...state.props} />;
    default:
      return null;
  }
};

export { ToolsDialog };
