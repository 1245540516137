import {
  discoveryService,
  DiscoveryServiceResponse,
  useGetServicesQuery,
} from "../../services/DiscoveryService";

export const useDiscoveryService = () => useGetServicesQuery("/");

export function useDiscoveryServiceSelector<
  T extends (discovery: DiscoveryServiceResponse) => ReturnType<T>
>(selector: T): ReturnType<T> {
  return discoveryService.endpoints.getServices.useQueryState("/", {
    selectFromResult: (result) => selector(result.data ?? { Items: [] }),
  });
}

export const useDiscoveryServiceStatusSelector = () => {
  const {
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUninitialized,
  } = discoveryService.endpoints.getServices.useQueryState("/");

  return { isError, isFetching, isLoading, isSuccess, isUninitialized };
};
