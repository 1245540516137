import { createTheme } from "@xcira/components";

const black = "#222222";
const gray5 = "#455560";
const gray4 = "#768792";
const gray3 = "#90A1AD";
const gray2 = "#AABCC8";
const gray1 = "#C6D7E4";
const gray0 = "#E2F4FF";
const xciraBlue = "#55BCEB";
const blue = "#056CB6";
const blue3 = "#B7D5E5";
const blue2 = "#D2ECFA";
const blue1 = "#ECF5FB";
const red = "#E31936";
const red2 = "#F2C7C1";
const red1 = "#FDDDD9";
const xciraOrange = "#F58025";
const darkOrange = "#B24F00";
const lightOrange = "#FCEFE4";
const yellow = "#FACE53";
const yellow2 = "#F2E0A9";
const yellow1 = "#FCF0D4";
const green = "#439639";
const green2 = "#C3E5C5";
const green1 = "#DBF3DD";

export const theme = createTheme({
  shape: {
    borderRadius: 5,
  },
  spacing: 8,
  typography: {
    fontFamily: "Roboto",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1367,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: black,
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: black,
      secondary: gray4,
      disabled: gray3,
    },
    grey: {
      50: gray0,
      100: gray0,
      200: gray1,
      400: gray2,
      500: gray3,
      600: gray4,
      700: gray5,
    },
    primary: {
      main: xciraBlue,
      dark: blue,
    },
    secondary: {
      light: lightOrange,
      main: xciraOrange,
      dark: darkOrange,
    },
    error: {
      light: red1,
      main: red,
      dark: red2,
    },
    warning: {
      light: yellow1,
      main: yellow,
      dark: yellow2,
    },
    info: {
      light: blue1,
      main: blue2,
      dark: blue3,
    },
    success: {
      light: green1,
      main: green,
      dark: green2,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
