import { FC } from "react";
import { default as MuiInput, InputProps } from "@mui/material/Input";
import { default as MuiInputLabel, InputLabelProps } from "@mui/material/InputLabel";
import { default as MuiInputAdornment, InputAdornmentProps } from "@mui/material/InputAdornment";

export const Input: FC<InputProps> = ({ ...props }) => {
  return <MuiInput {...props} />;
};

export const InputLabel: FC<InputLabelProps> = ({ children, ...props }) => {
  return <MuiInputLabel {...props}>{children}</MuiInputLabel>;
};

export const InputAdornment: FC<InputAdornmentProps> = ({ children, ...props }) => {
  return <MuiInputAdornment {...props}>{children}</MuiInputAdornment>;
};

export type { InputProps, InputLabelProps, InputAdornmentProps };
