import { FC } from "react";
import { IconButton, Icon } from "@xcira/components";
import { useLazyCumulativeStats } from "hooks";
import { FileUtil } from "@xcira/commons";
import { CUMULATIVE_STATS_HEADERS } from "components/CumulativeStats";
import { convertToTime } from "utils/StatsUtil";
import { CumulativeStatsDto } from "graphql/generated";

const buildCSVRow = (stats?: CumulativeStatsDto) => {
  return stats?.cumulativeStats?.reduce((row, stat) => {
    const arr = [
      stat?.auctionName,
      stat?.date,
      stat?.startTime,
      stat?.endTime,
      convertToTime(stat?.runningTime),
      convertToTime(stat?.totalLoginDuration),
      stat?.totalCars,
      stat?.lotsClerked,
      stat?.totalSold,
      stat?.soldToNet,
      stat?.difItemsNetAccepted,
      stat?.secondHighest,
      stat?.acceptedBids,
      stat?.biddersAttended,
      stat?.netWithoutBid,
      stat?.acceptedNetBids,
      stat?.netPurchasedItems,
      stat?.totalSales?.toFixed(2),
      stat?.totalNetSales?.toFixed(2),
      stat?.averagePerItem?.toFixed(2),
      stat?.averageItemOlr?.toFixed(2),
      stat?.percentageOlrRunnerup?.toFixed(0),
      stat?.percentageOlrBid?.toFixed(0),
      stat?.percentageOlrSold?.toFixed(0),
      stat?.percentageSalesOlr?.toFixed(0),
    ];
    return row.concat(arr.toString() + "\n");
  }, "");
};

export const CumulativeStatsDownloadButton: FC = () => {
  const trigger = useLazyCumulativeStats();

  const onClick = async () => {
    const csvHeaders = CUMULATIVE_STATS_HEADERS.toString() + "\n";
    const { data } = await trigger();

    FileUtil.createAndDownloadFile(
      csvHeaders.concat(buildCSVRow(data?.cumulativeStats) ?? ""),
      "cumulativeStats.csv"
    );
  };

  return (
    <IconButton
      onClick={onClick}
      title="Download CSV"
      data-testid="cumulativeStatsDownloadButton"
      size="large"
    >
      <Icon icon="download" fontSize="inherit" />
    </IconButton>
  );
};
