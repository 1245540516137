import { FC } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@xcira/components";
import {
  useAppDispatch,
  useHighestPlayerVersionSelector,
  useSelectAvPublisherByPort,
  useSelectAvStreamByPort,
  useHighestPlayerVersion,
} from "hooks";
import { DateUtil } from "@xcira/commons";
import { getStatus } from "utils/AvStreamUtil";
import { closeDialog, ExtractDialogProps } from "slices/dialogs";
import { STREAM_URL } from "../../../constants/constants";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  dialogContent: {
    width: "85vw",
    maxWidth: 560,
    overflowX: "hidden",
  },
  streamInfoGrid: { marginBottom: theme.spacing(1) },
  streamInfoText: { wordBreak: "break-word" },
  listItem: { paddingLeft: 0 },
  listItemText: { marginRight: theme.spacing(3), flex: 0.7 },
}));

export type AvStreamDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"Av">;

const AvStreamDialog: FC<AvStreamDialogProps> = ({
  isOpen,
  avPort,
  auctionCompany,
  facilityName,
  clientEventId,
}) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const avPublisher = useSelectAvPublisherByPort(avPort);
  const avStream = useSelectAvStreamByPort(avPort);

  useHighestPlayerVersion();
  const highestPlayerVersion = useHighestPlayerVersionSelector(
    (item) => item?.highestPlayerVersion
  );

  const playerVersion = highestPlayerVersion ? `&version=${highestPlayerVersion}` : "";

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleLaunchPlayer = () => {
    window.open(
      `${STREAM_URL}?stream=${avPublisher?.avStream.name}&host=${avPublisher?.avStream?.avConnection.edgeUrl}${playerVersion}`
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.dialog}
      data-testid="avStreamDialog"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="avStreamDialogTitle">
          Live Stream Details
        </Typography>
        <IconButton aria-label="close" onClick={handleClose} data-testid="avStreamDialogClose">
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container spacing={2} className={classes.streamInfoGrid}>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Auction House</Typography>
              </Grid>
              <Grid item flexWrap="wrap">
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogAuctionCompany"
                >
                  {auctionCompany}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Facility Name</Typography>
              </Grid>
              <Grid item flexWrap="wrap">
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogFacilityName"
                >
                  {facilityName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Client Event ID</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogClientEventId"
                >
                  {clientEventId}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">AV Port</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogAvPort"
                >
                  {avPort}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Stream Name</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogStreamName"
                >
                  {avPublisher?.avStream.name ?? avStream?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Stream Type</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogStreamType"
                >
                  {avPublisher?.avStream.streamType ?? avStream?.streamType}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Stream Status</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogStreamStatus"
                >
                  {getStatus(avPublisher?.status, avPublisher?.statusInfo) ?? "NOT ACTIVE"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">CDN</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="avStreamDialogCDN"
                >
                  {avPublisher?.avStream.avConnection.description ??
                    avStream?.avConnection.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {avPublisher && (
            <>
              <Grid item xs={6} sm={4}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography variant="subtitle2">Video FPS</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.streamInfoText}
                      data-testid="avStreamDialogVideoFps"
                    >
                      {avPublisher?.avStream.avEncoderSettings.videoFps}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography variant="subtitle2">Audio Bitrate</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.streamInfoText}
                      data-testid="avStreamDialogAudioBitrate"
                    >
                      {avPublisher?.avStream.avEncoderSettings.audioBitrate}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography variant="subtitle2">Start Date Time</Typography>
                  </Grid>
                  <Grid item>
                    {avPublisher?.startTime && (
                      <Typography
                        variant="body2"
                        className={classes.streamInfoText}
                        data-testid="avStreamDialogStartTime"
                      >
                        {DateUtil.format(avPublisher?.startTime, "MM/D/YYYY HH:mm:ss")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography variant="subtitle2">Last Heartbeat</Typography>
                  </Grid>
                  <Grid item>
                    {avPublisher?.lastModified && (
                      <Typography
                        variant="body2"
                        className={classes.streamInfoText}
                        data-testid="avStreamDialogLastModified"
                      >
                        {DateUtil.format(avPublisher?.lastModified, "MM/D/YYYY HH:mm:ss")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Divider />

        <List>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={avPublisher?.description ?? avStream?.avEncoderSettings.description}
              primaryTypographyProps={{ variant: "body2" }}
              className={classes.listItemText}
              data-testid="avStreamDialogDescription"
            />
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                onClick={() => handleLaunchPlayer()}
                disabled={!avPublisher}
                data-testid="avStreamDialogLaunchPlayer"
              >
                Launch Player
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};

export { AvStreamDialog };
