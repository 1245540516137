import { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { selectIsAuthenticated } from "slices/auth";
import { useAppSelector } from "hooks";
import {
  ApplicationStatus,
  Events,
  EventDetails,
  Login,
  NoMatch,
  EventsProvider,
  UserProvider,
  ToolsDialog,
  CustomerSalesLinks,
  Reports,
} from "components";
import { Facilities } from "components/Facilities";
import { CumulativeStats } from "components/CumulativeStats";

interface ProtectedRoutesProps {}

export type RouterParams = {
  eventId: string;
};

export type CustomerSalesLinksParams = {
  ahco: string;
};

export const ProtectedRoutes: FC<ProtectedRoutesProps> = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <>
      <UserProvider />
      <ToolsDialog data-testid="toolsDialog" />
      <Switch>
        <Route exact path="/">
          <Redirect to="/events" />
        </Route>
        <Route path="/applicationstatus">
          <ApplicationStatus />
        </Route>
        <Route exact path="/events">
          <Events />
        </Route>
        <Route path="/events/:eventId">
          <EventsProvider>
            <EventDetails />
          </EventsProvider>
        </Route>
        <Route path="/configurations">
          <Facilities />
        </Route>
        <Route path="/reports/stats">
          <CumulativeStats />
        </Route>
        <Route path="/reports">
          <Reports />
        </Route>
        <Route path="/customer/links/:ahco">
          <CustomerSalesLinks />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};
