import { Severity, notify } from "@xcira/commons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  makeStyles,
} from "@xcira/components";
import { useAppDispatch, useAvPublishers, useAvStreams } from "hooks";
import { useChangeAvConnection } from "hooks/useChangeAvConnection/useChangeAvConnection";
import { FC } from "react";
import { ExtractDialogProps, closeDialog } from "slices";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  dialogContent: {
    overflowX: "hidden",
    maxWidth: 540,
  },
  dialogActions: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
}));

type AvConnectionConfirmationDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"AvConnection">;

const AvConnectionConfirmationDialog: FC<AvConnectionConfirmationDialogProps> = ({
  isOpen,
  facilityName = "",
  description,
  type,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { refetch: refetchAvStreams } = useAvStreams();
  const { refetch: refetchAvPublishers } = useAvPublishers();
  const [changeAvConnection] = useChangeAvConnection(facilityName, type);

  const reloadData = () => {
    refetchAvStreams();
    refetchAvPublishers();
  };

  const handleSubmit = async () => {
    const response = await changeAvConnection();

    let message = "";
    let severity: Severity = "error";

    if (response.status === "SUCCESS") {
      severity = "success";
      message = `CDN successfully changed to ${description}.`;
    } else {
      message = `Unable to change cdn: ${response.message}.`;
    }
    dispatch(notify({ message, severity }));
    reloadData();
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="avConnectionConfirmationDialogTitle">
          Change CDN
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          data-testid="avConnectionConfirmationDialogClose"
        >
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Typography variant="body2">
          Are you sure you want to change the CDN to {type} - {description}?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          autoFocus
          data-testid="portsConfirmationDialogCancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          data-testid="portsConfirmationDialogOkButton"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AvConnectionConfirmationDialog };
