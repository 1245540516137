import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { DateUtil, orderBy as _orderBy } from "@xcira/commons";
import {
  AuctionCompany as GeneratedAuctionCompany,
  AuctionEvent,
  EventStatus,
} from "graphql/generated";
import { RootState } from "app/store";
import { selectEvents } from "services";

function hasStarted(startTime: number, rangeEnd: number) {
  return DateUtil.isSameOrBefore(startTime, rangeEnd);
}

function hasNotEndedBeforeStart(rangeStart: number, endTime: number) {
  return DateUtil.isSameOrAfter(endTime, rangeStart);
}

function includesStatus(eventStatusFilter: EventStatusFilter[], status: EventStatus) {
  const statuses = eventStatusFilter.reduce((acc, eventStatus) => {
    const { status } = eventStatus;
    if (status) {
      acc.push(status);
    }
    return acc;
  }, [] as EventStatus[]);

  return statuses.length === 0 || statuses.includes(status);
}

export type AuctionCompany = Omit<GeneratedAuctionCompany, "flavorGroup" | "flavorGroupName">;

export const dateLabels = [
  "All Days",
  "Today",
  "Yesterday",
  "Tomorrow",
  "Next 7 Days",
  "Next 31 Days",
] as const;

export type DateLabel = typeof dateLabels[number];

export interface DateFilterPayload {
  label: DateLabel;
  now: number;
}
export interface DateFilter {
  label: DateLabel;
  startTime: number;
  endTime: number;
}

export const sortLabels = [
  "Facility Name Ascending",
  "Facility Name Descending",
  "Start Time Ascending",
  "Start Time Descending",
];
export type SortLabel = typeof sortLabels[number];
export type Direction = "asc" | "desc";
export type SortField = "facilityName" | "startTime";

export interface OrderBy {
  label: SortLabel;
  field: SortField;
  direction: Direction;
}

export interface OrderByPayload {
  label: SortLabel;
}

export const eventStatusLabels = [
  "All",
  "Scheduled",
  "Presale",
  "Active",
  "Complete",
  "Closedout",
] as const;

export type EventStatusLabel = typeof eventStatusLabels[number];

export interface EventStatusFilterPayload {
  labels: EventStatusLabel[];
}

export interface EventStatusFilter {
  label: EventStatusLabel;
  status?: EventStatus;
}

interface EventState {
  auctionHouseFilter: Omit<AuctionCompany, "isActive">;
  dateFilter: DateFilter;
  eventStatusFilter: EventStatusFilter[];
  orderBy: OrderBy;
}

const init = (): EventState => ({
  auctionHouseFilter: {
    __typename: "AuctionCompany",
    auctionCompany: "All",
    fullName: "All",
    id: 0,
  },
  dateFilter: {
    label: "Today",
    startTime: DateUtil.toStartOf(Date.now(), "day"),
    endTime: DateUtil.toEndOf(Date.now(), "day"),
  },
  eventStatusFilter: [
    {
      label: "All",
    },
  ],
  orderBy: {
    label: "Facility Name Ascending",
    field: "facilityName",
    direction: "asc",
  },
});

const events = createSlice({
  name: "events",
  initialState: init,
  reducers: {
    setAuctionHouseFilter: (state, action: PayloadAction<Omit<AuctionCompany, "isActive">>) => {
      state.auctionHouseFilter = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<OrderByPayload>) => {
      const { label } = action.payload;
      let field: SortField = "facilityName";
      let direction: Direction = "asc";

      switch (label) {
        case "Facility Name Ascending": {
          field = "facilityName";
          direction = "asc";
          break;
        }
        case "Facility Name Descending": {
          field = "facilityName";
          direction = "desc";
          break;
        }
        case "Start Time Ascending": {
          field = "startTime";
          direction = "asc";
          break;
        }
        case "Start Time Descending": {
          field = "startTime";
          direction = "desc";
          break;
        }
      }

      state.orderBy = {
        label,
        field,
        direction,
      };
    },
    setEventStatusFilter: (state, action: PayloadAction<EventStatusFilterPayload>) => {
      const { labels } = action.payload;
      const filters: EventStatusFilter[] = [];
      const currLabels = state.eventStatusFilter.map((filter) => filter.label);

      if (labels.length === 0 || (labels.includes("All") && !currLabels.includes("All"))) {
        state.eventStatusFilter = [
          {
            label: "All",
          },
        ];

        return;
      }

      labels.forEach((label) => {
        switch (label) {
          case "Scheduled":
            filters.push({
              label,
              status: EventStatus.Scheduled,
            });
            break;
          case "Presale":
            filters.push({
              label,
              status: EventStatus.Presale,
            });
            break;
          case "Active":
            filters.push({
              label,
              status: EventStatus.Active,
            });
            break;
          case "Complete":
            filters.push({
              label,
              status: EventStatus.Complete,
            });
            break;
          case "Closedout":
            filters.push({
              label,
              status: EventStatus.Closedout,
            });
            break;
        }
      });

      state.eventStatusFilter = filters;
    },
    setDateFilter: (state, action: PayloadAction<DateFilterPayload>) => {
      const { label, now } = action.payload;

      let startTime;
      let endTime;

      switch (label) {
        case "All Days": {
          startTime = 0;
          endTime = DateUtil.add(DateUtil.toEndOf(now, "day"), 100, "years");

          break;
        }
        case "Today": {
          startTime = DateUtil.toStartOf(now, "day");
          endTime = DateUtil.toEndOf(now, "day");

          break;
        }
        case "Yesterday": {
          const yesterday = DateUtil.subract(now, 1, "days");

          startTime = DateUtil.toStartOf(yesterday, "day");
          endTime = DateUtil.toEndOf(yesterday, "day");

          break;
        }
        case "Tomorrow": {
          const tomorrow = DateUtil.add(now, 1, "days");

          startTime = DateUtil.toStartOf(tomorrow, "day");
          endTime = DateUtil.toEndOf(tomorrow, "day");

          break;
        }
        case "Next 7 Days": {
          const sevenDaysFromNow = DateUtil.add(now, 1, "week");

          startTime = DateUtil.toStartOf(now, "day");
          endTime = DateUtil.toEndOf(sevenDaysFromNow, "day");

          break;
        }
        case "Next 31 Days": {
          const thirtyDaysFromNow = DateUtil.add(now, 1, "month");

          startTime = DateUtil.toStartOf(now, "day");
          endTime = DateUtil.toEndOf(thirtyDaysFromNow, "day");

          break;
        }
      }

      state.dateFilter = {
        label,
        startTime,
        endTime,
      };
    },
  },
});

export const { name: eventsReducerPath, reducer: eventsReducer } = events;
export const {
  setAuctionHouseFilter,
  setDateFilter,
  setEventStatusFilter,
  setOrderBy,
} = events.actions;
export const selectAuctionHouseFilter = (state: RootState) => state.events.auctionHouseFilter;
export const selectDateFilter = (state: RootState) => state.events.dateFilter;
export const selectEventStatusFilter = (state: RootState) => state.events.eventStatusFilter;
export const selectOrderBy = (state: RootState) => state.events.orderBy;

export const selectFilteredEvents = createSelector(
  selectEvents,
  selectAuctionHouseFilter,
  selectDateFilter,
  selectEventStatusFilter,
  selectOrderBy,
  (eventsServiceState, auctionHouseFilter, dateFilter, eventStatusFilter, orderBy) => {
    const events = eventsServiceState.data?.auctionEvents;

    const { startTime, endTime } = dateFilter;
    const { field, direction } = orderBy;

    const result = (events?.filter((event) => {
      const ahcoFilter =
        auctionHouseFilter.auctionCompany === "All"
          ? true
          : event?.auctionCompany === auctionHouseFilter?.auctionCompany;

      const eventStart = event?.startTime ?? 0;
      const eventEnd = event?.endTime ?? 0;

      const eventHasStarted = hasStarted(eventStart, endTime);
      const eventHasNotEndedBeforeStart = hasNotEndedBeforeStart(startTime, eventEnd);

      return (
        ahcoFilter &&
        eventHasStarted &&
        eventHasNotEndedBeforeStart &&
        includesStatus(eventStatusFilter, event?.status)
      );
    }) ?? []) as AuctionEvent[];

    return _orderBy(result, [field], direction);
  }
);
