import { FC } from "react";
import {
  Container,
  ErrorBoundary,
  makeStyles,
  LocalizationProvider,
  AdapterDayjs,
} from "@xcira/components";
import { useDiscoveryService } from "@xcira/commons";
import { NavBar, ProtectedRoutes, Notifications, PublisherPage } from "components";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@xcira/components";
import { theme } from "../config";
import { Provider } from "react-redux";
import { persistor, store } from "../app/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
}));

export interface AppProps {}

const App: FC<AppProps> = () => {
  const { isLoading } = useDiscoveryService();
  const { classes } = useStyles();

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Switch>
          <Route exact path="/av/streamer">
            <PublisherPage />
          </Route>
          <Route path="*">
            <NavBar position="sticky" />
            <Container maxWidth="xl" className={classes.root}>
              <ProtectedRoutes />
            </Container>
          </Route>
        </Switch>
        <Notifications />
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

const AppWithProviders: FC<AppProps> = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppWithProviders;
