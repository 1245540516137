import { FC } from "react";
import { default as MuiCard, CardProps } from "@mui/material/Card";
import { default as MuiCardHeader, CardHeaderProps } from "@mui/material/CardHeader";
import { default as MuiCardActionArea, CardActionAreaProps } from "@mui/material/CardActionArea";
import { default as MuiCardActions, CardActionsProps } from "@mui/material/CardActions";
import { default as MuiCardContent, CardContentProps } from "@mui/material/CardContent";
import { default as MuiCardMedia, CardMediaProps } from "@mui/material/CardMedia";

export const Card: FC<CardProps> = ({ children, ...props }) => {
  return <MuiCard {...props}>{children}</MuiCard>;
};

export const CardHeader: FC<CardHeaderProps> = ({ children, ...props }) => {
  return <MuiCardHeader {...props}>{children}</MuiCardHeader>;
};

export const CardActionArea: FC<CardActionAreaProps> = ({ children, ...props }) => {
  return <MuiCardActionArea {...props}>{children}</MuiCardActionArea>;
};

export const CardActions: FC<CardActionsProps> = ({ children, ...props }) => {
  return <MuiCardActions {...props}>{children}</MuiCardActions>;
};

export const CardContent: FC<CardContentProps> = ({ children, ...props }) => {
  return <MuiCardContent {...props}>{children}</MuiCardContent>;
};

export const CardMedia: FC<CardMediaProps> = ({ children, ...props }) => {
  return <MuiCardMedia {...props}>{children}</MuiCardMedia>;
};

export type {
  CardProps,
  CardHeaderProps,
  CardActionAreaProps,
  CardActionsProps,
  CardContentProps,
  CardMediaProps,
};
