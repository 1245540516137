import { FC, memo, useMemo } from "react";
import { makeStyles, Grid, Typography, Box } from "@xcira/components";

interface StreamInfoProps {
  html?: string | null;
}

const useStyles = makeStyles()((theme) => ({
  box: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 220,
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
  },
  capitalize: { textTransform: "capitalize" },
}));

const parser = new DOMParser();
const defaultHtml = "<ul></ul>";

type ParsedStreamInfo = {
  label: string;
  values: {
    label: string;
    value: string;
  }[];
};

const StreamInfo: FC<StreamInfoProps> = memo(({ html }) => {
  const { classes } = useStyles();

  const parsedStreamInfo = useMemo(() => {
    const parsedDocument = parser.parseFromString(html ?? defaultHtml, "text/html");

    // @ts-expect-error - horrible dom stuff because of bad code
    const parentNodes = parsedDocument.body.querySelector("ul").querySelectorAll(":scope > li");

    const streamInfo: ParsedStreamInfo[] = [];

    parentNodes.forEach((node) => {
      // @ts-expect-error - horrible dom stuff because of bad code
      const key: string | undefined = node.firstChild?.data;

      if (key) {
        streamInfo.push({
          label: key,
          values: Array.from(node.querySelectorAll("li")).map((node) => {
            const text = node.textContent;

            const [label, value] = text?.split(":").map((value) => value.trim()) ?? [];

            return { label, value };
          }),
        });
      }
    });

    return streamInfo;
  }, [html]);

  return (
    <Grid container spacing={2} data-testid="streamInfo">
      {parsedStreamInfo.map((streamInfo, index) => (
        <Grid item xs={12} lg={4} key={`${streamInfo.label}${index}`}>
          <Box className={classes.box} data-testid={`streamInfoBox${streamInfo.label}`}>
            <Typography
              variant="body1"
              className={classes.capitalize}
              data-testid={`streamInfoTitleText${streamInfo.label}`}
            >
              {streamInfo.label}
            </Typography>
            {streamInfo.values.map((item) => (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
                key={`${streamInfo.label}${item.label}`}
                data-testid={`streamInfoGrid${streamInfo.label}${item.label}`}
              >
                <Grid item>
                  <Typography variant="subtitle2" className={classes.capitalize}>
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{item.value}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
});

export { StreamInfo };
