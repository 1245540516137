import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

const auth = createSlice({
  name: "auth",
  initialState: {
    authToken: "guest",
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    logout: (state) => {
      state.authToken = "guest";
    },
  },
});

export const { name: authReducerPath, reducer: authReducer } = auth;
export const { setAuthToken, logout } = auth.actions;
export const selectIsAuthenticated = (state: RootState) => state.auth.authToken !== "guest";
