import { AttendeeTypeEnum } from "graphql/generated";
import { useCallback } from "react";
import { useMultiAttendMutation } from "services";

function useMultiAttend(
  ahco: string | undefined,
  type: AttendeeTypeEnum,
  clientEventId: string[],
  clientAuctionIds: string[],
  flavorOverride?: string,
  devOverride?: string
) {
  const [multiAttend] = useMultiAttendMutation();

  return useCallback(async () => {
    if (ahco) {
      const link = await multiAttend({
        ahco,
        type,
        flavorOverride,
        clientAuctionIds,
        clientEventId,
        devOverride,
      }).unwrap();

      const popupArgs = [
        link.multiAttend,
        "_blank",
        "location=no, scrollbars=yes, resizable=yes, width=1250, height=950",
      ];
      const { availHeight, availWidth } = window.screen;
      const popup = window.open(...popupArgs);

      popup?.moveTo(0, 0);
      popup?.resizeTo(availWidth, availHeight);
    }
  }, [multiAttend, ahco, type, clientEventId, clientAuctionIds, flavorOverride, devOverride]);
}

export { useMultiAttend };
