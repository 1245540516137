import { useMemo } from "react";
import { usePermissions } from "hooks";
import { Permission } from "graphql/generated";

type Permissions = Permission | Permission[];

type PermissionArray<T extends Permissions> = T extends Permission[]
  ? {
      [key in keyof T]: T[key];
    }
  : [T];

type TypeFromArray<T extends Permissions> = T extends (infer U)[] ? U : T;

type ToPermissionResult<T extends string> = {
  [key in T]: boolean;
};

type PermissionResult<T extends Permissions> = ToPermissionResult<
  TypeFromArray<PermissionArray<T>>
>;
type ResultKey<T extends Permissions> = keyof PermissionResult<T>;

const checkPermissions = <T extends Permissions>(
  permissions: T,
  userPermissions: Permission[]
): [boolean, PermissionResult<T>] => {
  const permsToCheck = Array.isArray(permissions) ? permissions : [permissions];
  const permissionResults: PermissionResult<T> = ({} as unknown) as PermissionResult<T>;

  for (const permission of permsToCheck as ResultKey<T>[]) {
    permissionResults[permission] = userPermissions.includes(permission);
  }

  const hasPermissions = Object.values(permissionResults).every((result) => result);

  return [hasPermissions, permissionResults];
};

function useRequiresPermissions<T extends Permission | Permission[]>(permissions: T) {
  const userPermissions = usePermissions();

  return useMemo(() => checkPermissions(permissions, userPermissions), [
    permissions,
    userPermissions,
  ]);
}

export { useRequiresPermissions };
