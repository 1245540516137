import { FC } from "react";
import { default as MuiFormControl, FormControlProps } from "@mui/material/FormControl";
import { default as MuiFormLabel, FormLabelProps } from "@mui/material/FormLabel";

export const FormControl: FC<FormControlProps> = ({ children, ...props }) => {
  return <MuiFormControl {...props}>{children}</MuiFormControl>;
};

export const FormLabel: FC<FormLabelProps> = ({ children, ...props }) => {
  return <MuiFormLabel {...props}>{children}</MuiFormLabel>;
};

export type { FormControlProps, FormLabelProps };
