import { cloudServicesEndpoints } from "services";
import { GetUserQuery } from "graphql/generated";

type UserDetails = GetUserQuery["getUser"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: UserDetails) => any;

const defaultUserDetails: UserDetails = {
  permissions: [],
  username: "",
};

function useUserDetailsSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetUser.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.getUser ?? defaultUserDetails),
  });
}

export { useUserDetailsSelector };
