import { FC, forwardRef } from "react";
import MuiMenu from "@mui/material/Menu";
import type { MenuProps } from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import type { MenuItemProps } from "@mui/material/MenuItem";
import MuiMenuList from "@mui/material/MenuList";
import type { MenuListProps } from "@mui/material/MenuList";

const Menu: FC<MenuProps> = ({ children, ...props }) => {
  return <MuiMenu {...props}>{children}</MuiMenu>;
};

const MenuItem: FC<MenuItemProps> = ({ children, ...props }) => {
  return <MuiMenuItem {...props}>{children}</MuiMenuItem>;
};

const MenuList = forwardRef<HTMLUListElement, MenuListProps>((props, ref) => (
  <MuiMenuList {...props} ref={ref} />
));

export { Menu, MenuItem, MenuList };
export type { MenuProps, MenuItemProps, MenuListProps };
