import { AttendeeTypeEnum, useCustomerAttendMutation } from "graphql/generated";
import { useCallback } from "react";

function useCustomerAttend(
  ahco: string,
  eventId: number,
  type: AttendeeTypeEnum,
  username?: string,
  password?: string
) {
  const [customerAttend] = useCustomerAttendMutation();

  return useCallback(async () => {
    const link = await customerAttend({ ahco, eventId, type, username, password }).unwrap();

    const popupArgs = [
      link.customerAttend,
      "_blank",
      "location=no, scrollbars=yes, resizable=yes, width=1250, height=950",
    ];
    const { availHeight, availWidth } = window.screen;
    const popup = window.open(...popupArgs);

    popup?.moveTo(0, 0);
    popup?.resizeTo(availWidth, availHeight);

    return link;
  }, [customerAttend, ahco, eventId, type, username, password]);
}

export { useCustomerAttend };
