import { FC } from "react";
import { default as MuiAppBar, AppBarProps } from "@mui/material/AppBar";
import { default as MuiToolBar } from "@mui/material/Toolbar";

export const AppBar: FC<AppBarProps> = ({ children, ...props }) => {
  return (
    <MuiAppBar {...props}>
      <MuiToolBar>{children}</MuiToolBar>
    </MuiAppBar>
  );
};

export type { AppBarProps };
