import {
  GetLoggedInAttendeeCountsQuery,
  GetLoggedInAttendeeCountsQueryVariables,
} from "graphql/generated";
import { cloudServicesEndpoints } from "services";
type LoggedInAttendeeCounts = GetLoggedInAttendeeCountsQuery["loggedInAttendeeCounts"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: LoggedInAttendeeCounts) => any;

function useLoggedInAttendeeCountsSelector<T extends SelectFromResult>(
  selector: T,
  queryVariables?: GetLoggedInAttendeeCountsQueryVariables
): ReturnType<T> {
  return cloudServicesEndpoints.GetLoggedInAttendeeCounts.useQueryState(queryVariables, {
    selectFromResult: (result) => selector(result.data?.loggedInAttendeeCounts ?? []),
  });
}

export { useLoggedInAttendeeCountsSelector };
