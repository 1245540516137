import { useEffect } from "react";

const DEFAULT_TITLE = "Xcira OLR Tools";

export const useSaleTitleAsWindowTitle = (saleTitle?: string): void => {
  useEffect(() => {
    if (saleTitle) {
      document.title = saleTitle;
    }

    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [saleTitle]);
};
