import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { STATS_DEFAULT_LIMIT } from "../../constants/constants";
import { AuctionCompany } from "graphql/generated";

// TODO: this should not use Dates, they are not serializable

interface CumulativeStatsState {
  auctionHouseFilter: Omit<AuctionCompany, "isActive">;
  startDate: Date;
  endDate: Date;
  limit: number;
}
const init = (): CumulativeStatsState => ({
  auctionHouseFilter: {
    __typename: "AuctionCompany",
    auctionCompany: "All",
    fullName: "All",
    id: 0,
  },
  startDate: new Date(),
  endDate: new Date(),
  limit: STATS_DEFAULT_LIMIT,
});

const cumulativeStats = createSlice({
  name: "cumulativeStats",
  initialState: init,
  reducers: {
    setCumulativeStatsAuctionHouseFilter: (
      state,
      action: PayloadAction<Omit<AuctionCompany, "isActive">>
    ) => {
      state.auctionHouseFilter = action.payload;
    },
    setStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
  },
});

export const {
  name: cumulativeStatsReducerPath,
  reducer: cumulativeStatsReducer,
} = cumulativeStats;
export const {
  setCumulativeStatsAuctionHouseFilter,
  setEndDate,
  setStartDate,
  setLimit,
} = cumulativeStats.actions;

export const selectAhco = (state: RootState) =>
  state.cumulativeStats.auctionHouseFilter.auctionCompany === "All"
    ? undefined
    : state.cumulativeStats.auctionHouseFilter.auctionCompany;
export const selectCumulativeStatsAuctionHouseFilter = (state: RootState) =>
  state.cumulativeStats.auctionHouseFilter;
export const selectStartDate = (state: RootState) => state.cumulativeStats.startDate;
export const selectEndDate = (state: RootState) => state.cumulativeStats.endDate;
export const selectLimit = (state: RootState) => state.cumulativeStats.limit;
