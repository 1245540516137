import { FC, memo } from "react";
import { TableCell, TableRow, makeStyles } from "@xcira/components";
import { useSelectCumulativeStatById } from "hooks/useCumulativeStats";
import { convertToTime } from "utils/StatsUtil";

const useStyles = makeStyles()((theme) => ({
  row: {
    "& td": {
      whiteSpace: "nowrap",
      textAlign: "center",
      padding: theme.spacing(1),
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

interface CumulativeStatsRowProps {
  cumulativeStatsId: number;
}

export const CumulativeStatsRow: FC<CumulativeStatsRowProps> = memo(({ cumulativeStatsId }) => {
  const { classes } = useStyles();
  const stat = useSelectCumulativeStatById(cumulativeStatsId);

  return (
    <TableRow className={classes.row} data-testid={`cumulativeStatsRow-${stat?.id}`}>
      <TableCell>{stat?.auctionName}</TableCell>
      <TableCell>{stat?.date}</TableCell>
      <TableCell>{stat?.startTime}</TableCell>
      <TableCell>{stat?.endTime}</TableCell>
      <TableCell>{convertToTime(stat?.runningTime)}</TableCell>
      <TableCell>{convertToTime(stat?.totalLoginDuration)}</TableCell>
      <TableCell>{stat?.totalCars}</TableCell>
      <TableCell>{stat?.lotsClerked}</TableCell>
      <TableCell>{stat?.totalSold}</TableCell>
      <TableCell>{stat?.soldToNet}</TableCell>
      <TableCell>{stat?.difItemsNetAccepted}</TableCell>
      <TableCell>{stat?.secondHighest}</TableCell>
      <TableCell>{stat?.acceptedBids}</TableCell>
      <TableCell>{stat?.biddersAttended}</TableCell>
      <TableCell>{stat?.netWithoutBid}</TableCell>
      <TableCell>{stat?.acceptedNetBids}</TableCell>
      <TableCell>{stat?.netPurchasedItems}</TableCell>
      <TableCell>{stat?.totalSales?.toFixed(2)}</TableCell>
      <TableCell>{stat?.totalNetSales?.toFixed(2)}</TableCell>
      <TableCell>{stat?.averagePerItem?.toFixed(2)}</TableCell>
      <TableCell>{stat?.averageItemOlr?.toFixed(2)}</TableCell>
      <TableCell>{stat?.percentageOlrRunnerup?.toFixed(0)}</TableCell>
      <TableCell>{stat?.percentageOlrBid?.toFixed(0)}</TableCell>
      <TableCell>{stat?.percentageOlrSold?.toFixed(0)}</TableCell>
      <TableCell>{stat?.percentageSalesOlr?.toFixed(0)}</TableCell>
    </TableRow>
  );
});
