import { FC, useState, MouseEvent, useCallback } from "react";
import { Button, Icon, makeStyles, Menu, MenuItem } from "@xcira/components";
import { useSelectAvPublisherByPort } from "hooks/useAvPublishers";
import {
  AvStreamStatus,
  Permission,
  SetAvPublisherActionMutation,
  StreamActionEnum,
} from "graphql/generated";
import {
  useAppDispatch,
  useRequiresPermissions,
  useSelectEventById,
  useSetAvPublisherAction,
} from "hooks";
import { notify } from "@xcira/commons";
import { RequiresPermissions } from "components";
import { openDialog } from "slices";

const useStyles = makeStyles()(() => ({
  menuButton: {
    textTransform: "capitalize",
  },
  startStreamBtn: {
    minWidth: 100,
  },
}));

const requiredPermissions = [Permission.AvStreamsUpdate, Permission.AvStreamsStop];

interface StreamStatusUpdateButtonProps {
  eventId: number;
}

const StreamStatusUpdateButton: FC<StreamStatusUpdateButtonProps> = ({ eventId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const [, perms] = useRequiresPermissions(requiredPermissions);

  const avPort = useSelectEventById(eventId, (event) => event?.avPort ?? 0);

  const avPublisher = useSelectAvPublisherByPort(avPort);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setAvPublisherStopAction = useSetAvPublisherAction(StreamActionEnum.Stop, avPort);
  const setAvPublisherStartAction = useSetAvPublisherAction(StreamActionEnum.Start, avPort);
  const setAvPublisherReloadAction = useSetAvPublisherAction(StreamActionEnum.Reload, avPort);

  const handleAction = useCallback(
    async (action: StreamActionEnum) => {
      let setAction: Promise<SetAvPublisherActionMutation>;
      switch (action) {
        case StreamActionEnum.Stop:
          setAction = setAvPublisherStopAction();
          break;
        case StreamActionEnum.Start:
          setAction = setAvPublisherStartAction();
          break;
        case StreamActionEnum.Reload:
          setAction = setAvPublisherReloadAction();
          break;
      }
      const {
        setAvPublisherAction: { success },
      } = await setAction;

      if (success) {
        dispatch(
          notify({
            message: `${action} action was successful. It may take 60 seconds to update the current stream status.`,
            severity: "success",
          })
        );
      }

      handleClose();
    },
    [setAvPublisherStopAction, setAvPublisherStartAction, setAvPublisherReloadAction, dispatch]
  );

  const handleDialogOpen = () => {
    dispatch(openDialog({ type: "SetGain", props: { eventId } }));
    handleClose();
  };

  if (
    perms.AV_STREAMS_UPDATE &&
    !perms.AV_STREAMS_STOP &&
    avPublisher?.status === AvStreamStatus.Stopped
  ) {
    return (
      <Button
        variant="contained"
        onClick={() => handleAction(StreamActionEnum.Start)}
        className={classes.startStreamBtn}
      >
        Start Stream
      </Button>
    );
  }

  if (perms.AV_STREAMS_UPDATE || perms.AV_STREAMS_STOP) {
    return (
      <>
        <Button
          variant="contained"
          onClick={handleMenu}
          disabled={!avPublisher}
          data-testid={`streamStatusUpdateButton${eventId}`}
          endIcon={<Icon icon="arrow_drop_down" />}
          disableElevation
        >
          Update Status
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <RequiresPermissions permissions={Permission.AvStreamsUpdate}>
            <MenuItem
              onClick={() => handleAction(StreamActionEnum.Start)}
              disabled={avPublisher?.status === AvStreamStatus.Streaming}
              data-testid={`streamStatusUpdateButtonMenuStart${eventId}`}
            >
              Start
            </MenuItem>
          </RequiresPermissions>

          <RequiresPermissions permissions={Permission.AvStreamsStop}>
            <>
              <MenuItem
                onClick={() => handleAction(StreamActionEnum.Stop)}
                disabled={avPublisher?.status === AvStreamStatus.Stopped}
                data-testid={`streamStatusUpdateButtonMenuStop${eventId}`}
              >
                Stop
              </MenuItem>
              <MenuItem
                onClick={() => handleAction(StreamActionEnum.Reload)}
                value={StreamActionEnum.Reload}
                data-testid={`streamStatusUpdateButtonMenuReload${eventId}`}
              >
                Reload
              </MenuItem>
            </>
          </RequiresPermissions>

          <MenuItem
            onClick={handleDialogOpen}
            data-testid={`streamStatusUpdateButtonMenuSetGain${eventId}`}
          >
            Set Gain
          </MenuItem>
        </Menu>
      </>
    );
  }

  return null;
};

export { StreamStatusUpdateButton };
