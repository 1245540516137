import { memo, FC } from "react";
import { useRequiresPermissions } from "hooks";
import type { Permission } from "graphql/generated";

type RequiresPermissionsProps = {
  permissions: Permission | Permission[];
  children: React.ReactElement;
};

const RequiresPermissions: FC<RequiresPermissionsProps> = memo(
  ({ permissions, children }: RequiresPermissionsProps) => {
    const [hasPermissions] = useRequiresPermissions(permissions);

    if (hasPermissions) {
      return children;
    }

    return null;
  }
);

export { RequiresPermissions };
