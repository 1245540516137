import { GetAppInfoQuery } from "graphql/generated";
import { cloudServicesEndpoints } from "services";

type AppInfo = GetAppInfoQuery["appInfo"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: AppInfo) => any;

const appInfo: AppInfo = {
  envName: "",
};

function useAppInfoSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetAppInfo.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.appInfo ?? appInfo),
  });
}

export { useAppInfoSelector };
