import { FC, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Icon,
  DialogContent,
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@xcira/components";
import { ExtractDialogProps, closeDialog } from "slices";
import { useAppDispatch, useMultiAttend } from "hooks";
import { AttendeeTypeEnum } from "graphql/generated";
import { MultiAttendCompanySelect } from "./MultiAttendCompanySelect";
import { FlavorSelect } from "components/Flavors";
import { MultiAttendEventList } from "./MultiAttendEventList";
import { useSelectEventsByIds } from "hooks/useEvents/useSelectEventsByIds";

const useStyles = makeStyles()((theme) => ({
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(3),
    flexShrink: 0,
  },
}));

export type MultiAttendDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"MultiAttend">;

const MultiAttendDialog: FC<MultiAttendDialogProps> = ({ isOpen }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [ahco, setAhco] = useState("");
  const [type, setType] = useState(AttendeeTypeEnum.Bidder);
  const [flavorOverride, setFlavorOverride] = useState("");
  const [devOverride] = useState("");
  const [selectedEventIds, setSelectedEventIds] = useState([0]);
  const clientEventId = useSelectEventsByIds(selectedEventIds, (events) =>
    events?.map((event) => event.clientEventId)
  );
  const clientAuctionIds = [
    ...new Set(
      useSelectEventsByIds(selectedEventIds, (events) =>
        events?.map((event) => event?.clientAuctionId ?? "")
      )
    ),
  ];

  const isAttendDisabled =
    !ahco ||
    !type ||
    !clientAuctionIds ||
    clientAuctionIds?.length <= 0 ||
    !clientEventId ||
    clientEventId.length <= 0;

  const multiAttend = useMultiAttend(
    ahco,
    type,
    clientEventId ?? [],
    clientAuctionIds ?? [],
    flavorOverride,
    devOverride
  );

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    setType(event.target.value as AttendeeTypeEnum);
  };

  const handleAttend = () => {
    multiAttend();
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} data-testid="multiAttendDialog">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="multiAttendDialogTitle">
          Multi-Lane Launch Selection
        </Typography>
        <IconButton aria-label="close" onClick={handleClose} data-testid="multiAttendDialogClose">
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl variant="standard" className={classes.formControl}>
          <MultiAttendCompanySelect setAuctionHouseFilter={setAhco} ahco={ahco} />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="multi-attend-type-select" data-testid="typeLabel">
            Launch Type
          </InputLabel>
          <Select
            id="multi-attend-type-select"
            value={type}
            onChange={handleTypeChange}
            label="Launch Type"
          >
            <MenuItem value={AttendeeTypeEnum.Bidder}>
              <Typography variant="body2">Bidder</Typography>
            </MenuItem>
            <MenuItem value={AttendeeTypeEnum.Viewer}>
              <Typography variant="body2">Viewer</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <FlavorSelect ahco={ahco} setFlavor={setFlavorOverride} flavor={flavorOverride} />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <MultiAttendEventList
            ahco={ahco}
            selectedEventIds={selectedEventIds}
            setSelectedEventIds={setSelectedEventIds}
          />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <Button variant="outlined" onClick={handleAttend} disabled={isAttendDisabled}>
            Launch
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export { MultiAttendDialog };
