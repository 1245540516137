import { FC, MouseEvent, memo, useState } from "react";
import {
  Icon,
  IconButton,
  Paper,
  Popover,
  TableCell,
  TableRow,
  makeStyles,
} from "@xcira/components";
import { useSelectFacilityByName } from "hooks";
import { EventStatus, Permission } from "graphql/generated";
import { PortsControl, RequiresPermissions, StreamIconButton } from "components";

const useStyles = makeStyles()((theme) => ({
  row: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  titleCase: {
    textTransform: "capitalize",
  },
  launch: {
    width: 40,
  },
  description: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  popOver: {
    padding: theme.spacing(2),
  },
  ports: {
    minWidth: 60,
  },
  av: { minWidth: 80 },
}));

interface FacilitiesRowProps {
  facilityName: string;
}

export const FacilitiesRow: FC<FacilitiesRowProps> = memo(({ facilityName }) => {
  const { classes } = useStyles();
  const facility = useSelectFacilityByName(facilityName);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const eventId = facility?.auctionEvent?.id;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `popover${facilityName}` : undefined;

  return (
    <TableRow className={classes.row} data-testid={`facilityRow${facilityName}`}>
      <TableCell data-testid={`facilityCellAuctionCompany${facilityName}`}>
        {facility?.auctionCompany}
      </TableCell>
      <TableCell data-testid={`facilityCellFacilityId${facilityName}`}>
        {facility?.facilityId}
      </TableCell>
      <TableCell data-testid={`facilityCellFacilityName${facilityName}`}>
        {facility?.facilityName}
      </TableCell>
      <TableCell
        data-testid={`facilityCellMarketplace${facilityName}`}
        className={classes.titleCase}
      >
        {facility?.marketplace}
      </TableCell>
      <TableCell data-testid={`facilityCellFlavorGroup${facilityName}`}>
        {facility?.flavorGroup}
      </TableCell>
      <TableCell data-testid={`facilityCellFeatureCollectionOverride${facilityName}`}>
        <IconButton onClick={handleClick} size="large">
          <Icon icon="speaker_notes" fontSize="large" />
        </IconButton>
        <Popover
          id={id}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper className={classes.popOver} elevation={1}>
            {facility?.featureCollectionOverride}
          </Paper>
        </Popover>
      </TableCell>
      <TableCell
        data-testid={`facilityCellAuctionEventStatus${facilityName}`}
        className={classes.titleCase}
      >
        {facility?.auctionEvent?.status ?? EventStatus.NotPresent}
      </TableCell>

      <RequiresPermissions permissions={Permission.PortsRead}>
        <TableCell data-testid={`facilityCellPortStatus${facilityName}`} className={classes.ports}>
          <PortsControl
            eventId={eventId}
            updatePortNumber={facility?.updatePort}
            bidPortNumber={facility?.bidPort}
            masterPortNumber={facility?.masterPort}
          />
        </TableCell>
      </RequiresPermissions>
      <RequiresPermissions permissions={Permission.PortsRead}>
        <TableCell align="center" className={classes.av}>
          <StreamIconButton
            avPort={facility?.avPort}
            facilityName={facilityName}
            auctionCompany={facility?.auctionCompany}
            clientEventId={facility?.auctionEvent?.clientEventId}
          />
        </TableCell>
      </RequiresPermissions>
    </TableRow>
  );
});
