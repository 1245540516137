import { serversSelector, Resources } from "@xcira/commons";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { BaseQueryApi, BaseQueryEnhancer } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { RootState } from "app/store";
import { WebsocketClient } from "./WebsocketClient";

export const client = new GraphQLClient("/graphql");
export const websocketClient = new WebsocketClient();

export interface ServiceProviderOptions {
  serviceProvider: "entityManager" | "services" | "terminology" | "cloudServices";
}

export const gqlQueryWithServiceDiscovery: BaseQueryEnhancer<
  unknown,
  ServiceProviderOptions | object,
  ServiceProviderOptions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = (baseQuery) => async (args: any, baseQueryApi: BaseQueryApi, extraOptions: any) => {
  const services: Resources = serversSelector(baseQueryApi.getState() as RootState);

  const serviceProviderUrl: string = services.cloudServices;

  const url = `${serviceProviderUrl}/graphql`;

  client.setEndpoint(url);
  websocketClient.setUrl(url);

  const authToken = (baseQueryApi.getState() as RootState).auth.authToken;

  client.setHeader("Authorization", `Bearer ${authToken}`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return baseQuery(args, baseQueryApi, extraOptions) as any;
};

export const baseQuery = gqlQueryWithServiceDiscovery(
  graphqlRequestBaseQuery({
    // TODO: improve this typing?
    client,
    customErrors: ({ message, response, name, stack = "" }) => ({
      name,
      message,
      stack,
      error: response?.errors?.[0].message ?? message,
    }),
  }),
  {
    serviceProvider: "cloudServices",
  }
);
