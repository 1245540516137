import { FC, PropsWithChildren } from "react";
import { makeStyles, Grid } from "@xcira/components";
import { CompanyFilter } from "./CompanyFilter";
import { DateFilter } from "./DateFilter";
import { EventStatusFilter } from "./EventStatusFilter";
import { OrderBy } from "./OrderBy";

interface FilterProps {}

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
  },
  filter: {
    width: "100%",
    minWidth: 160,
    marginRight: theme.spacing(2),
  },
}));

export const Filters: FC<PropsWithChildren<FilterProps>> = () => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2} className={classes.root} data-testid="filters">
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <CompanyFilter className={classes.filter} />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <DateFilter className={classes.filter} />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <EventStatusFilter className={classes.filter} />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <OrderBy className={classes.filter} />
      </Grid>
    </Grid>
  );
};
