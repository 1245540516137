import { FC } from "react";
import type { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { default as MuiButton } from "@mui/material/Button";

type ButtonProps = MuiButtonProps & {
  target?: "_blank" | "_self" | "_parent" | "_top";
};

export const Button: FC<ButtonProps> = ({ children, ...props }) => {
  return <MuiButton {...props}>{children}</MuiButton>;
};

export type { ButtonProps };
