import { useUpdatePortStatusByPortNumberMutation } from "services";
import { PortActionEnum, PortTypeEnum } from "../../graphql/generated";
import { useCallback } from "react";

function useUpdatePortStatusByPortNumber(
  portNumber: number,
  status: PortActionEnum,
  type?: PortTypeEnum
) {
  const [updatePortStatusByPortNumber, result] = useUpdatePortStatusByPortNumberMutation();

  const callback = useCallback(async () => {
    const result = await updatePortStatusByPortNumber({ portNumber, status, type }).unwrap();

    return result;
  }, [updatePortStatusByPortNumber, portNumber, status, type]);

  return [callback, result] as const;
}

export { useUpdatePortStatusByPortNumber };
