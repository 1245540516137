import { FC } from "react";
import { Table, TableBody, TableContainer, makeStyles } from "@xcira/components";
import { useSelectCurrentFacilityNames } from "hooks";
import { FacilitiesRow } from "./FacilitiesRow";
import { FacilitiesHeader } from "./FacilitiesHeader";

const useStyles = makeStyles()((theme) => ({
  head: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableContainer: {
    height: "70vh",
  },
}));

const FacilitiesTable: FC = () => {
  const { classes } = useStyles();
  const facilityNames = useSelectCurrentFacilityNames();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table size="small" stickyHeader data-testid="facilitiesTable">
        <FacilitiesHeader className={classes.head} />
        <TableBody>
          {facilityNames.map((facilityName) => {
            return <FacilitiesRow key={facilityName} facilityName={facilityName} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { FacilitiesTable };
