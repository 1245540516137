export class FileUtil {
  /**
   * Creates a Blob object and downloads it by simulating a download link.
   *
   * @param content Contents of the file
   * @param filename What to name the file that will be downloaded
   */
  static createAndDownloadFile(content: string, filename: string): void {
    // Create a plain text file with the specified filename and content
    const blob = new Blob([content], { type: "text/plain" });

    // Create a link element to simulate a download
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.style.display = "none";

    // Append the link to the document body
    document.body.appendChild(downloadLink);

    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Remove the link from the document body
    document.body.removeChild(downloadLink);
  }
}
