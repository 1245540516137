import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "services";
import { setAuthToken } from "slices";

function useLogin(username: string, password: string) {
  const dispatch = useDispatch();
  const [login] = useLoginMutation();

  return useCallback(async () => {
    const result = await login({ userInput: { username, password } }).unwrap();

    dispatch(setAuthToken(result.getSessionToken.token));
  }, [login, dispatch, username, password]);
}

export { useLogin };
