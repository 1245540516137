import { ChangeEvent, FC, PropsWithChildren, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  IconButton,
} from "@xcira/components";
import { useLogin } from "hooks";

interface LoginProps {}

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  card: {
    minWidth: 275,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
  button: {
    width: "100%",
    textTransform: "capitalize",
  },
  control: {
    padding: theme.spacing(2),
  },
  inputIcon: {
    color: theme.palette.grey[500],
  },
}));

export const Login: FC<PropsWithChildren<LoginProps>> = () => {
  const { classes } = useStyles();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const login = useLogin(user, password);

  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUser(event.currentTarget.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const isDisabled = () => !(user?.length && password?.length);

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter" && !isDisabled()) {
      login();
    }
  };

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      data-testid="login"
    >
      <Grid item xs={12}>
        <Card className={classes.card} variant="outlined">
          <CardHeader
            avatar={
              <Avatar>
                <Icon icon="account_circle" />
              </Avatar>
            }
            title="Login"
            titleTypographyProps={{ variant: "h5", color: "textPrimary" }}
            className={classes.cardHeader}
          />
          <Divider />
          <CardContent className={classes.content}>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="user">User</InputLabel>
              <Input
                id="user"
                value={user}
                onChange={handleUserChange}
                onKeyPress={handleKeyPress}
              />
            </FormControl>
            <FormControl variant="standard" className={classes.formControl}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className={classes.inputIcon}
                    >
                      <Icon icon={showPassword ? "visibility" : "visibility_off"}></Icon>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </CardContent>

          <CardActions>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={login}
              disabled={isDisabled()}
            >
              {/* status === "authenticating" ? status : "Login" */}
              Login
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
