import { FC } from "react";
import { Card, CardContent, Grid, Typography } from "@xcira/components";

interface NoEventsFoundProps {
  className: string;
}

const NoEventsFound: FC<NoEventsFoundProps> = ({ className }) => (
  <Grid container data-testid="noEvents">
    <Grid item xs={12}>
      <Card elevation={0} className={className}>
        <CardContent>
          <Typography variant="h5">No Events Found</Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export { NoEventsFound };
