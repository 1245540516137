import { FC } from "react";
import { default as MuiList, ListProps } from "@mui/material/List";
import { default as MuiListSubheader, ListSubheaderProps } from "@mui/material/ListSubheader";

const List: FC<ListProps> = ({ children, ...props }) => {
  return <MuiList {...props}>{children}</MuiList>;
};

const ListSubheader: FC<ListSubheaderProps> = ({ children, ...props }) => {
  return <MuiListSubheader {...props}>{children}</MuiListSubheader>;
};

export { List, ListSubheader };
export type { ListProps, ListSubheaderProps };
