import { FC } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { AutoComplete, TextField } from "@xcira/components";
import { useCompaniesSelector } from "hooks";
import {
  selectAuctionHouseFilter as defaultSelectAuctionHouseFilter,
  setAuctionHouseFilter as defaultSetAuctionHouseFilter,
} from "slices";
import type { AuctionCompany } from "slices";
import omit from "lodash/omit";
import { RootState } from "app/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface CompanyFilterProps {
  className?: string;
  selectAuctionHouseFilter?: (state: RootState) => Omit<AuctionCompany, "isActive">;
  setAuctionHouseFilter?: ActionCreatorWithPayload<Omit<AuctionCompany, "isActive">, string>;
}

const isAuctionCompany = (data: unknown): data is AuctionCompany =>
  (data?.hasOwnProperty?.("auctionCompany") && data?.hasOwnProperty?.("fullName")) ?? false;

export const CompanyFilter: FC<CompanyFilterProps> = ({
  className,
  selectAuctionHouseFilter = defaultSelectAuctionHouseFilter,
  setAuctionHouseFilter = defaultSetAuctionHouseFilter,
}) => {
  const dispatch = useAppDispatch();
  const selectedCompanyFilter = useAppSelector(selectAuctionHouseFilter);

  const companies = useCompaniesSelector((companies) => {
    const companiesArr = companies
      ? companies.map((company) => omit(company, ["flavorGroup", "flavorGroupName"]))
      : [];

    return [{ fullName: "All", auctionCompany: "All", id: 0 }, ...companiesArr];
  });

  return (
    <AutoComplete
      data-testid="companiesFilter"
      disablePortal
      className={className}
      options={companies}
      getOptionLabel={(option) => (option as AuctionCompany).fullName}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="companiesFilterInput"
          label="Auction House"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <li {...props} data-testid={`companyOption${option.auctionCompany}`}>
          {option.fullName}
        </li>
      )}
      onChange={(event, value) => {
        if (isAuctionCompany(value)) {
          dispatch(setAuctionHouseFilter(value));
        }
      }}
      value={selectedCompanyFilter}
      isOptionEqualToValue={(option, value) => option.auctionCompany === value.auctionCompany}
      openOnFocus
    />
  );
};
