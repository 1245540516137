import { Port } from "graphql/generated";
import { usePortStatusSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: Port) => any;

const defaultSelector = (port?: Port) => port;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? Port | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectPortByNumber = <T extends SelectFromResult>(
  portNumber: number,
  selector: T = defaultSelector as T
): Result<T> => {
  const port = usePortStatusSelector((portStatus) =>
    portStatus.ports?.find((port) => port.portNumber === portNumber)
  );

  return useMemo(() => selector(port), [selector, port]);
};

export { useSelectPortByNumber };
