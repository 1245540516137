import { AuctionEvent } from "graphql/generated";
import { useEventsSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: AuctionEvent) => any;

const defaultSelector = (event?: AuctionEvent) => event;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? AuctionEvent | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectEventById = <T extends SelectFromResult>(
  eventId: number,
  selector: T = defaultSelector as T
): Result<T> => {
  const event = useEventsSelector((events) => events.find((event) => event.id === eventId));
  return useMemo(() => selector(event), [selector, event]);
};

export { useSelectEventById };
