import { cloudServicesEndpoints } from "services";
import { GetCustomerSalesLinksByAhcoQuery } from "graphql/generated";

type CustomerSalesLinks = GetCustomerSalesLinksByAhcoQuery["customerSalesLinksByAhco"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: CustomerSalesLinks) => any;

const defaultCusomerSalesLinks: CustomerSalesLinks = {};

function useCustomerSalesLinksSelector<T extends SelectFromResult>(
  ahco: string,
  selector: T
): ReturnType<T> {
  return cloudServicesEndpoints.GetCustomerSalesLinksByAhco.useQueryState(
    { ahco },
    {
      selectFromResult: (result) =>
        selector(result.data?.customerSalesLinksByAhco ?? defaultCusomerSalesLinks),
    }
  );
}

export { useCustomerSalesLinksSelector };
