import { FC, PropsWithChildren } from "react";
import { Link, Tabs, Tab, makeStyles } from "@xcira/components";
import { useLocation, matchPath } from "react-router-dom";

interface NavLinksProps {}

function getCurrentTab(path?: string) {
  if (!path || path === "/") {
    return "/events";
  }

  return path;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "2%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
}));

const useRouteMatch = (patterns: string[]) => {
  const { pathname } = useLocation();

  return matchPath(pathname, patterns);
};

export const NavLinks: FC<PropsWithChildren<NavLinksProps>> = () => {
  const { classes } = useStyles();
  const routeMatch = useRouteMatch(["/configurations", "/events", "/reports", "/"]);
  const currentTab = getCurrentTab(routeMatch?.path);

  return (
    <div className={classes.root} data-testid="navLinks">
      <Tabs value={currentTab} indicatorColor="secondary">
        <Tab
          value="/events"
          label={
            <Link to="/" color="textPrimary" className={classes.link}>
              Events
            </Link>
          }
        />
        <Tab
          value="/configurations"
          label={
            <Link to="/configurations" color="textPrimary" className={classes.link}>
              Configurations
            </Link>
          }
        />
        <Tab
          value="/reports"
          label={
            <Link to="/reports" color="textPrimary" className={classes.link}>
              Reports
            </Link>
          }
        />
      </Tabs>
    </div>
  );
};
