import { AvPublisher, Maybe } from "graphql/generated";
import { useAvPublishersSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: AvPublisher) => any;

const defaultSelector = (avPublisher?: AvPublisher) => avPublisher;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? AvPublisher | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectAvPublisherByPort = <T extends SelectFromResult>(
  port: Maybe<number> | undefined,
  selector: T = defaultSelector as T
): Result<T> => {
  const avPublisher = useAvPublishersSelector((avPublishers) =>
    avPublishers.find((avPublisher) => avPublisher.avStream.audioPort === port)
  );
  return useMemo(() => selector(avPublisher), [selector, avPublisher]);
};

export { useSelectAvPublisherByPort };
