import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AuctionCompany, Facility } from "graphql/generated";
import { selectFacilities } from "services";

interface FacilityState {
  auctionHouseFilter: Omit<AuctionCompany, "isActive">;
}
const init = (): FacilityState => ({
  auctionHouseFilter: {
    __typename: "AuctionCompany",
    auctionCompany: "All",
    fullName: "All",
    id: 0,
  },
});

const facilities = createSlice({
  name: "facilities",
  initialState: init,
  reducers: {
    setFacilitiesAuctionHouseFilter: (
      state,
      action: PayloadAction<Omit<AuctionCompany, "isActive">>
    ) => {
      state.auctionHouseFilter = action.payload;
    },
  },
});

export const { name: facilitiesReducerPath, reducer: facilitiesReducer } = facilities;
export const { setFacilitiesAuctionHouseFilter } = facilities.actions;
export const selectFacilitiesAuctionHouseFilter = (state: RootState) =>
  state.facilities.auctionHouseFilter;

export const selectFilteredFacilities = createSelector(
  selectFacilities,
  selectFacilitiesAuctionHouseFilter,
  (facilitiesServiceState, auctionHouseFilter) => {
    const facilities = facilitiesServiceState.data?.facilities;

    const result = (facilities?.filter((facility) => {
      const ahcoFilter =
        auctionHouseFilter.auctionCompany === "All"
          ? true
          : facility?.auctionCompany === auctionHouseFilter?.auctionCompany;

      return ahcoFilter;
    }) ?? []) as Facility[];

    return result;
  }
);
