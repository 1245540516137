import { AvPublisher } from "graphql/generated";
import { cloudServices } from "services";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: AvPublisher[]) => any;

const defaultAvPublishers: AvPublisher[] = [];

function useAvPublishersSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServices.endpoints.GetAvPublishers.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.avPublishers ?? defaultAvPublishers),
  });
}

export { useAvPublishersSelector };
