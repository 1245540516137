import { FC } from "react";
import { useAppDispatch } from "hooks";
import { AutoComplete, Chip, TextField, Typography, makeStyles } from "@xcira/components";
import { EventStatusLabel, eventStatusLabels, setEventStatusFilter } from "slices";
import { useSelectEventStatusFilterLabels } from "hooks/useSelectEventStatusFilter/useSelectEventStatusFilterLabels";

interface EventStatusFilterProps {
  className: string;
}

const useStyles = makeStyles()((theme) => ({
  allText: {
    paddingLeft: theme.spacing(1),
  },
}));

export const EventStatusFilter: FC<EventStatusFilterProps> = ({ className }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const selectedEventStatusValues = useSelectEventStatusFilterLabels();

  const handleEventStatusFilter = (labels: EventStatusLabel[]) => {
    dispatch(setEventStatusFilter({ labels }));
  };

  return (
    <AutoComplete
      disablePortal
      data-testid="eventStatusFilter"
      multiple
      className={className}
      options={eventStatusLabels}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid="eventStatusFilterInput"
          label="Status"
          variant="outlined"
        />
      )}
      onChange={(event, value) => {
        if (value !== null) {
          handleEventStatusFilter(value as EventStatusLabel[]);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={option} data-testid={`statusOption${option.replaceAll(" ", "-")}`}>
          {option}
        </li>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          if (option === "All") {
            return (
              <Typography className={classes.allText} key={index}>
                {option}
              </Typography>
            );
          }
          // eslint-disable-next-line react/jsx-key -- disabled because getTagProps provides key
          return <Chip label={option} {...getTagProps({ index })} />;
        })
      }
      value={selectedEventStatusValues}
      openOnFocus
    />
  );
};
