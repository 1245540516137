import { GetPortStatusQuery } from "graphql/generated";
import { cloudServicesEndpoints } from "services";

type PortStatus = GetPortStatusQuery["getPortStatus"];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: PortStatus) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultResult: PortStatus = {} as any;

function usePortStatusSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetPortStatus.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.getPortStatus ?? defaultResult),
  });
}

export { usePortStatusSelector };
