import { AttendeeTypeEnum } from "graphql/generated";
import { useCallback } from "react";
import { useAttendMutation } from "services";

function useAttend(
  ahco: string | undefined,
  eventId: number,
  type: AttendeeTypeEnum,
  flavorOverride?: string,
  username?: string,
  devOverride?: string
) {
  const [attend] = useAttendMutation();

  return useCallback(async () => {
    if (ahco) {
      const link = await attend({
        ahco,
        eventId,
        type,
        flavorOverride,
        username,
        devOverride,
      }).unwrap();
      const popupArgs = [
        link.attend,
        "_blank",
        "location=no, scrollbars=yes, resizable=yes, width=1250, height=950",
      ];
      const { availHeight, availWidth } = window.screen;
      const popup = window.open(...popupArgs);

      popup?.moveTo(0, 0);
      popup?.resizeTo(availWidth, availHeight);
    }
  }, [attend, ahco, eventId, flavorOverride, type, username, devOverride]);
}

export { useAttend };
