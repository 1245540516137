import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Typography,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
} from "@xcira/components";
import { useAppDispatch, useEvents, useEventsSelector, useSelectCurrentEventIds } from "hooks";
import { EventsTable } from "./EventsTable";
import { useUpdateEventStatus } from "hooks";
import { EventStatusActions, UpdateEventStatusResponse } from "graphql/generated";
import { notify } from "@xcira/commons";
import { ExtractDialogProps, closeDialog } from "slices/dialogs";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  dialogContent: {
    overflow: "hidden",
    width: "90vw",
    maxWidth: 720,
  },
  formControl: {
    minWidth: 240,
    marginBottom: theme.spacing(1),
    overflow: "hidden",
  },
  submit: { width: 140, height: 53 },
  resetSale: { fontWeight: 600 },
  divider: { marginRight: theme.spacing(2) },
  status: { minWidth: 80 },
}));

type AvailableActions =
  | EventStatusActions.PresaleChecklistComplete
  | EventStatusActions.StartupChecklistComplete
  | EventStatusActions.RunStatsComplete
  | EventStatusActions.SaleCloseout
  | EventStatusActions.RunStatsReset;

const actionMap = {
  [EventStatusActions.PresaleChecklistComplete]: {
    status: "SCHEDULED",
    message:
      "By submitting, you confirm that you have cleared logs, ports are up, and you can log into the sale.",
  },
  [EventStatusActions.StartupChecklistComplete]: {
    status: "PRESALE",
    message:
      "By submitting, you confirm that a clerk has logged in and you have Audio &/or Video for the sale.",
  },
  [EventStatusActions.RunStatsComplete]: {
    status: "ACTIVE",
    message: "By submitting, you confirm that the sale has completed.",
  },
  [EventStatusActions.SaleCloseout]: {
    status: "COMPLETE",
    message: "By submitting, you confirm that the sale is no longer needed for access.",
  },
  [EventStatusActions.RunStatsReset]: {
    status: "COMPLETE",
    message: `By submitting, you acknowledge the sale will go back to an "Active" status.`,
  },
};

export type EventStatusDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"EventStatus">;

const EventStatusDialog: FC<EventStatusDialogProps> = ({ isOpen }) => {
  const [action, setAction] = useState<AvailableActions>(
    EventStatusActions.PresaleChecklistComplete
  );
  const [eventIds, setEventIds] = useState<Set<number>>(new Set());
  const [failedUpdateResponses, setFailedUpdateResponses] = useState<UpdateEventStatusResponse[]>(
    []
  );

  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const { refetch } = useEvents();

  const { status, message } = actionMap[action];

  const currentEventIds = useSelectCurrentEventIds();
  const events = useEventsSelector((events) =>
    events.filter((event) => currentEventIds.includes(event.id) && event.status === status)
  );

  const handleActionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as AvailableActions);
    setEventIds(new Set());
    setFailedUpdateResponses([]);
  };

  const handleRowSelectChange = useCallback(
    (eventId: number) => {
      const updatedEventIds = new Set(eventIds);

      if (eventIds.has(eventId)) {
        updatedEventIds.delete(eventId);
      } else {
        updatedEventIds.add(eventId);
      }

      setEventIds(updatedEventIds);
    },
    [eventIds]
  );

  const [updateEventStatus, result] = useUpdateEventStatus(action, Array.from(eventIds));

  const handleSubmit = async () => {
    const {
      updateEventStatus: { responses },
    } = await updateEventStatus();
    const successes = responses
      .filter(({ response: { status } }) => status === "SUCCESS")
      .map(({ response }) => response.id);
    const fails = responses
      .filter(({ response }) => response.status === "FAIL")
      .map(({ response }) => response);

    setFailedUpdateResponses(fails);

    if (successes.length) {
      dispatch(
        notify({
          message: `Status update was successful for events ${successes.join(", ")}.`,
          severity: "success",
        })
      );
      setEventIds(new Set());
      refetch();
    }
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.dialog}
      data-testid="eventStatusDialog"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="eventStatusDialogTitle">
          Event Status Manager
        </Typography>
        <IconButton onClick={handleClose} data-testid="eventStatusDialogClose">
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container>
          <Grid item>
            <FormControl variant="standard" className={classes.formControl}>
              <FormLabel>Actions</FormLabel>
              <RadioGroup row name="actions" value={action} onChange={handleActionChange}>
                <FormControlLabel
                  value={EventStatusActions.PresaleChecklistComplete}
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Pre Checks</Typography>}
                  data-testid="prechecksLabel"
                />
                <FormControlLabel
                  value={EventStatusActions.StartupChecklistComplete}
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Sale Ready</Typography>}
                  data-testid="saleReadyLabel"
                />
                <FormControlLabel
                  value={EventStatusActions.RunStatsComplete}
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Complete</Typography>}
                  data-testid="completeLabel"
                />
                <FormControlLabel
                  value={EventStatusActions.SaleCloseout}
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Close Out</Typography>}
                  data-testid="closeOutLabel"
                />

                <Divider orientation="vertical" flexItem className={classes.divider} />

                <FormControlLabel
                  value={EventStatusActions.RunStatsReset}
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2" color="error" className={classes.resetSale}>
                      Reset Sale
                    </Typography>
                  }
                  data-testid="resetSaleLabel"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container flexDirection="column" pt={1} pb={1} pr={1} spacing={1}>
          <Grid item xs>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!eventIds.size || result.isLoading}
                  onClick={handleSubmit}
                  className={classes.submit}
                  data-testid="eventStatusDialogSubmit"
                >
                  {result.isLoading ? (
                    <CircularProgress data-testid="submitLoader" size={30} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="subtitle2">Event Status: </Typography>
                  </Grid>
                  <Grid item className={classes.status}>
                    <Typography variant="subtitle2" data-testid="eventStatusLabel">
                      {status}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={500}>
              {message}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <EventsTable
          events={events}
          eventIds={eventIds}
          status={status}
          failedUpdateResponses={failedUpdateResponses}
          handleRowSelectChange={handleRowSelectChange}
        />
      </DialogContent>
    </Dialog>
  );
};

export { EventStatusDialog };
