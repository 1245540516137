import { FC } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Icon,
  IconButton,
  Divider,
  Button,
} from "@xcira/components";
import {
  useEvents,
  useCompanies,
  useAppSelector,
  useLoggedInAttendeeCounts,
  useSelectCurrentEventIds,
  useAvPublishers,
  useAvStreams,
  useRequiresPermissions,
  useAppDispatch,
  useLoggedInAttendeeCountsTotal,
} from "hooks";
import { openDialog, selectDateFilter } from "slices";
import { Filters, RequiresPermissions } from "components";
import { NoEventsFound } from "./NoEventsFound";
import { EventsTable } from "./EventsTable";
import { DateUtil } from "@xcira/commons";
import { POLLING_INTERVAL } from "constants/constants";
import { usePortStatus, usePortStatusSelector } from "hooks/usePortStatus";
import { Permission } from "graphql/generated";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  links: {
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    display: "flex",
    marginRight: theme.spacing(1),
  },
  eventStatusButton: {
    paddingLeft: 0,
  },
  noEvents: {
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    justifyContent: "center",
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  applicationStatus: {
    display: "flex",
    alignItems: "center",
  },
  applicationStatusIcon: {
    color: theme.palette.success.main,
  },
  refetch: {
    textAlign: "right",
  },
  underline: { textDecoration: "underline" },
  noPermission: { padding: theme.spacing(2) },
}));

const requiredPermissions = [Permission.EventsRead, Permission.CompaniesRead];

export const Events: FC = () => {
  const { classes } = useStyles();

  const [hasPerms] = useRequiresPermissions(requiredPermissions);

  const dispatch = useAppDispatch();

  const { isLoading: eventsLoading, fulfilledTimeStamp, refetch: refetchEvents } = useEvents(
    POLLING_INTERVAL
  );
  const { isLoading: companiesLoading } = useCompanies();

  const selectedDateFilter = useAppSelector(selectDateFilter);

  const isLoading = eventsLoading || companiesLoading;
  const timestamp = fulfilledTimeStamp ?? Date.now();

  const handleDialogOpen = () => {
    dispatch(openDialog({ type: "EventStatus", props: {} }));
  };

  const eventIds = useSelectCurrentEventIds();

  const { refetch: refetchAttendeeCounts } = useLoggedInAttendeeCounts(
    { eventIds },
    POLLING_INTERVAL
  );

  const attendeeTotal = useLoggedInAttendeeCountsTotal();

  const { refetch: refetchAvPublishers } = useAvPublishers(POLLING_INTERVAL);

  useAvStreams();

  usePortStatus();

  const portStatusUpdatedOn = usePortStatusSelector((portStatus) => portStatus.updatedOn);

  const refetch = () => {
    refetchEvents();
    refetchAttendeeCounts();
    refetchAvPublishers();
  };

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="eventsLoader"
        className={classes.circularProgress}
        size={100}
      />
    );
  }

  if (!hasPerms) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.noPermission}
        data-testid="eventsNoPermission"
      >
        <Grid item xs>
          <Typography variant="h6">
            Your credentials don&apos;t have permissions to access OLR tools.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RequiresPermissions permissions={requiredPermissions}>
      <div className={classes.root} data-testid="events">
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Typography variant="caption">
              Last Updated: {DateUtil.format(timestamp, "MM/D/YYYY HH:mm:ss")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              Ports Last Updated On:{" "}
              {portStatusUpdatedOn
                ? DateUtil.format(portStatusUpdatedOn, "MM/D/YYYY HH:mm:ss")
                : ""}
            </Typography>
          </Grid>
        </Grid>

        <Filters />

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">
                  Auction Count for {selectedDateFilter?.label}: {eventIds?.length}
                </Typography>
              </Grid>
              <Grid item className={classes.divider}>
                <Divider orientation="vertical" flexItem />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Total Logged-in Users: {attendeeTotal}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} md={5} lg={4}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container flexWrap="nowrap">
                  <RequiresPermissions permissions={Permission.EventsUpdate}>
                    <>
                      <Grid item>
                        <Button
                          onClick={handleDialogOpen}
                          disableRipple
                          className={classes.eventStatusButton}
                          disabled={!eventIds?.length}
                          data-testid="eventStatusManagerButton"
                        >
                          Event Status Manager
                        </Button>
                      </Grid>
                      <Grid item className={classes.divider}>
                        <Divider orientation="vertical" flexItem />
                      </Grid>
                    </>
                  </RequiresPermissions>

                  <Grid item>
                    <div className={classes.applicationStatus}>
                      <Icon
                        icon="brightness_1"
                        fontSize="medium"
                        className={classes.applicationStatusIcon}
                        data-testid="applicationStatusIcon"
                      />
                      <Button
                        href="/applicationstatus"
                        disableRipple
                        className={classes.underline}
                        data-testid="applicationStatusLink"
                      >
                        Application Status
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item flex={1} className={classes.refetch}>
                <IconButton onClick={refetch}>
                  <Icon icon="replay" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {eventIds?.length ? (
          <Paper>
            <EventsTable />
          </Paper>
        ) : (
          <NoEventsFound className={classes.noEvents} />
        )}
      </div>
    </RequiresPermissions>
  );
};
