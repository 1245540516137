import { useSelectInventoryCountsByEventId } from "./useSelectInventoryCountsByEventId";

export interface InventoryProgessCounts {
  totalLots: number;
  soldLotCount: number;
  ifSoldLotCount: number;
  noSaleLotCount: number;
  withdrawnLotCount: number;
  percentClerked: number;
}

const useSelectInventoryProgressCountsByEventId = (
  eventId: number,
  lotsClerked: number
): InventoryProgessCounts => {
  const inventoryCounts = useSelectInventoryCountsByEventId(eventId);
  const defaultInventoryProgressCounts: InventoryProgessCounts = {
    totalLots: 0,
    soldLotCount: 0,
    ifSoldLotCount: 0,
    noSaleLotCount: 0,
    withdrawnLotCount: 0,
    percentClerked: 0,
  };

  const inventoryProgressCounts =
    inventoryCounts?.reduce((acc, inventoryCount) => {
      const { count, status } = inventoryCount;
      const statusLower = status.toLowerCase();

      acc.totalLots += count;
      if (statusLower === "sold") {
        acc.soldLotCount += count;
      } else if (statusLower === "withdrawn" || statusLower === "out") {
        acc.withdrawnLotCount += count;
      } else if (statusLower === "ifsale" || statusLower === "call") {
        acc.ifSoldLotCount += count;
      } else if (statusLower === "pass" || statusLower === "nosale") {
        acc.noSaleLotCount += count;
      }

      return acc;
    }, defaultInventoryProgressCounts) ?? defaultInventoryProgressCounts;

  const count = inventoryProgressCounts?.totalLots
    ? Math.round((lotsClerked / inventoryProgressCounts.totalLots) * 100)
    : 0;

  inventoryProgressCounts.percentClerked = count > 100 ? 100 : count;

  return inventoryProgressCounts ?? defaultInventoryProgressCounts;
};

export { useSelectInventoryProgressCountsByEventId };
