import { FC, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { AutoComplete, TextField } from "@xcira/components";
import { DateLabel, selectDateFilter, setDateFilter, dateLabels } from "slices";

interface DateFilterProps {
  className: string;
}

export const DateFilter: FC<DateFilterProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const selectedDateFilter = useAppSelector(selectDateFilter);

  const handleDateFilter = (label: DateLabel) => {
    dispatch(setDateFilter({ label, now: Date.now() }));
  };

  const options = useMemo(() => [...dateLabels], []);

  return (
    <AutoComplete
      disablePortal
      data-testid="dateFilter"
      className={className}
      options={options}
      renderInput={(params) => (
        <TextField {...params} data-testid="dateFilterInput" label="Limit By" variant="outlined" />
      )}
      onChange={(event, value) => {
        if (value !== null) {
          handleDateFilter(value as DateLabel);
        }
      }}
      renderOption={(props, option) => (
        <li {...props} key={option} data-testid={`dateOption${option.replaceAll(" ", "-")}`}>
          {option}
        </li>
      )}
      value={selectedDateFilter?.label}
      openOnFocus
    />
  );
};
