import { useLazyGetCumulativeStatsQuery } from "graphql/generated";
import { useAppSelector } from "hooks";
import { useCallback } from "react";
import { selectAhco, selectEndDate, selectStartDate } from "slices";

export function useLazyCumulativeStats() {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const ahco = useAppSelector(selectAhco);

  const [trigger] = useLazyGetCumulativeStatsQuery();

  const triggerCumulativeStats = useCallback(
    (preferCacheValue = false) => {
      return trigger({ startDate, endDate, ahco }, preferCacheValue);
    },
    [trigger, startDate, endDate, ahco]
  );

  return triggerCumulativeStats;
}
