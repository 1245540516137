import { RootState } from "app/store";
import { api as generatedApi, PortStatus, OnPortStatusUpdateDocument } from "graphql/generated";
import { client, websocketClient } from "services/cloud/GqlQueryWithAuth";

export const api = generatedApi.enhanceEndpoints({
  endpoints: {
    Login: {
      async onQueryStarted(arg, { queryFulfilled }) {
        const { data } = await queryFulfilled;

        client.setHeader("Authorization", `Bearer ${data.getSessionToken.token}`);
        websocketClient.setAuthToken(data.getSessionToken.token);
      },
    },
    GetUser: {
      async onQueryStarted(arg, { queryFulfilled, getState }) {
        await queryFulfilled;

        if (websocketClient.isDisconnected()) {
          const authToken = (getState() as RootState).auth.authToken;

          websocketClient.setAuthToken(authToken);
          websocketClient.connect();
        }
      },
    },
    GetPortStatus: {
      async onCacheEntryAdded(arg, api) {
        await api.cacheDataLoaded;

        const unsubscribe = websocketClient.subscribe(OnPortStatusUpdateDocument, (result) => {
          if (result?.data) {
            api.updateCachedData((draft) => {
              draft.getPortStatus = result.data?.portStatusesUpdated as PortStatus; // TODO: improve this typing?
            });
          }
        });

        await api.cacheEntryRemoved;

        unsubscribe?.();
      },
    },
  },
});
