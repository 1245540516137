import { FC } from "react";
import {
  LocalizationProvider as MuiLocalizationProvider,
  LocalizationProviderProps,
} from "@mui/x-date-pickers";
import { Dayjs } from "@xcira/commons";

export const LocalizationProvider: FC<LocalizationProviderProps<Dayjs, string>> = ({
  children,
  ...props
}) => {
  return <MuiLocalizationProvider {...props}>{children}</MuiLocalizationProvider>;
};

export type { LocalizationProviderProps };
