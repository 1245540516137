import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@xcira/components";
import { AuctionEvent } from "graphql/generated";
import { useAppSelector } from "hooks";
import { FC } from "react";
import { selectFilteredEvents } from "slices";

interface MultiAttendEventListProps {
  selectedEventIds: number[];
  setSelectedEventIds: (eventIds: number[]) => void;
  ahco: string;
}

const MultiAttendEventList: FC<MultiAttendEventListProps> = ({
  selectedEventIds,
  setSelectedEventIds,
  ahco,
}) => {
  const filteredEvents = useAppSelector(selectFilteredEvents).filter(
    (event) => event.clientAuctionId && event.auctionCompany === ahco
  );
  const handleToggle = (event: AuctionEvent) => {
    const index = selectedEventIds.indexOf(event.id);
    const newArr = [...selectedEventIds];
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      newArr.push(event.id);
    }

    setSelectedEventIds(newArr);
  };

  return (
    <List sx={{ width: "100%", overflowY: "auto" }}>
      {filteredEvents.map((event) => (
        <ListItem key={event.id}>
          <ListItemButton role={undefined} onClick={() => handleToggle(event)} dense>
            <ListItemIcon>
              <Checkbox edge="start" checked={selectedEventIds.includes(event.id)} />
              <ListItemText
                id={`event-list-${event.id}`}
                primary={event.facilityName}
                secondary={event.description}
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export { MultiAttendEventList };
