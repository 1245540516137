import { FC, useState, MouseEvent } from "react";
import { Button, Icon, IconButton, makeStyles, Menu, MenuItem } from "@xcira/components";
import { Permission, PortActionEnum } from "graphql/generated";
import { RequiresPermissions } from "components/RequiresPermissions";
import { useAppDispatch, useRequiresPermissions } from "hooks";
import { EventPort, openDialog } from "slices/dialogs";
import { isPortUp } from "utils/PortUtil";

const useStyles = makeStyles()(() => ({
  startPortsBtn: {
    minWidth: 100,
  },
  menuButton: {
    flexGrow: 0,
    width: 50,
  },
}));

const requiredPermissions = [Permission.PortsUpdate, Permission.PortsStop];

interface PortStatusUpdateButtonProps {
  eventId?: number;
  port?: EventPort;
}

const PortStatusUpdateButton: FC<PortStatusUpdateButtonProps> = ({ eventId, port }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const key = `${port?.type}${port?.portNumber}`;

  const open = Boolean(anchorEl);

  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const [, perms] = useRequiresPermissions(requiredPermissions);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = (portAction: PortActionEnum) => {
    dispatch(openDialog({ type: "Port", props: { eventId, ports: [port], action: portAction } }));
    handleClose();
  };

  if (perms.PORTS_UPDATE && !perms.PORTS_STOP && !isPortUp(port)) {
    return (
      <IconButton
        size="large"
        color="success"
        onClick={() => handleDialogOpen(PortActionEnum.Start)}
        data-testid={`portsStatusUpdateStart${key}`}
      >
        <Icon fontSize="large" icon="play_arrow" />
      </IconButton>
    );
  }

  if (perms.PORTS_UPDATE || perms.PORTS_STOP) {
    return (
      <>
        <Button
          variant="contained"
          onClick={handleMenu}
          data-testid={`portStatusUpdateButton${key}`}
          endIcon={<Icon icon="arrow_drop_down" />}
          className={classes.menuButton}
          disableElevation
        >
          ...
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          data-testid={`portStatusUpdateButtonMenu${key}`}
        >
          <RequiresPermissions permissions={Permission.PortsUpdate}>
            <MenuItem
              onClick={() => handleDialogOpen(PortActionEnum.Start)}
              disabled={isPortUp(port)}
              data-testid={`portStatusUpdateButtonMenuStart${key}`}
            >
              Start
            </MenuItem>
          </RequiresPermissions>

          <RequiresPermissions permissions={Permission.PortsStop}>
            <>
              <MenuItem
                onClick={() => handleDialogOpen(PortActionEnum.Stop)}
                disabled={!isPortUp(port)}
                data-testid={`portStatusUpdateButtonMenuStop${key}`}
              >
                Stop
              </MenuItem>
              <MenuItem
                onClick={() => handleDialogOpen(PortActionEnum.Bounce)}
                data-testid={`portStatusUpdateButtonMenuBounce${key}`}
              >
                Bounce
              </MenuItem>
            </>
          </RequiresPermissions>
        </Menu>
      </>
    );
  }

  return null;
};

export { PortStatusUpdateButton };
