import {
  default as MuiAutoComplete,
  AutocompleteProps as MuiAutoCompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";

type AutoCompleteProps<T> = MuiAutoCompleteProps<T, boolean, boolean, boolean>;

export function AutoComplete<T>(props: AutoCompleteProps<T>) {
  return <MuiAutoComplete {...props} />;
}

export type { AutoCompleteProps, AutocompleteRenderInputParams };
