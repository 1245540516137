import { FC } from "react";
import { TableCell, TableFooter, TableRow, makeStyles } from "@xcira/components";
import { useCumulativeStatsSelector } from "hooks/useCumulativeStats";
import { convertToTime } from "../../utils/StatsUtil";

const useStyles = makeStyles()((theme) => ({
  footer: {
    width: "100%",
    "& td": {
      textAlign: "center",
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body2.fontSize,
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: "sticky",
    },
  },
}));

export const CumulativeStatsFooter: FC = () => {
  const { classes } = useStyles();
  const totals = useCumulativeStatsSelector((stats) => stats.totals);

  return (
    <TableFooter className={classes.footer}>
      <TableRow>
        <TableCell>Totals</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell>{convertToTime(totals.totalRunningTime || 0)}</TableCell>
        <TableCell>{convertToTime(totals.totalLoginTime || 0)}</TableCell>
        <TableCell>{totals.totalItems}</TableCell>
        <TableCell>{totals.totalLotsClerked}</TableCell>
        <TableCell>{totals.totalSold}</TableCell>
        <TableCell>{totals.totalSoldOlr}</TableCell>
        <TableCell>{totals.totalSoldOlrBid}</TableCell>
        <TableCell>{totals.totalSoldRunnerup}</TableCell>
        <TableCell>{totals.totalAcceptedBidsOlr}</TableCell>
        <TableCell>{totals.totalAttendeesOlr}</TableCell>
        <TableCell>{totals.totalAttendeesOlrWithoutBid}</TableCell>
        <TableCell>{totals.totalAttendeesOlrBid}</TableCell>
        <TableCell>{totals.totalAttendeesOlrWon}</TableCell>
        <TableCell>{totals.totalSales?.toFixed(2)}</TableCell>
        <TableCell>{totals.totalSalesOlr?.toFixed(2)}</TableCell>
        <TableCell>{totals.totalAveragePerItem?.toFixed(2)}</TableCell>
        <TableCell>{totals.totalAveragePerItemOlr?.toFixed(2)}</TableCell>
        <TableCell>{totals.totalPercentageOlrRunnerup?.toFixed(0)}</TableCell>
        <TableCell>{totals.totalPercentageOlrBid?.toFixed(0)}</TableCell>
        <TableCell>{totals.totalPercentageOlrSold?.toFixed(0)}</TableCell>
        <TableCell>{totals.totalPercentageSalesOlr?.toFixed(0)}</TableCell>
      </TableRow>
    </TableFooter>
  );
};
