import { useAvStreamsSelector } from "hooks";
import { useMemo } from "react";
import { AvStream, Maybe } from "graphql/generated";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: AvStream) => any;

const defaultSelector = (avStream?: AvStream) => avStream;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? AvStream | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectAvStreamByPort = <T extends SelectFromResult>(
  port: Maybe<number> | undefined,
  selector: T = defaultSelector as T
): Result<T> => {
  const avStream = useAvStreamsSelector((avStreams) =>
    avStreams.find((avStream) => avStream.audioPort === port)
  );
  return useMemo(() => selector(avStream), [selector, avStream]);
};

export { useSelectAvStreamByPort };
