import { FC } from "react";
import { makeStyles, TableContainer, Table, TableBody } from "@xcira/components";
import { EventsHeader } from "./EventsHeader";
import { EventRow } from "./EventRow";
import { useSelectCurrentEventIds } from "hooks";

const useStyles = makeStyles()((theme) => ({
  head: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableContainer: {
    height: "70vh",
  },
}));

const EventsTable: FC = () => {
  const { classes } = useStyles();

  const eventIds = useSelectCurrentEventIds();

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table size="small" stickyHeader data-testid="eventsTable">
          <EventsHeader className={classes.head} />
          <TableBody>
            {eventIds.map((eventId) => {
              return <EventRow key={eventId} eventId={eventId} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <AttendDialog open={state.isAttendDialogOpen} eventId={state.eventId} dispatch={dispatch} />

      <RequiresPermissions permissions={Permission.AvStreamsRead}>
        <AvStreamDialog open={state.isAvDialogOpen} eventId={state.eventId} dispatch={dispatch} />
      </RequiresPermissions>

      <RequiresPermissions permissions={Permission.PortsUpdate}>
        <PortsConfirmationDialog
          open={state.isPortDialogOpen}
          eventId={state.eventId}
          dispatch={dispatch}
          action={state.action}
          portNumber={state.portNumber}
        />
      </RequiresPermissions> */}
    </>
  );
};

export { EventsTable };
