import { FC } from "react";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import type { TabsProps } from "@mui/material/Tabs";
import type { TabProps } from "@mui/material/Tab";

const Tabs: FC<TabsProps> = (props) => <MuiTabs {...props} />;
const Tab: FC<TabProps> = (props) => <MuiTab {...props} />;

export { Tabs, Tab };

export type { TabsProps, TabProps };
