import { HighestPlayerVersion } from "graphql/generated";
import { cloudServicesEndpoints } from "services";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: HighestPlayerVersion) => any;

const defaultHighestVersion: HighestPlayerVersion = {};

function useHighestPlayerVersionSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetHighestPlayerVersion.useQueryState(undefined, {
    selectFromResult: (result) =>
      selector(result.data?.highestPlayerVersion ?? defaultHighestVersion),
  });
}

export { useHighestPlayerVersionSelector };
