import { FC } from "react";
import { makeStyles, TableRow, TableCell, IconButton, Icon, Grid } from "@xcira/components";
import { AttendeeTypeEnum, Permission } from "graphql/generated";
import { RequiresPermissions } from "components";
import { useAttend } from "hooks";

const useStyles = makeStyles()(() => ({
  launch: {
    minWidth: 240,
    width: "20vw",
  },
  creditLimit: { minWidth: 140 },
  sellerId: {
    minWidth: 100,
  },
}));

interface AttendeeRowProps {
  id: number;
  clientAttendeeId: string;
  name: string;
  type: AttendeeTypeEnum;
  creditLimit?: number | null;
  warranty?: string;
  sellerId?: string | null;
  ahco: string;
  eventId: number;
  flavor: string;
  devOverride?: string;
}

const AttendeeRow: FC<AttendeeRowProps> = ({
  id,
  clientAttendeeId,
  name,
  type,
  creditLimit,
  warranty,
  sellerId,
  ahco,
  eventId,
  flavor,
  devOverride,
}) => {
  const { classes } = useStyles();
  const selectedFlavor = flavor === "default" ? undefined : flavor;

  const attend = useAttend(ahco, eventId, type, selectedFlavor, name, devOverride);

  return (
    <TableRow key={id} data-testid={`attendeeRow${id}`}>
      <RequiresPermissions permissions={Permission.AttendeesLaunch}>
        <>
          <TableCell className={classes.launch} data-testid={`attendeeCellLaunchAttendee${id}`}>
            <Grid container justifyContent="center">
              <Grid item>
                <IconButton onClick={attend}>
                  <Icon icon="launch" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell></TableCell>
        </>
      </RequiresPermissions>

      <TableCell data-testid={`attendeeCellClientAttendeeId${id}`}>{clientAttendeeId}</TableCell>
      <TableCell data-testid={`attendeeCellName${id}`}>{name}</TableCell>
      <TableCell data-testid={`attendeeCellType${id}`}>{type}</TableCell>
      <TableCell
        align="right"
        data-testid={`attendeeCellCreditLimit${id}`}
        className={classes.creditLimit}
      >
        {creditLimit}
      </TableCell>
      <TableCell data-testid={`attendeeCellWarranty${id}`}>{warranty}</TableCell>
      <TableCell
        align="right"
        data-testid={`attendeeCellSellerId${id}`}
        className={classes.sellerId}
      >
        {sellerId}
      </TableCell>
    </TableRow>
  );
};

export { AttendeeRow };
