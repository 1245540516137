import { AvConnectionTypeEnum } from "graphql/generated";
import { useCallback } from "react";
import { useChangeAvConnectionMutation } from "services";

function useChangeAvConnection(facilityName: string, type: AvConnectionTypeEnum) {
  const [changeAvConnection, result] = useChangeAvConnectionMutation();

  const callback = useCallback(async () => {
    const responses = await changeAvConnection({ facilityName, type }).unwrap();

    return responses.changeAvConnection.responses[0].response;
  }, [changeAvConnection, facilityName, type]);

  return [callback, result] as const;
}
export { useChangeAvConnection };
