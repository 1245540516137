import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

export class DateUtil {
  static toDayjs(date: Date) {
    return dayjs(date);
  }

  static toStartOf(timestamp: number, unit: dayjs.OpUnitType) {
    return dayjs(timestamp).startOf(unit).valueOf();
  }
  static toEndOf(timestamp: number, unit: dayjs.OpUnitType) {
    return dayjs(timestamp).endOf(unit).valueOf();
  }
  static add(timestamp: number, amount: number, unit: dayjs.ManipulateType) {
    return dayjs(timestamp).add(amount, unit).valueOf();
  }
  static subract(timestamp: number, amount: number, unit: dayjs.ManipulateType) {
    return dayjs(timestamp).subtract(amount, unit).valueOf();
  }
  static isValid(timestamp: number) {
    return dayjs(timestamp).isValid();
  }
  static format(timestamp: number, format: string) {
    return dayjs(timestamp).format(format);
  }
  static isBefore(timestamp: number, comparisonTimestamp: number) {
    return dayjs(timestamp).isBefore(comparisonTimestamp);
  }
  static isSameOrBefore(timestamp: number, comparisonTimestamp: number) {
    return dayjs(timestamp).isSameOrBefore(comparisonTimestamp);
  }
  static isAfter(timestamp: number, comparisonTimestamp: number) {
    return dayjs(timestamp).isAfter(comparisonTimestamp);
  }
  static isSameOrAfter(timestamp: number, comparisonTimestamp: number) {
    return dayjs(timestamp).isSameOrAfter(comparisonTimestamp);
  }
  static isBetween(timestamp: number, startTime: number, endTime: number) {
    return dayjs(timestamp).isBetween(startTime, endTime, null, "[]");
  }
  static fromNow(timestamp?: number) {
    return dayjs(timestamp).fromNow();
  }
  static formatFromString(date: string, format: string) {
    return dayjs(date).format(format);
  }
  static stringToUnix(date: string) {
    return dayjs(date).unix();
  }
}

export type { Dayjs };
