import { CumulativeStatDto } from "graphql/generated";
import { useMemo } from "react";
import { useCumulativeStatsSelector } from "./useCumulativeStatsSelector";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: CumulativeStatDto) => any;

const defaultSelector = (stat?: CumulativeStatDto) => stat;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? CumulativeStatDto | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectCumulativeStatById = <T extends SelectFromResult>(
  id: number,
  selector: T = defaultSelector as T
): Result<T> => {
  const cumulativeStat = useCumulativeStatsSelector((stats) =>
    stats.cumulativeStats?.find((cumulativeStat) => cumulativeStat.id === id)
  );
  return useMemo(() => selector(cumulativeStat), [selector, cumulativeStat]);
};

export { useSelectCumulativeStatById };
