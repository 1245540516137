import { cloudServices } from "services";
import { AvStream } from "graphql/generated";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: AvStream[]) => any;

const defaultAvStreams: AvStream[] = [];

function useAvStreamsSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServices.endpoints.GetAvStreams.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.avStreams ?? defaultAvStreams),
  });
}

export { useAvStreamsSelector };
