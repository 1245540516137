import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AttendeeTypeEnum, AvConnectionTypeEnum, Port, PortActionEnum } from "graphql/generated";
import { Maybe } from "../../graphql/generated";

export type DialogType =
  | "Attend"
  | "MultiAttend"
  | "Av"
  | "Port"
  | "SetGain"
  | "EventStatus"
  | "AvConnection"
  | "CustomerAttend"
  | "None";

export type EventPort = Port | undefined;

type NoProps = object;

type EventProps = {
  eventId: number;
};

type PortProps = Partial<EventProps> & {
  ports: EventPort[];
  action?: PortActionEnum;
};

type AvProps = {
  avPort?: Maybe<number>;
  auctionCompany?: string;
  facilityName?: string;
  clientEventId?: string;
};

type AvConnectionProps = {
  facilityName?: string;
  type: AvConnectionTypeEnum;
  description: string;
};

type CustomerAttendProps = {
  facilityName: string;
  ahco: string;
  type: AttendeeTypeEnum;
};

export type DialogPropsTypes = EventProps | PortProps | NoProps;

interface DialogProps {
  type: DialogType;
  props: DialogPropsTypes;
}

export interface AttendDialogProps extends DialogProps {
  type: "Attend";
  props: EventProps;
}

export interface MultiAttendDialogProps extends DialogProps {
  type: "MultiAttend";
  props: NoProps;
}

export interface AvDialogProps extends DialogProps {
  type: "Av";
  props: AvProps;
}

export interface PortDialogProps extends DialogProps {
  type: "Port";
  props: PortProps;
}

export interface SetGainDialogProps extends DialogProps {
  type: "SetGain";
  props: EventProps;
}

export interface EventStatusDialogProps extends DialogProps {
  type: "EventStatus";
  props: NoProps;
}

export interface AvConnectionDialogProps extends DialogProps {
  type: "AvConnection";
  props: AvConnectionProps;
}

export interface CustomerAttendDialogProps extends DialogProps {
  type: "CustomerAttend";
  props: CustomerAttendProps;
}

interface InitialStatus extends DialogProps {
  type: "None";
  props: NoProps;
}

export type DialogStateMap =
  | InitialStatus
  | PortDialogProps
  | AttendDialogProps
  | MultiAttendDialogProps
  | AvDialogProps
  | SetGainDialogProps
  | EventStatusDialogProps
  | AvConnectionDialogProps
  | CustomerAttendDialogProps;

export type ExtractDialogProps<T extends DialogType> = Extract<
  DialogStateMap,
  { type: T }
>["props"];

export type DialogState = {
  isOpen: boolean;
} & DialogStateMap;

const initialState = {
  isOpen: false,
  type: "None",
  props: {},
} as DialogState;

const dialogs = createSlice({
  name: "dialogs",
  initialState,
  reducers: {
    openDialog: <T extends DialogStateMap>(state: DialogState, action: PayloadAction<T>) => {
      state.isOpen = true;
      state.type = action.payload.type;
      state.props = action.payload.props;
    },
    closeDialog: () => initialState,
  },
});

export const { name: dialogsReducerPath, reducer: dialogsReducer } = dialogs;

export const { openDialog, closeDialog } = dialogs.actions;

export const selectIsDialogOpen = (state: RootState) => state.dialogs.isOpen;
export const selectDialogType = (state: RootState) => state.dialogs.type;
export const selectDialogProps = (state: RootState) => state.dialogs.props;
