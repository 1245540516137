import { GetCompaniesQuery } from "graphql/generated";
import { useCompaniesSelector } from "hooks";
import { useMemo } from "react";

type AuctionCompany = GetCompaniesQuery["auctionCompanies"][0];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: AuctionCompany) => any;

const defaultSelector = (company?: AuctionCompany) => company;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? AuctionCompany | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectCompanyByAhco = <T extends SelectFromResult>(
  ahco: string | undefined,
  selector: T = defaultSelector as T
): Result<T> => {
  const company = useCompaniesSelector((companies) =>
    companies.find((company) => company.auctionCompany === ahco)
  );

  return useMemo(() => selector(company), [selector, company]);
};

export { useSelectCompanyByAhco };
