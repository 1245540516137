import { FC, PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useEvents, useLoggedInAttendeeCounts } from "hooks";
import type { RouterParams } from "components/ProtectedRoutes";
import { POLLING_INTERVAL } from "constants/constants";

interface EventsProviderProps {}

const EventsProvider: FC<PropsWithChildren<EventsProviderProps>> = ({ children }) => {
  const { eventId: id } = useParams<RouterParams>();
  const eventId = Number(id);

  useEvents(POLLING_INTERVAL);

  useLoggedInAttendeeCounts({ eventIds: [eventId] }, POLLING_INTERVAL);

  return children as JSX.Element;
};

export { EventsProvider };
