import { FC } from "react";
import { selectFilteredAttendees } from "slices";
import { useAppSelector, useAttendees } from "hooks";
import { CircularProgress, makeStyles } from "@xcira/components";
import { AttendeeFilters } from "./AttendeeFilters";
import { AttendeeTable } from "./AttendeesTable";
import { POLLING_INTERVAL } from "../../constants/constants";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: "relative",
    minHeight: 200,
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

interface AttendeesProps {
  eventId: number;
  ahco: string;
}

const Attendees: FC<AttendeesProps> = ({ eventId, ahco }) => {
  const { classes } = useStyles();
  const { isLoading } = useAttendees(eventId, POLLING_INTERVAL);
  const attendees = useAppSelector((state) => selectFilteredAttendees(state, eventId));

  return (
    <div className={classes.root} data-testid="attendees">
      {isLoading ? (
        <CircularProgress
          data-testid="attendeesLoader"
          className={classes.circularProgress}
          size={100}
        />
      ) : (
        <>
          <AttendeeFilters />
          <AttendeeTable attendees={attendees} eventId={eventId} ahco={ahco} />
        </>
      )}
    </div>
  );
};

export { Attendees };
