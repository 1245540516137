import { InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@xcira/components";
import { useCompaniesSelector, useAppSelector } from "hooks";
import { FC } from "react";
import { selectFilteredEvents } from "slices";

interface MultiAttendCompanySelectorProps {
  setAuctionHouseFilter: (ahco: string) => void;
  ahco: string;
  className?: string;
}

const MultiAttendCompanySelect: FC<MultiAttendCompanySelectorProps> = ({
  setAuctionHouseFilter,
  ahco,
}) => {
  const filteredEvents = useAppSelector(selectFilteredEvents);
  const companyIds = [
    ...new Set(filteredEvents.map((filteredEvent) => filteredEvent.auctionCompany)),
  ];

  const companies = useCompaniesSelector((companies) =>
    companies.filter((company) => companyIds.includes(company.auctionCompany))
  );

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setAuctionHouseFilter(event.target.value as string);
  };

  return (
    <>
      <InputLabel htmlFor="multi-attend-auction-company-select" data-testid="auctionCompanyLabel">
        Auction House
      </InputLabel>
      <Select
        id="multi-attend-auction-company-select"
        value={ahco}
        onChange={handleChange}
        label="Auction House"
        data-testid="auctionCompanySelect"
        displayEmpty
      >
        {companies.map((company) => (
          <MenuItem
            value={company.auctionCompany}
            key={`companyFlavor${company.auctionCompany}`}
            data-testid={`companyFlavor${company.auctionCompany}`}
          >
            <Typography variant="body2">{company.fullName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export { MultiAttendCompanySelect };
