import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AuctionAttendee, AttendeeTypeEnum } from "graphql/generated";
import { cloudServices } from "services";

interface AttendeeState {
  roleFilters: AttendeeTypeEnum[];
  displayNameFilter: string;
  eventId: number;
}

const initialState: AttendeeState = {
  roleFilters: [],
  displayNameFilter: "",
  eventId: 0,
};

const attendees = createSlice({
  name: "attendees",
  initialState,
  reducers: {
    setRoleFilter: (state, action: PayloadAction<AttendeeTypeEnum>) => {
      state.roleFilters = [...new Set([...state.roleFilters, action.payload])];
    },
    setDisplayNameFilter: (state, action: PayloadAction<string>) => {
      state.displayNameFilter = action.payload;
    },
    removeRoleFilter: (state, action: PayloadAction<AttendeeTypeEnum>) => {
      state.roleFilters = state.roleFilters.filter((role) => role !== action.payload);
    },
  },
});

export const { name: attendeesReducerPath, reducer: attendeesReducer } = attendees;
export const { setRoleFilter, setDisplayNameFilter, removeRoleFilter } = attendees.actions;
export const selectRoleFilters = (state: RootState) => state.attendees.roleFilters;
export const selectDisplayNameFilter = (state: RootState) => state.attendees.displayNameFilter;

const selectEventId = (state: RootState, eventId: number): [RootState, number] => [state, eventId];

export const selectAttendees = createSelector([selectEventId], ([state, eventId]) => {
  const { data } = cloudServices.endpoints.GetAttendees.select({ eventId })(state);

  return data?.auctionAttendees;
});

export const selectFilteredAttendees = createSelector(
  selectAttendees,
  selectRoleFilters,
  selectDisplayNameFilter,
  (attendees, roleFilters, displayNameFilter) => {
    const result = (attendees?.filter((attendee) => {
      const roleFilter = roleFilters.length ? roleFilters.includes(attendee.type) : true;
      const nameFilter = displayNameFilter
        ? attendee.name.toLowerCase().includes(displayNameFilter.toLowerCase())
        : true;

      return roleFilter && nameFilter;
    }) ?? []) as AuctionAttendee[];

    return result;
  }
);
