import { IconProps } from "@xcira/components";
import { PortStatusEnum, PortTypeEnum } from "graphql/generated";
import { EventPort } from "slices/dialogs";

type Color = IconProps["color"];

const iconColors: Record<PortStatusEnum, Color> = {
  [PortStatusEnum.Down]: "error",
  [PortStatusEnum.Up]: "success",
};

export const isPortUp = (port?: EventPort) => {
  if (!port) {
    return false;
  }
  return port.status === PortStatusEnum.Up;
};

export const isPortsUp = (ports: EventPort[] = []) => {
  return ports.every((port) => isPortUp(port));
};

export const getColor = (ports: EventPort[] = []): Color => {
  if (isPortsUp(ports)) {
    return iconColors[PortStatusEnum.Up];
  }
  return iconColors[PortStatusEnum.Down];
};

export const getConstructedDownPort = (portNumber: number, type: PortTypeEnum) => {
  return { portNumber, status: PortStatusEnum.Down, type };
};
