import { GetFacilitiesQuery } from "graphql/generated";
import { cloudServicesEndpoints } from "services";

type Facilities = GetFacilitiesQuery["facilities"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: Facilities) => any;

const defaultFaciliites: Facilities = [];

function useFacilitiesSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetFacilities.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.facilities ?? defaultFaciliites),
  });
}

export { useFacilitiesSelector };
