import { useAppSelector } from "hooks";
import { useGetCumulativeStatsQuery } from "graphql/generated";
import { selectAhco, selectEndDate, selectLimit, selectStartDate } from "slices";

export function useCumulativeStats(noLimit?: boolean) {
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const limit = useAppSelector(selectLimit);
  const ahco = useAppSelector(selectAhco);

  return useGetCumulativeStatsQuery({
    startDate,
    endDate,
    limit: noLimit ? 250 : limit,
    ahco,
  });
}
