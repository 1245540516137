import { memo, useCallback, FC } from "react";
import { IconButton } from "@xcira/components";
import { StreamStatusIcon } from "./StreamManagement";
import { useAppDispatch } from "hooks";
import { openDialog } from "slices/dialogs";
import { Maybe } from "graphql/generated";

interface StreamIconButtonProps {
  avPort?: Maybe<number>;
  auctionCompany?: string;
  facilityName?: string;
  clientEventId?: string;
}

const StreamIconButton: FC<StreamIconButtonProps> = memo(
  ({ avPort, auctionCompany, facilityName, clientEventId }) => {
    const dispatch = useAppDispatch();
    const handleClick = useCallback(() => {
      dispatch(
        openDialog({ type: "Av", props: { avPort, auctionCompany, facilityName, clientEventId } })
      );
    }, [dispatch, avPort, auctionCompany, facilityName, clientEventId]);

    return (
      <IconButton onClick={handleClick} data-testid={`streamIconButton${avPort}${facilityName}`}>
        <StreamStatusIcon avPort={avPort} />
      </IconButton>
    );
  }
);

export { StreamIconButton };
