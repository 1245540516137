import { cloudServicesEndpoints } from "services";
import { GetCompaniesQuery } from "graphql/generated";

type AuctionCompanies = GetCompaniesQuery["auctionCompanies"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data: AuctionCompanies) => any;

const defaultCompanies: AuctionCompanies = [];

function useCompaniesSelector<T extends SelectFromResult>(selector: T): ReturnType<T> {
  return cloudServicesEndpoints.GetCompanies.useQueryState(undefined, {
    selectFromResult: (result) => selector(result.data?.auctionCompanies ?? defaultCompanies),
  });
}

export { useCompaniesSelector };
