import { CustomerSalesLink } from "graphql/generated";
import { useCustomerSalesLinksSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: CustomerSalesLink) => any;

const defaultSelector = (customerSalesLink?: CustomerSalesLink) => customerSalesLink;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? CustomerSalesLink | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectCustomerSalesLinkByFacilityName = <T extends SelectFromResult>(
  ahco: string,
  facilityName: string,
  selector: T = defaultSelector as T
): Result<T> => {
  const customerSalesLink = useCustomerSalesLinksSelector(ahco, (customerSalesLinks) =>
    customerSalesLinks?.customerSalesLinks?.find(
      (customerSalesLink) => customerSalesLink.facilityName === facilityName
    )
  );

  return useMemo(() => selector(customerSalesLink), [selector, customerSalesLink]);
};

export { useSelectCustomerSalesLinkByFacilityName };
