import { FC, PropsWithChildren } from "react";
import { Alert, AlertProps } from "../Alert";
import { Snackbar, SnackbarProps } from "../Snackbar";

export interface ToastProps extends PropsWithChildren {
  SnackbarProps?: SnackbarProps;
  AlertProps?: AlertProps;
}

export const Toast: FC<ToastProps> = ({ children, SnackbarProps, AlertProps }) => {
  return (
    <Snackbar {...SnackbarProps}>
      <Alert {...AlertProps}>{children}</Alert>
    </Snackbar>
  );
};
