import { useAppSelector } from "hooks";
import { useMemo } from "react";
import { selectEventStatusFilter } from "slices";

const useSelectEventStatusFilterLabels = () => {
  const selectedEventStatusFilter = useAppSelector(selectEventStatusFilter);

  return useMemo(() => selectedEventStatusFilter.map((filter) => filter.label), [
    selectedEventStatusFilter,
  ]);
};

export { useSelectEventStatusFilterLabels };
