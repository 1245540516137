import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@xcira/components";
import { useCustomerSalesLinksByAhco, useCustomerSalesLinksSelector } from "hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { CustomerSalesLinksParams } from "../ProtectedRoutes/ProtectedRoutes";
import { CustomerSalesLinkCard } from "./CustomerSalesLinkCard";
import { ToolsDialog } from "components/Dialogs";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  image: {
    marginLeft: theme.spacing(5),
    backgroundColor: theme.palette.grey[500],
    maxWidth: "50%",
    padding: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(5.5),
  },
  title: {
    marginBottom: theme.spacing(5.5),
    textAlign: "center",
  },
  noData: {
    padding: theme.spacing(2),
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

const CustomerSalesLinks: FC = () => {
  const { classes } = useStyles();
  const { ahco } = useParams<CustomerSalesLinksParams>();
  const { isLoading } = useCustomerSalesLinksByAhco(ahco);
  const customerLogo = useCustomerSalesLinksSelector(
    ahco,
    (customerSalesLinks) => customerSalesLinks.customerLogo
  );
  const facilityNames = useCustomerSalesLinksSelector(ahco, (customerSalesLinks) =>
    customerSalesLinks.customerSalesLinks?.map(
      (customerSalesLink) => customerSalesLink.facilityName
    )
  );

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="customerSalesLinkLoader"
        className={classes.circularProgress}
        size={100}
      />
    );
  }

  if (!facilityNames) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.noData}
        data-testid="customerSalesLinksNoData"
      >
        <Grid item xs>
          <Typography variant="h6">No data found. Check auction company and try again.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <img src={customerLogo ?? ""} alt="Company Logo" className={classes.image} />
      <Divider className={classes.divider} />
      <Typography variant="h6" className={classes.title}>
        Live Bidding
      </Typography>
      <Container data-testid="customerSalesLinksContainer">
        <Grid container spacing={2.5}>
          {facilityNames?.map((facilityName) => {
            return (
              <Grid item md={4} key={facilityName}>
                <CustomerSalesLinkCard ahco={ahco} facilityName={facilityName} />
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <ToolsDialog />
    </>
  );
};

export { CustomerSalesLinks };
