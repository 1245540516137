import { useSetAvPublisherActionMutation } from "services";
import { StreamActionEnum } from "../../graphql/generated";
import { useCallback } from "react";

function useSetAvPublisherAction(action: StreamActionEnum, avPort: number) {
  const [setAvPublisherAction] = useSetAvPublisherActionMutation();

  return useCallback(async () => {
    const result = await setAvPublisherAction({ action, avPort }).unwrap();

    return result;
  }, [setAvPublisherAction, action, avPort]);
}

export { useSetAvPublisherAction };
