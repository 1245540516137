import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  isAnyOf,
  AnyAction,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  discoveryServiceReducerPath,
  discoveryServiceReducer,
  discoveryServiceMiddleware,
  notificationsPath,
  notificationsReducer,
} from "@xcira/commons";
import { errorHandler } from "middleware";
import {
  cloudServicesReducerPath,
  cloudServicesReducer,
  cloudServicesMiddleware,
  cloudServicesEndpoints,
} from "services/cloud";
import {
  attendeesReducer,
  authReducer,
  dialogsReducer,
  eventsReducer,
  facilitiesReducer,
  logout,
} from "slices";
import { cumulativeStatsReducer } from "slices/cumulative-stats";

const reducers = combineReducers({
  attendees: attendeesReducer,
  auth: authReducer,
  dialogs: dialogsReducer,
  events: eventsReducer,
  facilities: facilitiesReducer,
  cumulativeStats: cumulativeStatsReducer,
  [notificationsPath]: notificationsReducer,
  [discoveryServiceReducerPath]: discoveryServiceReducer,
  [cloudServicesReducerPath]: cloudServicesReducer,
});

function isAuthenticationError(error: unknown): error is { status: number } {
  return (
    error !== null &&
    typeof error === "object" &&
    "error" in error &&
    error.error === "Unauthorized" &&
    "message" in error &&
    typeof error.message === "string" &&
    error.message.includes("UNAUTHENTICATED")
  );
}

const isRejectedCloudServiceAction = (action: AnyAction) =>
  isAnyOf(
    cloudServicesEndpoints.Attend.matchRejected,
    ...Object.values(cloudServicesEndpoints).map((endpoint) => endpoint.matchRejected)
  ) && isAuthenticationError(action.payload);

const shouldReset = (action: Action) => {
  return logout.match(action) || isRejectedCloudServiceAction(action);
};

const appReducer = (state: ReturnType<typeof reducers> | undefined, action: AnyAction) => {
  if (shouldReset(action)) {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const persistConfig = {
  key: "olr-tools",
  storage,
  whitelist: ["attendees", "auth", "events", "facilities"],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(errorHandler.middleware)
      .concat([cloudServicesMiddleware, discoveryServiceMiddleware]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
