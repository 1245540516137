import { FC, memo } from "react";
import { makeStyles, Grid, Typography, Box } from "@xcira/components";
import { AvPublisherInfo } from "graphql/generated";

interface StreamInfoRawProps {
  infoRaw?: AvPublisherInfo | null;
}

const useStyles = makeStyles()((theme) => ({
  box: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 220,
    borderWidth: "1px",
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
  },
}));

const StreamInfoRaw: FC<StreamInfoRawProps> = memo(({ infoRaw }) => {
  const { classes } = useStyles();
  const mic = infoRaw?.mic;
  const camera = infoRaw?.camera;
  const env = infoRaw?.env;

  return (
    <Grid container spacing={2} data-testid="streamInfoRaw">
      <Grid item xs={12} lg={4}>
        <Box className={classes.box} data-testid={`streamInfoRawBoxMic`}>
          <Typography variant="body1" data-testid={`streamInfoRawTitleTextMic`}>
            Mic
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridName}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.name}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridMuted}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Muted</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.muted?.toString()}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridCodec}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Codec</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.codec}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridRate}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Rate</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.rate}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridQuality}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Quality</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.quality}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridFramesPerPacket}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Frames Per Packet</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{mic?.framesperpacket}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box className={classes.box} data-testid={`streamInfoRawBoxCamera`}>
          <Typography variant="body1" data-testid={`streamInfoRawTitleTextCamera`}>
            Camera
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridName}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Name</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.name}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridMuted}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Muted</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.muted?.toString()}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridCodec}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Codec</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.codec}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridProfile}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Profile</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.profile}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridLevel}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Level</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.level}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridWidth}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Width</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.width}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridHeight}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Height</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.height}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridFPS}`}
          >
            <Grid item>
              <Typography variant="subtitle2">FPS</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.fps}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridKeyFrameInterval}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Key Frame Interval</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.keyframeinterval}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridQuality}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Quality</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.quality}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridBandwidth}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Bandwidth</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{camera?.bandwidth}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box className={classes.box} data-testid={`streamInfoRawBoxMic`}>
          <Typography variant="body1" data-testid={`streamInfoRawTitleTextMic`}>
            Env
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridPlayerVersion}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Publisher Version</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{env?.playerversion}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridOS}`}
          >
            <Grid item>
              <Typography variant="subtitle2">OS</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{env?.os}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.gridContainer}
            data-testid={`streamInfoRawGridMuted}`}
          >
            <Grid item>
              <Typography variant="subtitle2">Debug</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{env?.debug?.toString()}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
});

export { StreamInfoRaw };
