import { AttendeeTypeEnum } from "graphql/generated";
import { useLoggedInAttendeeCountsSelector } from "./useLoggedInAttendeeCountsSelector";
import { useSelectCurrentEventIds } from "hooks";

const useLoggedInAttendeeCountsTotal = (type?: AttendeeTypeEnum, cacheKey?: number[]): number => {
  const eventIds = useSelectCurrentEventIds();
  const queryCacheKey = cacheKey ?? eventIds;
  const attendeeTypes = [AttendeeTypeEnum.Bidder, AttendeeTypeEnum.Clerk, AttendeeTypeEnum.Viewer];

  return useLoggedInAttendeeCountsSelector(
    (counts) =>
      counts.reduce((acc, count) => {
        if ((type && count.type === type) || (!type && attendeeTypes.includes(count.type))) {
          acc += count.count;
        }

        return acc;
      }, 0),
    { eventIds: queryCacheKey }
  );
};

export { useLoggedInAttendeeCountsTotal };
