import { FC } from "react";
import {
  makeStyles,
  List,
  Icon,
  IconProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@xcira/components";
import { usePortStatusSelector } from "hooks";
import { useSelectPortByNumber } from "hooks";
import { PortStatusUpdateButton } from "./PortsControl";
import { getColor, getConstructedDownPort } from "utils/PortUtil";
import { PortTypeEnum } from "graphql/generated";
import { DateUtil } from "@xcira/commons";

const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: 170,
  },
  listItem: { paddingTop: 0, paddingBottom: 0 },
  listItemText: {
    marginTop: 0,
    marginRight: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 0,
  },
  updateButton: {
    marginRight: theme.spacing(2),
  },
  updatedOn: {
    justifyContent: "flex-end",
  },
}));

export interface PortsProps {
  eventId?: number;
  updatePortNumber?: number;
  bidPortNumber?: number;
  masterPortNumber?: number;
  size?: IconProps["fontSize"];
  divider?: boolean;
  allowUpdates?: boolean;
}

export const Ports: FC<PortsProps> = ({
  eventId,
  updatePortNumber = 0,
  bidPortNumber = 0,
  masterPortNumber = 0,
  size = "medium",
  divider,
  allowUpdates,
}) => {
  const { classes } = useStyles();

  const updatePort = useSelectPortByNumber(updatePortNumber);
  const bidPort = useSelectPortByNumber(bidPortNumber);
  const masterPort = useSelectPortByNumber(masterPortNumber);
  const key = `${updatePortNumber}_${bidPortNumber}_${masterPortNumber}_${eventId}`;
  const portStatusUpdatedOn = usePortStatusSelector((portStatus) => portStatus.updatedOn);

  return (
    <List className={classes.root} data-testid="ports">
      <ListItem dense className={classes.listItem}>
        <ListItemText
          color="primary"
          primary="Update Port"
          secondary={updatePortNumber}
          primaryTypographyProps={{ variant: "subtitle2" }}
          className={classes.listItemText}
          data-testid={`portsUpdatePort${key}`}
        />
        {allowUpdates && (
          <div className={classes.updateButton}>
            <PortStatusUpdateButton
              eventId={eventId}
              port={updatePort ?? getConstructedDownPort(updatePortNumber, PortTypeEnum.Update)}
            />
          </div>
        )}
        <ListItemIcon className={classes.listItemIcon}>
          <Icon
            icon="brightness_1"
            fontSize={size}
            color={getColor([updatePort])}
            data-testid={`portsUpdatePortIcon${key}`}
          />
        </ListItemIcon>
      </ListItem>
      {divider && <Divider data-testid="portsDividerUpdatePort" />}

      <ListItem dense className={classes.listItem}>
        <ListItemText
          color="primary"
          primary="Bid Port"
          secondary={bidPortNumber}
          primaryTypographyProps={{ variant: "subtitle2" }}
          className={classes.listItemText}
          data-testid={`portsBidPort${key}`}
        />

        {allowUpdates && (
          <div className={classes.updateButton}>
            <PortStatusUpdateButton
              eventId={eventId}
              port={bidPort ?? getConstructedDownPort(bidPortNumber, PortTypeEnum.Bid)}
            />
          </div>
        )}

        <ListItemIcon className={classes.listItemIcon}>
          <Icon
            icon="brightness_1"
            fontSize={size}
            color={getColor([bidPort])}
            data-testid={`portsBidPortIcon${key}`}
          />
        </ListItemIcon>
      </ListItem>

      {divider && <Divider data-testid="portsDividerBidPort" />}

      <ListItem dense className={classes.listItem}>
        <ListItemText
          color="primary"
          primary="Master Port"
          secondary={masterPortNumber}
          primaryTypographyProps={{ variant: "subtitle2" }}
          className={classes.listItemText}
          data-testid={`portsMasterPort${key}`}
        />
        <ListItemIcon className={classes.listItemIcon}>
          <Icon
            icon="brightness_1"
            fontSize={size}
            color={getColor([masterPort])}
            data-testid={`portsMasterPortIcon${key}`}
          />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.updatedOn}>
        <Typography variant="caption">
          Ports Last Updated On:{" "}
          {portStatusUpdatedOn ? DateUtil.format(portStatusUpdatedOn, "MM/D/YYYY HH:mm:ss") : ""}
        </Typography>
      </ListItem>
    </List>
  );
};
