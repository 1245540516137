import { Card, CardContent, Grid, Typography } from "@xcira/components";
import { FC } from "react";

interface NoFacilitiesFoundPage {
  className: string;
}

const NoFacilitiesFound: FC<NoFacilitiesFoundPage> = ({ className }) => (
  <Grid container data-testid="noFacilities">
    <Grid item xs={12}>
      <Card elevation={0} className={className}>
        <CardContent>
          <Typography variant="h5">No Facilities Found</Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export { NoFacilitiesFound };
