import { FC } from "react";
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import { Button, ButtonProps } from "../Button";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

export interface LinkProps extends RouterLinkProps {
  to: string;
}

export const Link: FC<LinkProps> = ({ to, ...props }) => {
  return (
    <RouterLink to={{ pathname: to }} {...props}>
      {props.children}
    </RouterLink>
  );
};

export type TextLinkProps = MuiLinkProps & LinkProps;

export const TextLink: FC<TextLinkProps> = ({ ...props }) => (
  <Link component={MuiLink} {...props} />
);

export type ButtonLinkProps = ButtonProps & LinkProps;

export const ButtonLink: FC<ButtonLinkProps> = ({ ...props }) => (
  <Link component={Button} {...props} />
);
