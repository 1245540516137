import { FC } from "react";
import {
  Grid,
  makeStyles,
  DatePicker,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@xcira/components";
import { CompanyFilter } from "components/Events";
import { useCumulativeStats } from "hooks/useCumulativeStats";
import { useAppDispatch, useAppSelector, useCompanies } from "hooks";
import {
  selectCumulativeStatsAuctionHouseFilter,
  selectEndDate,
  selectLimit,
  selectStartDate,
  setCumulativeStatsAuctionHouseFilter,
  setEndDate,
  setLimit,
  setStartDate,
} from "slices";
import { DateUtil, Dayjs } from "@xcira/commons";
import { STATS_DEFAULT_LIMIT } from "../../constants/constants";
import { CumulativeStatsDownloadButton } from "./CumulativeStatsDownloadButton";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const limitOptions = [STATS_DEFAULT_LIMIT, 500];

export const CumulativeStatsFilters: FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(selectStartDate);
  const endDate = useAppSelector(selectEndDate);
  const limit = useAppSelector(selectLimit);

  useCompanies();
  useCumulativeStats();

  const startDateChanged = (newValue: Dayjs | null) => {
    if (newValue) {
      dispatch(setStartDate(newValue.toDate()));
    }
  };

  const endDateChanged = (newValue: Dayjs | null) => {
    if (newValue) {
      newValue && dispatch(setEndDate(newValue.toDate()));
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <CompanyFilter
          setAuctionHouseFilter={setCumulativeStatsAuctionHouseFilter}
          selectAuctionHouseFilter={selectCumulativeStatsAuctionHouseFilter}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Start Date"
          value={DateUtil.toDayjs(startDate)}
          maxDate={DateUtil.toDayjs(endDate)}
          onChange={startDateChanged}
          slotProps={{
            field: {
              readOnly: true,
            },
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="End Date"
          value={DateUtil.toDayjs(endDate)}
          minDate={DateUtil.toDayjs(startDate)}
          onChange={endDateChanged}
          slotProps={{
            field: {
              readOnly: true,
            },
          }}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="cumulativeStatsLimitLabel">Limit</InputLabel>
          <Select
            labelId="cumulativeStatsLimitLabel"
            value={limit}
            label="Limit"
            onChange={(evt) => {
              if (typeof evt.target.value === "number") {
                dispatch(setLimit(evt.target.value));
              }
            }}
          >
            {limitOptions.map((option) => (
              <MenuItem key={`cumulativeStatsLimitOption-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <CumulativeStatsDownloadButton />
      </Grid>
    </Grid>
  );
};
