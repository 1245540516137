import { FC, ReactNode } from "react";
import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemSecondaryActionProps,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps,
  ListItemText as MuiListItemText,
  ListItemTextProps,
} from "@mui/material";
import { IconName, Icon } from "../Icon";

interface ListItemProps extends MuiListItemProps {
  text?: string;
  icon?: IconName;
  children?: ReactNode;
}

const ListItem: FC<ListItemProps> = ({
  icon,
  text,
  children,
  ...props
}: {
  icon?: IconName;
  text?: string;
  children?: ReactNode;
  props?: MuiListItemProps;
}) => {
  return (
    <MuiListItem {...props}>
      {icon && (
        <MuiListItemIcon>
          <Icon {...{ icon }} />
        </MuiListItemIcon>
      )}
      {text && <MuiListItemText primary={text} />}
      {children}
    </MuiListItem>
  );
};

const ListItemIcon: FC<ListItemIconProps> = ({ children, ...props }) => {
  return <MuiListItemIcon {...props}>{children}</MuiListItemIcon>;
};

const ListItemText: FC<ListItemTextProps> = ({ ...props }) => {
  return <MuiListItemText {...props} />;
};

const ListItemSecondaryAction: FC<ListItemSecondaryActionProps> = ({ children }) => {
  return <MuiListItemSecondaryAction>{children}</MuiListItemSecondaryAction>;
};

const ListItemButton: FC<ListItemButtonProps> = ({ children, ...props }) => {
  return <MuiListItemButton {...props}>{children}</MuiListItemButton>;
};

export { ListItem, ListItemSecondaryAction, ListItemButton, ListItemIcon, ListItemText };
export type { ListItemProps, ListItemSecondaryActionProps, ListItemButtonProps };
