import { FC } from "react";
import { notify } from "@xcira/commons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@xcira/components";
import { PortActionEnum, PortTypeEnum } from "graphql/generated";
import { useAppDispatch, useUpdatePortStatusByPortNumber } from "hooks";
import { closeDialog, EventPort, ExtractDialogProps } from "slices/dialogs";
import { AttendeesCountTable } from "components/Attendees/AttendeesCountTable";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  dialogContent: {
    overflowX: "hidden",
    maxWidth: 540,
  },
  dialogActions: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  tableContainer: { marginBottom: theme.spacing(1) },
  infoText: { marginTop: theme.spacing(1) },
  bold: { fontWeight: 500 },
}));

const getPort = (ports: EventPort[] = [], type: PortTypeEnum) => {
  return ports.find((port) => port?.type === type);
};

const isBothPorts = (ports: EventPort[] = []) => {
  return Boolean(getPort(ports, PortTypeEnum.Update) && getPort(ports, PortTypeEnum.Bid));
};

const getPortSuccessMessage = (port: EventPort) => {
  return `${port?.type} port ${port?.portNumber}.`;
};

export type PortsConfirmationDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"Port">;

const PortsConfirmationDialog: FC<PortsConfirmationDialogProps> = ({
  isOpen,
  eventId,
  ports,
  action = PortActionEnum.Start,
}) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const updatePort = getPort(ports, PortTypeEnum.Update);
  const bidPort = getPort(ports, PortTypeEnum.Bid);

  const [changeUpdatePortStatus] = useUpdatePortStatusByPortNumber(
    updatePort?.portNumber ?? 0,
    action,
    PortTypeEnum.Update
  );
  const [changeBidPortStatus] = useUpdatePortStatusByPortNumber(
    bidPort?.portNumber ?? 0,
    action,
    PortTypeEnum.Bid
  );

  const handleSubmit = async () => {
    let isUpdatePortSuccess: boolean = false;
    let isBidPortSuccess: boolean = false;

    if (updatePort) {
      const {
        updatePortStatusByPortNumber: { success: updatePortSuccess },
      } = await changeUpdatePortStatus();
      isUpdatePortSuccess = updatePortSuccess;
    }

    if (bidPort) {
      const {
        updatePortStatusByPortNumber: { success: updatePortSuccess },
      } = await changeBidPortStatus();
      isBidPortSuccess = updatePortSuccess;
    }

    if (isUpdatePortSuccess || isBidPortSuccess) {
      dispatch(
        notify({
          message: `${action} action successful: 
          ${isUpdatePortSuccess ? getPortSuccessMessage(updatePort) : ""} 
          ${isBidPortSuccess ? getPortSuccessMessage(bidPort) : ""}`,
          severity: "success",
        })
      );

      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.dialog}
      data-testid="portsConfirmationDialog"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="portsConfirmationDialogTitle">
          Port Status Update
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          data-testid="portsConfirmationDialogClose"
        >
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {ports.length === 1 && (
          <Typography variant="body2">
            <span
              data-testid={`portsConfirmationDialogMessage${
                updatePort ? PortTypeEnum.Update : PortTypeEnum.Bid
              }`}
            >
              You are about to <span className={classes.bold}>{action}</span> the{" "}
              {updatePort && `Update port ${updatePort?.portNumber}.`}
              {bidPort && `Bid port ${bidPort?.portNumber}.`}
            </span>
          </Typography>
        )}

        {isBothPorts(ports) && (
          <>
            <Typography
              variant="body2"
              data-testid={`portsConfirmationDialogMessage${PortTypeEnum.Update}${PortTypeEnum.Bid}`}
            >
              You are about to <span className={classes.bold}>{action}</span> the following ports.
            </Typography>
            <TableContainer className={classes.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Update Port</TableCell>
                    <TableCell>Bid Port</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell data-testid={`portsConfirmationDialog${PortTypeEnum.Update}`}>
                      {updatePort?.portNumber}
                    </TableCell>
                    <TableCell data-testid={`portsConfirmationDialog${PortTypeEnum.Bid}`}>
                      {bidPort?.portNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {(action === PortActionEnum.Stop || action === PortActionEnum.Bounce) && eventId && (
          <AttendeesCountTable eventId={eventId} />
        )}

        <Typography variant="body2" className={classes.infoText}>
          It may take up to 20 seconds to {action.toLowerCase()} the port.
        </Typography>

        <Typography variant="body2" className={classes.infoText}>
          Proceed?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          autoFocus
          data-testid="portsConfirmationDialogCancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          data-testid="portsConfirmationDialogOkButton"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PortsConfirmationDialog };
