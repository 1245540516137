import { FC } from "react";
import { useHistory } from "react-router-dom";
import { FormControlLabel, Radio, RadioGroup, Typography, makeStyles } from "@xcira/components";

const useStyles = makeStyles()(() => ({
  root: {
    width: "100%",
  },
}));

export const Reports: FC = () => {
  const { classes } = useStyles();
  const history = useHistory();

  const navigate = (link: string) => {
    history.push(link);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h3">Reports</Typography>
      <RadioGroup row name="radio-buttons-group">
        <FormControlLabel
          value="Stats"
          control={<Radio />}
          label="Stats"
          onClick={() => navigate("/reports/stats")}
        />
      </RadioGroup>
    </div>
  );
};
