import { FC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Hidden,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@xcira/components";
import { FlavorSelect } from "components";
import { useAppDispatch, useSelectEventById } from "hooks";
import { useAttend } from "hooks/useAttend";
import { AttendeeTypeEnum } from "graphql/generated";
import { closeDialog, ExtractDialogProps } from "slices/dialogs";

const useStyles = makeStyles()((theme) => ({
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  formControl: {
    width: "70vw",
    maxWidth: 370,
  },
  flavorListItem: { cursor: "pointer" },
  flexRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "95%",
    paddingLeft: theme.spacing(1),
  },
  list: { marginTop: theme.spacing(1) },
  listItemText: { marginRight: theme.spacing(3) },
}));

export type AttendDialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"Attend">;

const AttendDialog: FC<AttendDialogProps> = ({ isOpen, eventId }) => {
  const [flavor, setFlavor] = useState("default");
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const event = useSelectEventById(eventId);
  const ahco = event?.auctionCompany;
  const selectedFlavor = flavor === "default" ? undefined : flavor;
  const attendGuestViewer = useAttend(ahco, eventId, AttendeeTypeEnum.GuestViewer, selectedFlavor);
  const attendGuestBidder = useAttend(ahco, eventId, AttendeeTypeEnum.GuestBidder, selectedFlavor);
  const attendGuestMarquee = useAttend(
    ahco,
    eventId,
    AttendeeTypeEnum.GuestMarquee,
    selectedFlavor
  );
  const attendGuestTechcoord = useAttend(ahco, eventId, AttendeeTypeEnum.GuestTechcoord);

  const handleClose = () => {
    dispatch(closeDialog());
    setFlavor("default");
  };

  if (!event) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} data-testid="attendDialog">
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="attendDialogTitle">
          {`${event.auctionCompany} - ${event.facilityName}`}
        </Typography>
        <IconButton aria-label="close" onClick={handleClose} data-testid="attendDialogClose">
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl className={classes.formControl} variant="standard">
          <FlavorSelect eventId={eventId} setFlavor={setFlavor} flavor={flavor} />
          <List className={classes.list}>
            <ListItem data-testid="adminGuestViewer">
              <Hidden smDown>
                <ListItemIcon>
                  <Icon icon="visibility" />
                </ListItemIcon>
              </Hidden>

              <ListItemText
                primary="Guest Viewer"
                secondary="Without credentials"
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  onClick={attendGuestViewer}
                  data-testid="adminGuestViewerLaunch"
                >
                  Launch
                </Button>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem data-testid="adminGuestBidder">
              <Hidden smDown>
                <ListItemIcon>
                  <Icon icon="person" />
                </ListItemIcon>
              </Hidden>

              <ListItemText
                primary="Guest Bidder"
                secondary="Very low bidding limit"
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  onClick={attendGuestBidder}
                  data-testid="adminGuestBidderLaunch"
                >
                  Launch
                </Button>
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem data-testid="adminGuestMarquee">
              <Hidden smDown>
                <ListItemIcon>
                  <Icon icon="airplay" />
                </ListItemIcon>
              </Hidden>

              <ListItemText
                primary="Guest Marquee"
                secondary="From customer options"
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  onClick={attendGuestMarquee}
                  data-testid="adminGuestMarqueeLaunch"
                >
                  Launch
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem data-testid="adminGuestTechcoord">
              <Hidden smDown>
                <ListItemIcon>
                  <Icon icon="manage_accounts" />
                </ListItemIcon>
              </Hidden>

              <ListItemText
                primary="Guest Techcoord"
                secondary="Assist auction"
                primaryTypographyProps={{ variant: "subtitle2" }}
                secondaryTypographyProps={{ variant: "caption" }}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  onClick={attendGuestTechcoord}
                  data-testid="adminGuestTechcoordLaunch"
                >
                  Launch
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export { AttendDialog };
