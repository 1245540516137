import { FC, forwardRef } from "react";
import { default as MuiTableContainer, TableContainerProps } from "@mui/material/TableContainer";
import { default as MuiTable, TableProps } from "@mui/material/Table";
import { default as MuiTableHead, TableHeadProps } from "@mui/material/TableHead";
import { default as MuiTableBody, TableBodyProps } from "@mui/material/TableBody";
import { default as MuiTableRow, TableRowProps } from "@mui/material/TableRow";
import { default as MuiTableCell, TableCellProps } from "@mui/material/TableCell";
import { default as MuiTableFooter, TableFooterProps } from "@mui/material/TableFooter";
import { default as MuiTableSortLabel, TableSortLabelProps } from "@mui/material/TableSortLabel";
import { default as MuiTablePagination, TablePaginationProps } from "@mui/material/TablePagination";

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableContainer {...props} ref={ref}>
        {children}
      </MuiTableContainer>
    );
  }
);

export const Table = forwardRef<HTMLTableElement, TableProps>(({ children, ...props }, ref) => (
  <MuiTable {...props} ref={ref}>
    {children}
  </MuiTable>
));

export const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableHead {...props} ref={ref}>
        {children}
      </MuiTableHead>
    );
  }
);

export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableBody {...props} ref={ref}>
        {children}
      </MuiTableBody>
    );
  }
);

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableRow {...props} ref={ref}>
        {children}
      </MuiTableRow>
    );
  }
);

export const TableCell: FC<TableCellProps> = ({ children, ...props }) => {
  return <MuiTableCell {...props}>{children}</MuiTableCell>;
};

export const TableFooter = forwardRef<HTMLTableSectionElement, TableFooterProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableFooter {...props} ref={ref}>
        {children}
      </MuiTableFooter>
    );
  }
);

export const TableSortLabel = forwardRef<HTMLSpanElement, TableSortLabelProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiTableSortLabel {...props} ref={ref}>
        {children}
      </MuiTableSortLabel>
    );
  }
);

export const TablePagination: FC<TablePaginationProps> = ({ ...props }) => {
  return <MuiTablePagination {...props} />;
};

export type {
  TableProps,
  TableHeadProps,
  TableBodyProps,
  TableRowProps,
  TableCellProps,
  TableFooterProps,
  TableSortLabelProps,
  TablePaginationProps,
  TableContainerProps,
};
