import { FC } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  makeStyles,
} from "@xcira/components";

import { useCumulativeStats, useCumulativeStatsSelector } from "hooks/useCumulativeStats";
import { CumulativeStatsHeader } from "./CumulativeStatsHeader";
import { CumulativeStatsRow } from "./CumulativeStatsRow";
import { CumulativeStatsFooter } from "./CumulativeStatsFooter";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  noData: {
    padding: theme.spacing(2),
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

export const CumulativeStatsTable: FC = () => {
  const { classes } = useStyles();
  const { isLoading } = useCumulativeStats();

  const cumulativeStats = useCumulativeStatsSelector(
    (cumulativeStats) => cumulativeStats.cumulativeStats
  );

  const isEmpty = !cumulativeStats || cumulativeStats.length <= 0;

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="cumulativeStatsLoader"
        className={classes.circularProgress}
        size={100}
      />
    );
  }

  if (isEmpty) {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.noData}
        data-testid="cumulativeStatsNoData"
      >
        <Grid item xs>
          <Typography variant="h6">No stats found. Check filters and try again.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Paper>
        <TableContainer data-testid="cumulativeStatsContainer" sx={{ maxHeight: "70vh" }}>
          <Table size="small" stickyHeader>
            <CumulativeStatsHeader />
            <TableBody>
              {cumulativeStats?.map((cumulativeStat) => {
                return (
                  <CumulativeStatsRow
                    key={cumulativeStat.id}
                    cumulativeStatsId={cumulativeStat.id}
                  />
                );
              })}
            </TableBody>
            <CumulativeStatsFooter />
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
