import { FC } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@xcira/components";
import { AttendeeTypeEnum } from "graphql/generated";
import { useLoggedInAttendeeCountsByEventId } from "hooks";

export interface AttendeesCountsTableProps {
  eventId: number;
}

const useStyles = makeStyles()((theme) => ({
  infoText: { marginTop: theme.spacing(1) },
}));

const AttendeesCountTable: FC<AttendeesCountsTableProps> = ({ eventId }) => {
  const { classes } = useStyles();

  const clerkCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Clerk);
  const techcoordCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Techcoord);
  const bidderCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Bidder);
  const viewerCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Viewer);
  const marqueeCount = useLoggedInAttendeeCountsByEventId(eventId, AttendeeTypeEnum.Marquee);

  return (
    <div data-testid="portsConfirmationDialogAttendees">
      <Typography
        variant="subtitle2"
        className={classes.infoText}
        data-testid="portsConfirmationDialogAttendeesMessage"
      >
        Following attendees are logged into the sale currently:
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Clerk</TableCell>
              <TableCell>Techcoord</TableCell>
              <TableCell>Viewers</TableCell>
              <TableCell>Bidders</TableCell>
              <TableCell>Marquee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell data-testid="portsConfirmationDialogClerkCount">{clerkCount}</TableCell>
              <TableCell data-testid="portsConfirmationDialogTechcoordCount">
                {techcoordCount}
              </TableCell>
              <TableCell data-testid="portsConfirmationDialogViewerCount">{viewerCount}</TableCell>
              <TableCell data-testid="portsConfirmationDialogBidderCount">{bidderCount}</TableCell>
              <TableCell data-testid="portsConfirmationDialogMarqueeCount">
                {marqueeCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export { AttendeesCountTable };
