import { FC, SyntheticEvent, useCallback } from "react";
import { Toast } from "@xcira/components";
import { dismiss } from "@xcira/commons";
import { useAppDispatch, useAppSelector } from "hooks";
import type { SnackbarProps } from "@xcira/components";

const anchorOrigin: SnackbarProps["anchorOrigin"] = {
  vertical: "top",
  horizontal: "center",
};

const Notifications: FC = () => {
  const dispatch = useAppDispatch();

  const message = useAppSelector((state) => state.notifications.message);
  const severity = useAppSelector((state) => state.notifications.severity);
  const duration = useAppSelector((state) => state.notifications.duration);
  const open = useAppSelector((state) => state.notifications.isOpen);

  const handleClose = useCallback(
    (_event: SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      dispatch(dismiss());
    },
    [dispatch]
  );

  const isError = severity === "error";

  return (
    <Toast
      SnackbarProps={{
        anchorOrigin,
        open,
        autoHideDuration: isError ? null : duration,
        onClose: handleClose,
      }}
      AlertProps={{
        onClose: handleClose,
        severity: severity,
        variant: "filled",
      }}
    >
      {message}
    </Toast>
  );
};

export { Notifications };
