import { AuctionEvent } from "graphql/generated";
import { useEventsSelector } from "hooks";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectFromResult = (data?: AuctionEvent[]) => any;

const defaultSelector = (event?: AuctionEvent[]) => event;

type DefaultSelector = typeof defaultSelector;

type Result<T> = T extends DefaultSelector
  ? AuctionEvent[] | undefined
  : T extends SelectFromResult
  ? ReturnType<T>
  : never;

const useSelectEventsByIds = <T extends SelectFromResult>(
  eventIds: number[],
  selector: T = defaultSelector as T
): Result<T> => {
  const selectedEvents = useEventsSelector((events) =>
    events.filter((event) => eventIds.includes(event.id))
  );
  return useMemo(() => selector(selectedEvents), [selector, selectedEvents]);
};

export { useSelectEventsByIds };
