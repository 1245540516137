import { useSetAvPublisherGainMutation } from "services";
import { useCallback } from "react";

function useSetAvPublisherGain(avPort: number, gain: number) {
  const [setAvPublisherGain] = useSetAvPublisherGainMutation();

  return useCallback(async () => {
    const result = await setAvPublisherGain({ avPort, gain }).unwrap();

    return result;
  }, [setAvPublisherGain, avPort, gain]);
}

export { useSetAvPublisherGain };
