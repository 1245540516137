import { FC } from "react";
import { TableCell, TableHead, TableRow, makeStyles } from "@xcira/components";

export const CUMULATIVE_STATS_HEADERS = [
  "Auction Name",
  "Date",
  "Start Time",
  "End Time",
  "Event Duration HH:MM:SS",
  "Total Login Duration HH:MM:SS",
  "Items",
  "Items Clerked",
  "Items Sold",
  "Items Sold to Olr",
  "Items with OLR Bid",
  "Items Sold with OLR Runner Up",
  "Accepted OLR Bids",
  "OLR Attendees",
  "OLR Attendees without Bid",
  "OLR Attendees who Bid",
  "OLR Attendees who Won",
  "Total Sales",
  "Total Sales to OLR",
  "Average $$ Per Item",
  "Average $$ Per Item to OLR",
  "% of Items with OLR Runner Up",
  "% of Items with OLR Bid",
  "% of Items sold to OLR",
  "% of Sales to Olr",
];

const useStyles = makeStyles()((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[200],
    "& th": {
      backgroundColor: theme.palette.grey[200],
      textAlign: "center",
      padding: theme.spacing(0, 1),
      minWidth: 80,
    },
  },
}));

export const CumulativeStatsHeader: FC = () => {
  const { classes } = useStyles();

  return (
    <TableHead className={classes.head}>
      <TableRow>
        {CUMULATIVE_STATS_HEADERS.map((header) => (
          <TableCell key={header}>{header}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
