import { useMemo, ChangeEvent, FC } from "react";
import {
  makeStyles,
  Input,
  Grid,
  FormControl,
  Icon,
  InputLabel,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@xcira/components";
import {
  setRoleFilter,
  setDisplayNameFilter,
  removeRoleFilter,
  selectRoleFilters,
  selectDisplayNameFilter,
} from "slices/attendees";
import { useAppDispatch, useAppSelector } from "hooks";
import { AttendeeTypeEnum } from "graphql/generated";
import { debounce } from "@xcira/commons";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  head: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  row: {
    "& td": { minWidth: 160 },
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  filtersGrid: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    paddingRight: theme.spacing(2),
    width: "100%",
  },
  flavorDropdown: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[400],
    borderWidth: "1px",
    borderStyle: "solid",
  },
  checkboxes: {
    display: "flex",
    alignItems: "center",
  },
  reload: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const VIEWER = AttendeeTypeEnum.Viewer;
const BIDDER = AttendeeTypeEnum.Bidder;
const MARQUEE = AttendeeTypeEnum.Marquee;
const CLERK = AttendeeTypeEnum.Clerk;
const TECHCOORD = AttendeeTypeEnum.Techcoord;

const AttendeeFilters: FC = () => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const roleFilters = useAppSelector(selectRoleFilters);
  const displayNameFilter = useAppSelector(selectDisplayNameFilter);

  const handleDisplayNameChange = useMemo(
    () =>
      debounce((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setDisplayNameFilter(event.target.value.trim()));
      }, 250),
    [dispatch]
  );

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(setRoleFilter(event.target.value as AttendeeTypeEnum));
    } else {
      dispatch(removeRoleFilter(event.target.value as AttendeeTypeEnum));
    }
  };

  const isChecked = (role: AttendeeTypeEnum) => {
    return roleFilters.includes(role);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.filtersGrid}
      spacing={1}
    >
      <Grid item xs={12} sm={4} lg={3}>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="displayname-search">Display Name</InputLabel>
          <Input
            type="search"
            id="displayname-search"
            defaultValue={displayNameFilter}
            onChange={handleDisplayNameChange}
            endAdornment={
              <InputAdornment position="end">
                <Icon icon="search" />
              </InputAdornment>
            }
            data-testid="attendeesDisplayNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container spacing={2} className={classes.checkboxes}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={VIEWER}
                      checked={isChecked(VIEWER)}
                      onChange={handleRoleChange}
                      data-testid="attendeesViewerCheckbox"
                    />
                  }
                  label="Viewer"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={BIDDER}
                      checked={isChecked(BIDDER)}
                      onChange={handleRoleChange}
                      data-testid="attendeesBidderCheckbox"
                    />
                  }
                  label="Bidder"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={MARQUEE}
                      checked={isChecked(MARQUEE)}
                      onChange={handleRoleChange}
                      data-testid="attendeesMarqueeCheckbox"
                    />
                  }
                  label="Marquee"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={CLERK}
                      checked={isChecked(CLERK)}
                      onChange={handleRoleChange}
                      data-testid="attendeesClerkCheckbox"
                    />
                  }
                  label="Clerk"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={TECHCOORD}
                      checked={isChecked(TECHCOORD)}
                      onChange={handleRoleChange}
                      data-testid="attendeesTechcoordCheckbox"
                    />
                  }
                  label="Techcoord"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AttendeeFilters };
