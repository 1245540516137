import { RequestDocument } from "graphql-request";
import { Client, ExecutionResult, Sink, createClient } from "graphql-ws";

type WebsocketClientStatus = "connecting" | "connected" | "disconnected";
type Next<T, E> = Sink<ExecutionResult<Record<string, T>, E>>["next"];
type Subscription<T, E, V> = {
  document: RequestDocument;
  subscriber: Next<T, E>;
  variables?: V;
  unsubscribe?: () => void;
};

export class WebsocketClient {
  public static GRAPHQL_TRANSPORT_WS: string = "graphql-transport-ws";
  private url?: string;
  private client?: Client;
  private status: WebsocketClientStatus = "disconnected";
  private subscriptions: Record<string, Subscription<unknown, unknown, unknown>> = {};
  private authToken: string = "";

  public WebsocketClient() {}

  public isConnected() {
    return this.status === "connected";
  }

  public isConnecting() {
    return this.status === "connecting";
  }

  public isDisconnected() {
    return this.status === "disconnected";
  }

  public setUrl(url: string) {
    const urlObject = new URL(url);

    urlObject.protocol = "wss:";
    urlObject.pathname = "/graphql";

    this.url = urlObject.toString();
  }

  public setAuthToken(token: string) {
    this.authToken = token;
  }

  public async connect() {
    if (this.isDisconnected()) {
      this.status = "connecting";
      this.createClient();
      this.status = "connected";

      Object.values(this.subscriptions).forEach((sub) => {
        this.subscribe(sub.document, sub.subscriber, sub.variables);
      });
    }
  }

  public subscribe<T, E, V>(document: RequestDocument, subscriber: Next<T, E>, variables?: V) {
    const query = document.toString();
    this.subscriptions[query] = { document, subscriber, variables };

    if (this.isConnected()) {
      const unsubscribe = this.client?.subscribe(
        {
          query,
        },
        {
          next: subscriber,
          error() {},
          complete() {},
        }
      );

      this.subscriptions[query].unsubscribe = unsubscribe;

      return unsubscribe;
    }
  }

  private createClient() {
    if (this.url) {
      this.client = createClient({
        url: this.url,
        connectionParams: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });
    }
  }
}
