export const convertToTime = (minutes: number | null | undefined) => {
  if (!minutes) {
    return "00:00:00";
  }

  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
};
