import { FC, useState, ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Typography,
  Input,
  Slider,
} from "@xcira/components";
import {
  useAppDispatch,
  useSelectAvPublisherByPort,
  useSelectEventById,
  useSetAvPublisherGain,
} from "hooks";
import { notify } from "@xcira/commons";
import { closeDialog, ExtractDialogProps } from "slices/dialogs";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    overflowX: "hidden",
  },
  dialogTitle: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  dialogContent: {
    width: "80vw",
    maxWidth: 340,
    overflowX: "hidden",
  },
  streamInfoGrid: { marginBottom: theme.spacing(1) },
  streamInfoText: { wordBreak: "break-word" },
  auctionCompany: { textTransform: "capitalize" },
  setGainGrid: {
    marginBottom: theme.spacing(1),
  },
  submit: { width: "100%", minWidth: 100 },
}));

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 150,
    label: "150",
  },
];

type DialogProps = {
  isOpen: boolean;
} & ExtractDialogProps<"SetGain">;

const SetGainDialog: FC<DialogProps> = ({ isOpen, eventId }) => {
  const [value, setValue] = useState<number>(0);

  const dispatch = useAppDispatch();

  const { classes } = useStyles();

  const avPort = useSelectEventById(eventId, (event) => event?.avPort ?? 0);

  const avPublisherName = useSelectAvPublisherByPort(
    avPort,
    (avPublisher) => avPublisher?.avStream.name
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value ?? 0));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 150) {
      setValue(150);
    }
  };

  const setAvPublisherGainAction = useSetAvPublisherGain(avPort, value);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleClose = async () => {
    const { setAvPublisherGain } = await setAvPublisherGainAction();
    if (setAvPublisherGain.success) {
      dispatch(
        notify({
          message: `Set gain action was successful. Gain is now set to ${value}.`,
          severity: "success",
        })
      );

      handleCloseDialog();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      className={classes.dialog}
      data-testid="setGainDialog"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1" data-testid="setGainDialogTitle">
          Set Gain
        </Typography>
        <IconButton aria-label="close" onClick={handleCloseDialog} data-testid="setGainDialogClose">
          <Icon icon="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container spacing={2} className={classes.streamInfoGrid}>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">AV Port</Typography>
              </Grid>
              <Grid item flexWrap="wrap" className={classes.auctionCompany}>
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="setGainDialogAvPort"
                >
                  {avPort}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container flexDirection="column">
              <Grid item>
                <Typography variant="subtitle2">Stream Name</Typography>
              </Grid>
              <Grid item flexWrap="wrap">
                <Typography
                  variant="body2"
                  className={classes.streamInfoText}
                  data-testid="setGainDialogStreamName"
                >
                  {avPublisherName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center" className={classes.setGainGrid}>
          <Grid item xs>
            <Slider
              value={value}
              onChange={handleSliderChange}
              marks={marks}
              valueLabelDisplay="auto"
              min={0}
              max={150}
              data-testid="setGainDialogSlider"
            />
          </Grid>
          <Grid item>
            <Input
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{ step: 1, min: 0, max: 150, type: "number" }}
              data-testid="setGainDialogInput"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.submit}
              data-testid="setGainDialogSubmit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export { SetGainDialog };
