import { useMemo } from "react";
import { useAppSelector } from "hooks";
import { selectFilteredEvents } from "slices";

const useSelectCurrentEventIds = () => {
  const events = useAppSelector(selectFilteredEvents);
  return useMemo(() => events.map((event) => event.id), [events]);
};

export { useSelectCurrentEventIds };
