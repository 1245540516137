import { useAppDispatch, useAppSelector, useAvStreamNameByAudioPort } from "hooks";
import { FC, useEffect, useRef } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { notify, Resources, serversSelector, Severity } from "@xcira/commons";

const severity: Severity = "error";
const DOCUMENT_BASE_TITLE = "Xcira Publisher: ";

export const PublisherPage: FC = () => {
  const { search } = useLocation();
  const values = queryString.parse(search, { parseBooleans: true, parseNumbers: true });
  const dispatch = useAppDispatch();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const services: Resources = useAppSelector(serversSelector);
  const streamName = useAvStreamNameByAudioPort(
    values.audio_port as number,
    values.stream_name as string
  );

  const isValidBooleanArg = (
    values: queryString.ParsedQuery<string | number | boolean>,
    key: string
  ) => {
    const value = values[key];

    if (value !== undefined && typeof value !== "boolean") {
      const message = `${key} value must be true or false`;
      dispatch(notify({ message, severity }));
      return false;
    }
    return true;
  };

  const validatePublisherArgs = () => {
    if ((values.audio_port && values.stream_name) || (!values.stream_name && !values.audio_port)) {
      dispatch(notify({ message: "invalid streamer configuration", severity }));
      return false;
    }

    if (!isValidBooleanArg(values, "auto_start")) {
      return false;
    }

    if (!isValidBooleanArg(values, "tcp")) {
      return false;
    }

    if (!isValidBooleanArg(values, "restart")) {
      return false;
    }

    if (!isValidBooleanArg(values, "debug")) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    document.title = `${DOCUMENT_BASE_TITLE} ${streamName}`;
  }, [streamName]);

  if (validatePublisherArgs()) {
    return (
      <iframe
        data-testid="PublisherPageIframe"
        ref={iframeRef}
        width="100%"
        height="100%"
        title="bs"
        allow="camera;microphone"
        src={`${services.cloudServices}/av/streamer?${queryString.stringify(values || {})}`}
      />
    );
  }

  return <></>;
};
