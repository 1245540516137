import React, { FC, ChangeEvent } from "react";
import { FlavorSelect, RequiresPermissions } from "components";
import {
  makeStyles,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  TextField,
} from "@xcira/components";
import { useParams } from "react-router-dom";
import type { RouterParams } from "components/ProtectedRoutes";
import { Permission } from "graphql/generated";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& th": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  formControl: {
    minWidth: 280,
    width: "100%",
  },
  flavorDropdown: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[400],
    borderWidth: "1px",
    borderStyle: "solid",
  },
}));
interface AttendeesHeaderProps {
  flavor: string;
  setFlavor: React.Dispatch<React.SetStateAction<string>>;
  devOverride?: string;
  setDevOverride?: React.Dispatch<React.SetStateAction<string>>;
}

const AttendeesHeader: FC<AttendeesHeaderProps> = ({
  flavor,
  setFlavor,
  devOverride,
  setDevOverride,
}) => {
  const { eventId } = useParams<RouterParams>();

  const { classes } = useStyles();

  const handleDevOverrideChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (setDevOverride) {
      setDevOverride(event.target.value.trim());
    }
  };

  return (
    <TableHead className={classes.root} data-testid="attendeesHeader">
      <TableRow>
        <RequiresPermissions permissions={Permission.AttendeesLaunch}>
          <>
            <TableCell data-testid="attendeesHeaderFormControl">
              <FormControl className={classes.formControl} variant="standard">
                <TextField
                  type="text"
                  id="devOverride"
                  label="Dev Port Override"
                  variant="outlined"
                  value={devOverride}
                  onChange={handleDevOverrideChange}
                  data-testid="attendeesDevOverrideInput"
                />
                <FlavorSelect
                  eventId={Number(eventId)}
                  flavor={flavor}
                  setFlavor={setFlavor}
                  label={false}
                  className={classes.flavorDropdown}
                />
              </FormControl>
            </TableCell>
            <TableCell></TableCell>
          </>
        </RequiresPermissions>

        <TableCell>Client Attendee ID</TableCell>
        <TableCell>Display Name</TableCell>
        <TableCell>Screen</TableCell>
        <TableCell align="right">Credit Limit</TableCell>
        <TableCell>Warranty</TableCell>
        <TableCell align="right">Seller ID</TableCell>
      </TableRow>
    </TableHead>
  );
};

export { AttendeesHeader };
